import React, { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import styled, { css } from "styled-components";
import * as ROUTES from "../../constants/routes";
import AnimatedForm from "../../assests/forms";
import useProgressiveImg from "../../assests/progressiveImage";
import fullResBRImg from "../../assests/images/fullResLoginBackground.jpg";
import lowResBRImg from "../../assests/images/lowResLoginBackground.jpg";

const BackgroundImage = styled.img`
  position: relative;
  width: 100%;
  height: 100vh;
  opacity: 0;

  ${(props) =>
    props.fadeIn &&
    css`
      opacity: 1;
      transition: opacity 0.1s ease-in;
    `}
  //None of the below is working for some reason
  /* filter: ${(blur) => (blur ? "blur(200px)" : "none")};
  transition: ${(blur) => (blur ? "none" : "filter 0.3s ease-out")}; */
  filter: blur(20px);

  /* object-position: 0% 5%; */

  object-fit: cover;
  /* margin-top: -70px; */

  z-index: -2;
`;

const BackgroundImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const FinishSignupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Box = styled.div`
  position: absolute;

  /* left: 33.3%;
  transform: translate(-50% -50%); */
  width: 400px;
  background-color: white;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;
  top: 20vh;
  @media only screen and (max-height: 750px) {
    top: 13vh;
  }
  @media only screen and (max-height: 620px) {
    top: 8vh;
  }
  @media only screen and (max-height: 520px) {
    top: 4vh;
  }
  @media only screen and (max-width: 550px) {
    width: 350px;
  }
`;

const FinishSignUpFormBase = ({ firebase }) => {
  const [error, setError] = useState(null);
  const [pressedFinish, setPressedFinish] = useState(false);
  const [timeoutFail, setTimeoutFail] = useState(undefined);

  const history = useHistory();
  const authUser = useContext(AuthUserContext);

  const submitFinishSignUp = (inputs) => {
    console.log(
      `Valid email link: ${firebase.isValidEmailLink(window.location.href)}`
    );
    //This means they've already pressed it once, and push() below didn't work.  Take them to the landing.
    if (pressedFinish) {
      if (authUser) {
        clearTimeout(timeoutFail);
        history.push(ROUTES.LANDING);
      } else {
        const timeoutToFail = setTimeout(() => {
          history.push(ROUTES.SIGN_UP);
        }, 4000);
        setTimeoutFail(timeoutToFail);
      }
    }
    setPressedFinish(true);
    //if (firebase.isValidEmailLink(window.location.href)) {
    // The client SDK will parse the code from the link for you.
    firebase
      .doFinishSignInWithEmailLink(inputs.email, window.location.href)
      .then((result) => {
        console.log(`User created: ${result.user}`);
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        firebase.user(result.user.uid).set(
          {
            username: inputs.username,
            email: inputs.email,
            roles: {},
          },
          {
            merge: true,
          }
        );
      })
      .then(() => {
        history.push(ROUTES.LANDING);
      })
      .catch((err) => {
        console.error(
          `Error signing up and creating user with link ${err.code}`
        );
        setError(err.code);
      });
  };

  const formInputs = [
    { name: "firstName", label: "First Name", type: "text" },
    { name: "lastName", label: "Last Name", type: "text" },
    { name: "email", label: "Verify Email", type: "text" },
  ];

  const SubmitButtonStyle = {
    width: "260px",
    fontSize: "1.5rem",
  };

  return (
    <React.Fragment>
      <AnimatedForm
        formInputs={formInputs}
        submitButtonText={"Finsh Sign Up"}
        submitFunction={submitFinishSignUp}
        buttonStyle={SubmitButtonStyle}
        disableBox={true}
      />

      {error && <p>{error.message}</p>}
    </React.Fragment>
  );
};

const FinishSignUpPage = () => {
  const BlurredUpBackground = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [src, { blur, cancelAnimate }] = useProgressiveImg(
      lowResBRImg,
      fullResBRImg
    );
    setTimeout(() => {
      setFadeIn(true);
    }, 160);

    return (
      <BackgroundImageWrapper>
        <BackgroundImage
          fadeIn={fadeIn}
          style={
            blur
              ? { filter: "blur(20px)" }
              : cancelAnimate
              ? { filter: "grayscale(100%)" }
              : { filter: "grayscale(100%)", transition: "filter 0.3s ease-in" }
          }
          src={src}
        />
      </BackgroundImageWrapper>
    );
  };
  return (
    <FinishSignupWrapper>
      <BlurredUpBackground />
      <Box>
        <FinishSignUpForm />
      </Box>
    </FinishSignupWrapper>
  );
};

const FinishSignUpForm = withRouter(withFirebase(FinishSignUpFormBase));

export default withFirebase(FinishSignUpPage);
