import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import GoogleSigninButtonImage from "./img/btn_google_signin_light_normal_web@2x.png";
import FacebookSigninButtonImage from "./img/signinButtonFacebook.png";
import styled, { css } from "styled-components";

import AnimatedForm from "../../assests/forms";
import useProgressiveImg from "../../assests/progressiveImage";
import fullResBRImg from "../../assests/images/fullResLoginBackground.jpg";
import lowResBRImg from "../../assests/images/lowResLoginBackground.jpg";

const BackgroundImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${(props) =>
    props.fadeIn &&
    css`
      opacity: 1;
      transition: opacity 0.1s ease-in;
    `}

  /* filter: ${(blur) => (blur ? "blur(200px)" : "none")};
  transition: ${(blur) => (blur ? "none" : "filter 0.3s ease-out")}; */
  filter: blur(20px);

  /* object-position: 0% 5%; */

  object-fit: cover;
  transform: scale(1.03);
  /* margin-top: -70px; */

  z-index: -2;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;
  justify-content: center;
`;

const Heading2 = styled.h2`
  color: #5b5656;
  text-align: center;
`;

const OrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  justify-content: center;
  height: 30px;
  font-size: 18px;
  font-family: roboto-light, sans-sarif;
`;

const DesktopWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BoxDesktop = styled.div`
  position: absolute;
  top: 4vh;
  left: 33.3%;
  transform: translate(-50% -50%);
  width: 400px;
  background-color: white;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;
`;

const BoxMobile = styled.div`
  position: relative;
  /* transform: translate(-50% -50%); */
  width: 350px;
  background-color: white;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;
  top: 4vh;
  @media only screen and (max-height: 650px) {
    padding-bottom: 20px;
  }
`;

const InvisibleButton = styled.button`
  background: transparent;
  border: none !important;
  font-size: 0;
`;

const LoginButtonImg = styled.img`
  height: 60px;
`;
const LoginButtonImgFB = styled.img`
  height: 55px;
`;

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
An account with this E-Mail address already exists.
Try to login with this account instead.  If you think the
the account is already used from one of the social logins, try
to sign-in with one of them.  Afterward, associate your accounts
on your personal account page.
`;

const SignUpFormBase = ({ firebase }) => {
  const [error, setError] = useState(null);

  const history = useHistory();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const displayMobile = windowWidth >= 900 ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const SignInGoogle = () => {
    const [error, setError] = useState(null);

    const history = useHistory();

    const onSubmit = (event) => {
      firebase
        .doSignInWithGoogle()
        .then((socialAuthUser) => {
          //Create a user in your Firebase Firestore too
          return firebase.user(socialAuthUser.user.uid).set(
            {
              username: socialAuthUser.user.displayName,
              email: socialAuthUser.user.email,
              roles: {},
            },
            {
              merge: true,
            }
          );
        })
        .then(() => {
          setError(null);
          history.push(ROUTES.ASSESSMENTS);
        })
        .catch((err) => {
          if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
            err.message = ERROR_MSG_ACCOUNT_EXISTS;
          }
          setError({ err });
        });

      event.preventDefault();
    };

    return (
      <form onSubmit={onSubmit}>
        {/* <Button type="submit" variant="outline-success">
          Sign In with Google
        </Button> */}
        <InvisibleButton>
          <LoginButtonImg
            src={GoogleSigninButtonImage}
            alt="Google Sign In"
            onClick={onSubmit}
          />
        </InvisibleButton>

        {error && <p>{error.message}</p>}
      </form>
    );
  };

  const SignInFacebook = () => {
    const [error, setError] = useState(null);

    const history = useHistory();

    const onSubmit = (event) => {
      firebase
        .doSignInWithFacebook()
        .then((socialAuthUser) => {
          //Create a user in your Firebase Realtime Database too
          return firebase.user(socialAuthUser.user.uid).set(
            {
              username: socialAuthUser.additionalUserInfo.profile.name,
              email: socialAuthUser.additionalUserInfo.profile.email,
              roles: {},
            },
            {
              merge: true,
            }
          );
        })
        .then(() => {
          setError(null);
          history.push(ROUTES.ASSESSMENTS);
        })
        .catch((err) => {
          if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
            err.message = ERROR_MSG_ACCOUNT_EXISTS;
          }
          setError(err);
        });

      event.preventDefault();
    };

    return (
      <form onSubmit={onSubmit}>
        <InvisibleButton>
          <LoginButtonImgFB
            src={FacebookSigninButtonImage}
            alt="Facebook Sign In"
            onClick={onSubmit}
          />
        </InvisibleButton>

        {error && <p>{error.message}</p>}
      </form>
    );
  };

  const EmailSignUpFormWithInputs = () => {
    const [formError, setFormError] = useState(null);

    //Signup Function using Firebase
    const submitSignUp = (inputs) => {
      if (inputs.password === inputs.passwordConfirm) {
        firebase
          .doCreateUserWithEmailAndPassword(inputs.email, inputs.password)
          .then((authUser) => {
            // Create a user in Firebase Firestore
            const username = inputs.firstName
              .concat(" ")
              .concat(inputs.lastName);
            firebase.user(authUser.user.uid).set(
              {
                username: username,
                email: inputs.email,
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                phoneNumber: inputs.phoneNumber,
                roles: {},
              },
              {
                merge: true,
              }
            );
          })

          .then(() => {
            firebase.doSendEmailVerification();
          })

          .then(() => {
            alert(
              `You have signed up!  Please verify your email address (you should receive and email) then sign in.`
            );
            history.push(ROUTES.LANDING);
          })
          .catch((error) => {
            if (error.code === ERROR_MSG_ACCOUNT_EXISTS) {
              error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }
            setFormError(error);
          });
      } else if (inputs.password !== inputs.passwordConfirm) {
        setFormError("Your passwords don't match, you goofed");
      }
    };

    const formInputs = [
      { name: "firstName", label: "First Name", type: "text" },
      { name: "lastName", label: "Last Name", type: "text" },
      { name: "email", label: "Email", type: "text" },
      { name: "phoneNumber", label: "Phone Number", type: "text" },
      { name: "password", label: "Password", type: "password" },
      { name: "passwordConfirm", label: "Confirm Password", type: "password" },
    ];

    const SubmitButtonStyle = {
      width: "260px",
      fontSize: "1.5rem",
    };

    return (
      <React.Fragment>
        <AnimatedForm
          formInputs={formInputs}
          submitButtonText={"Sign Up"}
          submitFunction={submitSignUp}
          buttonStyle={SubmitButtonStyle}
          disableBox={true}
        />
        {formError && <p>{formError}</p>}
      </React.Fragment>
    );
  };

  const FormElementsLayout = () => (
    <React.Fragment>
      <Heading2>Sign Up</Heading2>
      <ComponentWrapper>
        <SignInGoogle />
      </ComponentWrapper>
      <ComponentWrapper>
        <SignInFacebook />
      </ComponentWrapper>
      <OrWrapper>
        <p> - or - </p>
      </OrWrapper>
      <EmailSignUpFormWithInputs />
    </React.Fragment>
  );
  return displayMobile ? (
    <MobileWrapper>
      <BoxMobile>
        <FormElementsLayout />
      </BoxMobile>
    </MobileWrapper>
  ) : (
    <DesktopWrapper>
      <BoxDesktop>
        <FormElementsLayout />
      </BoxDesktop>
    </DesktopWrapper>
  );
};

const SignUpPage = () => {
  const BlurredUpBackground = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [src, { blur, cancelAnimate }] = useProgressiveImg(
      lowResBRImg,
      fullResBRImg
    );
    setTimeout(() => {
      setFadeIn(true);
    }, 160);

    return (
      <BackgroundImageWrapper>
        <BackgroundImage
          fadeIn={fadeIn}
          style={
            blur
              ? { filter: "blur(20px)" }
              : cancelAnimate
              ? { filter: "grayscale(100%)" }
              : { filter: "grayscale(100%)", transition: "filter 0.3s ease-in" }
          }
          src={src}
        />
      </BackgroundImageWrapper>
    );
  };

  return (
    <React.Fragment>
      <BlurredUpBackground />
      <SignUpForm />
    </React.Fragment>
  );
};

const SignUpLink = () => (
  <SignUpWrapper>
    <p>Don't have an account?</p>
    <p>
      <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </p>
  </SignUpWrapper>
);
const SignUpForm = withRouter(withFirebase(SignUpFormBase));

export default withFirebase(SignUpPage);
export { SignUpForm, SignUpLink };
