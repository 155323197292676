import React, { useState, useEffect } from "react";
import DemographicSurveyQuestions from "./DemographicSurveyQuestions";
import DemographicSurveyBase from "./DemographicSurveyBase";
import styled from "styled-components";
import animations from "react-animations";

const BlackButton = styled.button`
  display: flex;
  width: 270px;
  height: 60px;
  justify-content: center;
  font-size: 35px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-left: 80px;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.surveyComplete ? "visibility: visible;" : "visibility: hidden;"}

  ${(props) => (props.surveyComplete ? "z-index: 6;" : "z-index: -1;")}
`;

const EntireSurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-itmes: flex-start;
`;

//TODO: need to change the above width to adjust with screen width with a max value.

const DemographicSurvey = (props) => {
  const [questionFocus, setQuestionFocus] = useState("Age");
  const [answersObject, setAnswersObject] = useState({});
  const [checkedAnswerCurrent, setCheckedAnswerCurrent] = useState(undefined);
  const [surveyComplete, setSurveyComplete] = useState(false);

  //Create an array of questionName for this survey, so onAnswerSelected can increment to the next question by name
  var questionNamesArray = [];
  DemographicSurveyQuestions.forEach((question) =>
    questionNamesArray.push(question.questionName)
  );

  const onAnswerSelected = (event) => {
    var answersObjectTemp = answersObject;
    answersObjectTemp[questionFocus] = event.target.value;
    setAnswersObject(answersObjectTemp);
    if (answersObjectTemp) {
      setCheckedAnswerCurrent(answersObjectTemp[questionFocus]);
    }
    setSurveyComplete(Object.keys(answersObjectTemp).length === 8);

    console.log(`Survey complete is: ${surveyComplete}`);

    setTimeout(() => {
      var arrayPosOfCurrent = questionNamesArray.indexOf(questionFocus);
      setQuestionFocus(questionNamesArray[arrayPosOfCurrent + 1]);
      console.log(answersObject);
    }, 800);
  };

  useEffect(() => {
    if (answersObject) {
      setCheckedAnswerCurrent(answersObject[questionFocus]);
    }
  }, [questionFocus]);

  useEffect(() => {
    setSurveyComplete(Object.keys(answersObject).length >= 8);
  }, [answersObject]);

  return (
    <EntireSurveyWrapper>
      {DemographicSurveyQuestions.map((question) => (
        <DemographicSurveyBase
          key={question.questionName.concat("SurveyComponent")}
          questionText={question.questionText}
          onAnswerSelected={onAnswerSelected}
          answers={question.answers}
          questionName={question.questionName}
          questionFocus={questionFocus}
          setQuestionFocus={setQuestionFocus}
          checkedAnswer={checkedAnswerCurrent}
          setCheckedAnswer={setCheckedAnswerCurrent}
          answersObject={answersObject}
        />
      ))}
      <BlackButton
        disabled={!surveyComplete}
        onClick={() => props.storeDemographicResults(answersObject)}
        surveyComplete={surveyComplete}>
        Submit Survey
      </BlackButton>
    </EntireSurveyWrapper>
  );
};

export default DemographicSurvey;
