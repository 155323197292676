import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import styled from "styled-components";

import StyledButton from "../../assests/buttons";
import BRImage from "../../assests/images/fullBackgroundImg.jpg";
import SocialStylesLogo from "../../assests/logos/Team.svg";
import TypologyLogo from "../../assests/logos/Growth.svg";

const MainImage = styled.img`
  position: absolute;
  border-radius: 1rem;
  width: 96vw;
  height: 430px;
  object-fit: cover;
  object-position: 0% 80%;
  @media only screen and (max-width: 1450px) {
    height: 400px;
  }
  @media only screen and (max-width: 1150px) {
    height: 370px;
  }
  @media only screen and (max-width: 799px) {
    height: 300px;
  }
  @media only screen and (max-width: 531px) {
    height: 350px;
    object-position: 20% 80%;
  }

  z-index: -2;
  margin: 2vw;
`;

const OverlayStyled = styled.div`
  position: absolute;
  border-radius: 1rem;
  width: 96vw;
  height: 430px;
  @media only screen and (max-width: 1450px) {
    height: 400px;
  }
  @media only screen and (max-width: 1150px) {
    height: 370px;
  }
  @media only screen and (max-width: 799px) {
    height: 300px;
  }
  @media only screen and (max-width: 531px) {
    height: 350px;
  }
  z-index: -1;
  margin: 2vw;
  background-image: linear-gradient(120deg, #ee3189, #f36d21, #70c8b8, #469dd7);
  opacity: 0.8;
`;

const SpacerForAbsoluteOverlay = styled.div`
  border-radius: 1rem;
  width: 96vw;
  height: 430px;
  @media only screen and (max-width: 1450px) {
    height: 400px;
  }
  @media only screen and (max-width: 1150px) {
    height: 370px;
  }
  @media only screen and (max-width: 799px) {
    height: 300px;
  }
  @media only screen and (max-width: 531px) {
    height: 350px;
  }

  margin: 2vw;
`;

const TitleText = styled.p`
  font-family: "MontserratMed";
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: 4px;
  color: white;
  margin-top: 50px;

  @media only screen and (max-width: 1150px) {
    margin-top: 45px;
    font-size: 2.8rem;
  }

  @media only screen and (max-width: 799px) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 670px) {
    margin-top: 25px;
    font-size: 1.8rem;
  }
`;

const CopyTextHeading = styled.p`
  font-family: "MontserratReg";
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  color: white;
  margin-top: 25px;
  width: 75vw;
  @media only screen and (max-width: 1150px) {
    margin-top: 10px;
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 799px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 670px) {
    margin-top: 10px;
  }
`;

const AssessmentLogo = styled.img`
  height: 3rem;
  margin-left: 1vw;
`;

const AssessmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AssessmentsHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AssessmentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const AssessmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  width: 46vw;
  margin: 2vw;
  padding: 1vw;
  @media only screen and (max-width: 799px) {
    width: 96vw;
  }
`;

const AssessmentTitle = styled.p`
  height: 3rem;
  font-size: 2.6rem;
  font-family: "MontserratSemiBold";
  color: grey;
  @media only screen and (max-width: 900px) {
    font-size: 2.2rem;
  }
`;

const AssessmentCopyText = styled.p`
  font-size: 1.4rem;
  font-family: "MontserratReg";
  color: grey;
  margin-left: 2vw;
  margin-right: 2vw;
  text-align: center;
  @media only screen and (max-width: 900px) {
    font-size: 1rem;
  }
`;

const AssessmentsHome = (props) => {
  return (
    <React.Fragment>
      <OverlayStyled />
      <MainImage src={BRImage} alt="Image of a hip meeting" />

      <SpacerForAbsoluteOverlay>
        <AssessmentsHeadingWrapper>
          <TitleText>ASSESSMENTS</TitleText>
          <CopyTextHeading>
            Assessments are the root of what we do for teams; however, that is
            only the beginning of what makes our service powerful. We believe
            that compiled team data is what breathes life into the individual
            assessments, and provides opportunities for growth.
          </CopyTextHeading>
        </AssessmentsHeadingWrapper>
      </SpacerForAbsoluteOverlay>
      <AssessmentsWrapper>
        <AssessmentWrapper>
          <AssessmentTitleWrapper>
            <AssessmentTitle>TYPOLOGY</AssessmentTitle>
            <AssessmentLogo src={TypologyLogo} alt="Typology Logo" />
          </AssessmentTitleWrapper>

          <AssessmentCopyText>
            Typology is our primary assessment. The term "Typology" was first
            used by Carl Jung in the world of psychology to describe the
            differences between how we observe and interact with our
            surroundings. These interactions create the uniqueness of teams, and
            learning about them helps every team's performance.
          </AssessmentCopyText>
          <Link to={ROUTES.TYPOLOGY}>
            <StyledButton primary>
              {props.tookTypology ? "View Typology Results" : "Take Typology"}
            </StyledButton>
          </Link>
        </AssessmentWrapper>
        <AssessmentWrapper>
          <AssessmentTitleWrapper>
            <AssessmentTitle>SOCIAL STYLES</AssessmentTitle>
            <AssessmentLogo src={SocialStylesLogo} alt="Social Styles Logo" />
          </AssessmentTitleWrapper>
          <AssessmentCopyText>
            Social Styles is a new assessment developed by coach Lisa
            Gomez-Osborn flowing from her decades of experience. It is the
            culmination of her knowledge and will give you a vision of how
            individuals and teams operate within their preferences to create the
            dynamic function observed in teams.
          </AssessmentCopyText>
          <Link to={ROUTES.SOCIALSTYLES}>
            <StyledButton primary>
              {props.tookSocial ? "View Social Results" : "Take Social Styles"}
            </StyledButton>
          </Link>
        </AssessmentWrapper>
      </AssessmentsWrapper>
    </React.Fragment>
  );
};

export default AssessmentsHome;
