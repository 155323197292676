var assessmentQuestions = [
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Expressive"
      },
      {
        type: "Significance",
        content: "Adventurous"
      },
      {
        type: "Sensibility",
        content: "Analytical"
      },
      {
        type: "Altruistic",
        content: "Amiable"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Playful"
      },
      {
        type: "Significance",
        content: "Compelling"
      },
      {
        type: "Sensibility",
        content: "Perseverant"
      },
      {
        type: "Altruistic",
        content: "Peaceable"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Convincing"
      },
      {
        type: "Significance",
        content: "Competitive"
      },
      {
        type: "Sensibility",
        content: "Detailed"
      },
      {
        type: "Altruistic",
        content: "Restrained"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Energizing"
      },
      {
        type: "Significance",
        content: "Resourceful"
      },
      {
        type: "Sensibility",
        content: "Respectful"
      },
      {
        type: "Altruistic",
        content: "Reserved"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Champion"
      },
      {
        type: "Significance",
        content: "Independent"
      },
      {
        type: "Sensibility",
        content: "Perceptive"
      },
      {
        type: "Altruistic",
        content: "Grateful"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Spontaneous"
      },
      {
        type: "Significance",
        content: "Sure"
      },
      {
        type: "Sensibility",
        content: "Scheduled"
      },
      {
        type: "Altruistic",
        content: "Patient"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Funny"
      },
      {
        type: "Significance",
        content: "Convincing"
      },
      {
        type: "Sensibility",
        content: "Dedicated"
      },
      {
        type: "Altruistic",
        content: "Companionable"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Lively"
      },
      {
        type: "Significance",
        content: "Strong-Willed"
      },
      {
        type: "Sensibility",
        content: "Self-Sacrificial"
      },
      {
        type: "Altruistic",
        content: "Adaptable"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Inspiring"
      },
      {
        type: "Significance",
        content: "Confident"
      },
      {
        type: "Sensibility",
        content: "Faithful"
      },
      {
        type: "Altruistic",
        content: "Content"
      }
    ]
  },
  {
    question: "Which strength describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Popular"
      },
      {
        type: "Significance",
        content: "Productive"
      },
      {
        type: "Sensibility",
        content: "Thoughtful"
      },
      {
        type: "Altruistic",
        content: "Diplomatic"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Sassy"
      },
      {
        type: "Significance",
        content: "Domineering"
      },
      {
        type: "Sensibility",
        content: "Anxious"
      },
      {
        type: "Altruistic",
        content: "Expressionless"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Undisciplined"
      },
      {
        type: "Significance",
        content: "Callous"
      },
      {
        type: "Sensibility",
        content: "Unforgiving"
      },
      {
        type: "Altruistic",
        content: "Uninterested"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Preoccupied"
      },
      {
        type: "Significance",
        content: "Outspoken"
      },
      {
        type: "Sensibility",
        content: "Grouchy"
      },
      {
        type: "Altruistic",
        content: "Fearful"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Interrupts"
      },
      {
        type: "Significance",
        content: "Impatient"
      },
      {
        type: "Sensibility",
        content: "Insecure"
      },
      {
        type: "Altruistic",
        content: "Indecisive"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Inconsistent"
      },
      {
        type: "Significance",
        content: "Aloof"
      },
      {
        type: "Sensibility",
        content: "Unpopular"
      },
      {
        type: "Altruistic",
        content: "Uninvolved"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Lax"
      },
      {
        type: "Significance",
        content: "Superior"
      },
      {
        type: "Sensibility",
        content: "Downer"
      },
      {
        type: "Altruistic",
        content: "Plain"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Naive"
      },
      {
        type: "Significance",
        content: "Brash"
      },
      {
        type: "Sensibility",
        content: "Negative"
      },
      {
        type: "Altruistic",
        content: "Complacent"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Long-Winded"
      },
      {
        type: "Significance",
        content: "Rude"
      },
      {
        type: "Sensibility",
        content: "Hyper-Sensitive"
      },
      {
        type: "Altruistic",
        content: "Apprehensive"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Cluttered"
      },
      {
        type: "Significance",
        content: "Manipulative"
      },
      {
        type: "Sensibility",
        content: "Tempermental"
      },
      {
        type: "Altruistic",
        content: "Mutters"
      }
    ]
  },
  {
    question: "Which weakness describes you best",
    answers: [
      {
        type: "Entertainer",
        content: "Show-Off"
      },
      {
        type: "Significance",
        content: "Hard-Headed"
      },
      {
        type: "Sensibility",
        content: "Distrustful"
      },
      {
        type: "Altruistic",
        content: "Lethargic"
      }
    ]
  }
];

export default assessmentQuestions;
