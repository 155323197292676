//Add cloudfunction to listen for when users purchase codes for others.
//Add 'coach: true' to their user object at that point, and allow them to retrieve results for all of their users.
//Also add admin functionality to allow me to add users under coaches.
import React, { useContext, useEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { compose } from "recompose";
import { Switch, Route, Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "../CustomHooks";
import * as ROUTES from "../../constants/routes";
import styled, { keyframes } from "styled-components";
import TeamGraphsComponent from "../Team/teamGraphsComponent";
import Result from "../Assessments/Typology/AssessmentComponents/Results";
import ResultSocial from "../Assessments/SocialStyles/AssessmentComponents/Results";
import PurchaseCodesComponent from "../Assessments/PurchaseCodes/PurchaseCodes";

import { pulse } from "react-animations";
import StyledButton from "../../assests/buttons";

const pulseAnimation = keyframes`${pulse}`;

const PortalComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vw;
  width: 30vw;
  margin: 5vw;
  align-items: center;
  @media only screen and (max-width: 1270px) {
    width: 40vw;
    margin-right: 2vw;
  }
  @media only screen and (max-width: 950px) {
    width: 65vw;
  }
  @media only screen and (max-width: 799px) {
    width: 80vw;
  }
`;

const CoachesPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CohortAndCodesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1150px) {
    flex-direction: column;
  }
`;

const PurchaseCodesComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vw;
`;

const MembersPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GiveAccessButton = styled.button`
  display: flex;
  width: 470px;
  height: 45px;
  justify-content: center;
  font-size: 26px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-left: 80px;
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: #8abd6a;
    transition: all 0.3s ease-in-out 0s;
    text-decoration: none;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const MembersListWrapper = styled.ul`
  list-style: none;
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const StepTitleText = styled.p`
  font-family: Arial, Helvetica sans-serif;
  color: white;
  padding: 3px;
  font-size: 1.8rem;
  font-weight: 600;
  padding-top: 1rem;
`;

const StepTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #469dd7, #85469b);
  border-radius: 1.5rem;
  min-width: 8rem;
  width: 8rem;
  height: 3rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 799px) {
    margin-bottom: 4px;
  }
`;

const StepText = styled.p`
  margin-left: 10px;
  margin-right: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  padding-top: 4px;
  padding-right: 2vw;
`;

const MemberButton = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: black;
  font-size: 26px;
  width: 250px;

  &:hover {
    animation: 0.5s ${pulseAnimation};
  }
`;

const UsersListTitle = styled.p`
  font-size: 1.8rem;
  font-family: Arial, Helvetica, sans-serif;
`;

const FilterUserForm = styled.form`
  margin-bottom: 1rem;
`;

const CoachesPortal = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.COACHES_MEMBER} component={IndividualMember} />
      <Route exact path={ROUTES.COACHES} component={MembersList} />
    </Switch>
  </div>
);

const MembersListBase = ({ firebase }) => {
  const [coachesmembers, setCoachesmembers] = useState([]);
  const [cohortFiltered, setCohortFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    const fetchUserData = async () => {
      const usersCohortObject = await firebase
        .users()
        .doc(authUser.uid)
        .collection(`coachingCohort`)
        .get();
      var usersCohortArr = [];
      usersCohortObject.forEach((doc) =>
        usersCohortArr.push({ ...doc.data(), uid: doc.id })
      );
      setCoachesmembers(usersCohortArr);
      setIsLoading(false);
    };
    fetchUserData();
  }, []);

  const { inputs, handleSubmit, handleInputChange } = useForm();

  useEffect(() => {
    var searchString;
    var membersFilteredTemp;
    if (inputs.filterMembers) {
      searchString = inputs.filterMembers.toLowerCase();
    } else {
      searchString = false;
    }

    if (searchString) {
      membersFilteredTemp = coachesmembers.filter((member) =>
        member.member.toLowerCase().includes(searchString)
      );
    } else {
      membersFilteredTemp = coachesmembers.map((member) => member);
    }

    setCohortFiltered(membersFilteredTemp);
  }, [inputs.filterMembers, coachesmembers]);

  const loadingPage = () => {
    return (
      <div>
        <h3>Loading Members</h3>
        <Spinner />
      </div>
    );
  };

  const StyledLine = styled.div`
    height: 2px;
    width: 15vw;
    background-image: linear-gradient(
      to right,
      #ee3189,
      #f36d21,
      #70c8b8,
      #469dd7
    );
    margin-bottom: 25px;
    @media only screen and (max-width: 1050px) {
      margin-bottom: 10px;
      width: 400px;
    }
    @media only screen and (max-width: 799px) {
      margin-bottom: 10px;
      width: 60vw;
    }
  `;

  const membersExist = coachesmembers.length > 0;

  const cohortPage = () => {
    return (
      <CohortAndCodesWrapper>
        {membersExist && (
          <PortalComponentWrapper>
            <UsersListTitle>Coach's Cohort Members</UsersListTitle>
            <StyledLine />
            <FilterUserForm>
              <input
                name="filterMembers"
                type="string"
                placeholder="Filter Users"
                value={inputs.filterMembers}
                onChange={handleInputChange}
              />
            </FilterUserForm>
            <MembersListWrapper>
              {cohortFiltered.map((member) => (
                <li key={member.uid}>
                  <span>
                    <Link
                      to={{
                        pathname: `${ROUTES.COACHES}/${member.uid}`,
                        state: { member },
                      }}>
                      <MemberButton>{member.member}</MemberButton>
                    </Link>
                  </span>
                </li>
              ))}
            </MembersListWrapper>
          </PortalComponentWrapper>
        )}
        <PurchaseCodesComponentWrapper>
          <StepWrapper>
            <StepTitleWrapper>
              <StepTitleText>Step 1</StepTitleText>
            </StepTitleWrapper>
            <StepText>
              Buy a code for your team member using the form below, they will
              recieve the code by email.
            </StepText>
          </StepWrapper>
          <StepWrapper>
            <StepTitleWrapper>
              <StepTitleText>Step 2</StepTitleText>
            </StepTitleWrapper>
            <StepText>
              Have your team member take the assessment on their own time, they
              can only view results if you release them.
            </StepText>
          </StepWrapper>
          <StepWrapper>
            <StepTitleWrapper>
              <StepTitleText>Step 3</StepTitleText>
            </StepTitleWrapper>
            <StepText>
              Coach your team member through their results by clicking on their
              name in your cohort list.
            </StepText>
          </StepWrapper>
          <PurchaseCodesComponent />
        </PurchaseCodesComponentWrapper>
      </CohortAndCodesWrapper>
    );
  };

  return (
    <CoachesPageWrapper>
      <h1>Coach's Portal</h1>
      {isLoading ? loadingPage() : cohortPage()}
    </CoachesPageWrapper>
  );
};

const IndividualMemberBase = ({ firebase }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [member, setMember] = useState(undefined);
  const [teamsOfUser, setTeamsOfUser] = useState(undefined);
  const [currentTeamDisplayed, setCurrentTeamDisplayed] = useState(0);

  const authUser = useContext(AuthUserContext);
  let params = useParams();

  useEffect(() => {
    const retrieveUserInfo = async () => {
      let individualMember = await firebase
        .users()
        .doc(authUser.uid)
        .collection(`coachingCohort`)
        .doc(params.id)
        .get();
      setMember({
        name: individualMember.data().member,
        typologyResults: individualMember.data().typologyResult,
        socialResults: individualMember.data().socialStylesResult,
        typologyResultsNums: individualMember.data().typologyResultNums,
        givenResultsAccess: individualMember.data().givenResultsAccess,
      });
      let teamsObject = await firebase
        .users()
        .doc(authUser.uid)
        .collection(`coachingCohort`)
        .doc(params.id)
        .collection(`teams`)
        .get();
      let teamsTempArr = [];
      if (teamsObject.length > 0) {
        teamsObject.forEach((team) =>
          console.log(
            `Fetched team with SP:  ${team.data().typologyResults["SP"]}`
          )
        );
        teamsObject.forEach((team) => {
          let teamTempObj = {};
          teamTempObj.typologyOfTeam = team.data().typologyResults || false;
          teamTempObj.socialStylesOfTeam =
            team.data().socialStylesResults || false;
          teamTempObj.teamName = team.data().teamName;
          teamsTempArr.push(teamTempObj);
        });
      } else {
        console.log(`The user is on no teams`);
      }
      setTeamsOfUser(teamsTempArr);
      setIsLoading(false);
    };

    retrieveUserInfo();
  }, [params]);

  const onGiveUserAccess = async () => {
    try {
      await firebase
        .user(authUser.uid)
        .collection(`coachingCohort`)
        .doc(params.id)
        .update({ givenResultsAccess: true });
      setMember({ ...member, givenResultsAccess: true });
    } catch (e) {
      console.error(e);
    }
  };

  const incrementTeam = () => {
    if (teamsOfUser.size - 1 <= currentTeamDisplayed) {
      setCurrentTeamDisplayed(currentTeamDisplayed + 1);
    } else {
      setCurrentTeamDisplayed(0);
    }
  };

  return (
    <MembersPageWrapper>
      <Link to={{ pathname: `${ROUTES.COACHES}` }}>
        <StyledButton secondary fontSize="1.3rem">
          Back to All Members
        </StyledButton>
      </Link>
      {!isLoading && (
        <MembersPageWrapper>
          {member.name && member.typologyResults ? (
            <Result
              assessmentResult={member.typologyResults}
              assessmentResultNums={member.typologyResultsNums}
              source={"Coach"}
              memberName={member.name}
            />
          ) : (
            <p>The member hasn't taken the Typology Assessment yet.</p>
          )}
          <h5>Social Styles Result: </h5>
          {member.socialResults ? (
            <ResultSocial assessmentResult={member.socialResults} />
          ) : (
            <p>The member hasn't taken the Social Styles Assessment yet.</p>
          )}
          {teamsOfUser.length > 1 ? (
            <button onClick={incrementTeam}>Increment team by One</button>
          ) : null}
          {teamsOfUser && teamsOfUser.length > 0 ? (
            <TeamGraphsComponent
              resultsMap={teamsOfUser[0].typologyOfTeam}
              resultsMapSocial={teamsOfUser[0].socialStylesOfTeam}
              teamName={teamsOfUser[0].teamName}
              source={"Coach"}
            />
          ) : (
            <p>This member is not on any teams</p>
          )}
          {!member.givenResultsAccess && (
            <GiveAccessButton onClick={() => onGiveUserAccess()}>
              Give User Access to Their Results
            </GiveAccessButton>
          )}
        </MembersPageWrapper>
      )}
    </MembersPageWrapper>
  );
};

const MembersList = withFirebase(MembersListBase);
const IndividualMember = withFirebase(IndividualMemberBase);

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(CoachesPortal);
