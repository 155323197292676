import React, { useEffect, useContext, useState } from "react";
import { compose } from "recompose";
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from "../Session";
import { withFirebase } from "../Firebase";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import TypologyPage from "./Typology";
import SocialStylesPage from "./SocialStyles";
import AssessmentsHome from "./AssessmentsHome";

const AssessmentsPage = ({ firebase }) => {
  const [loading, setLoading] = useState(true);
  const [tookTypologyAssessment, setTookTypologyAssessment] = useState(false);
  const [tookSocialAssessment, setTookSocialAssessment] = useState(false);

  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    let authUserListener;
    const getUserData = async () => {
      authUserListener = await firebase.user(authUser.uid);
      authUserListener.onSnapshot((doc) => {
        if (doc.exists) {
          let tookTypoloy = doc.data().userTookAssessmentTypology;
          let tookSocial = doc.data().userTookAssessmentSocial;
          setTookTypologyAssessment(tookTypoloy);
          setTookSocialAssessment(tookSocial);
          setLoading(false);
        }
      });
    };

    getUserData();

    return authUserListener;
  }, []);

  return (
    <Switch>
      <Route exact path={ROUTES.ASSESSMENTS}>
        <AssessmentsHome
          tookTypology={tookTypologyAssessment}
          tookSocial={tookSocialAssessment}
        />
      </Route>
      <Route exact path={ROUTES.TYPOLOGY}>
        <TypologyPage tookTypology={tookTypologyAssessment} />
      </Route>
      <Route exact path={ROUTES.SOCIALSTYLES} component={SocialStylesPage} />
    </Switch>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(AssessmentsPage);
