import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as ROUTES from "../../../constants/routes";

const HeadingTitle = styled.h2``;

const InfoText = styled.p``;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const AquescenceOptions = styled.div`
  display: flex;
  flex-direction: row;
`;

const DemographicSurveyIntro = (props) => {
  const history = useHistory();

  const [rejectedTerms, setRejectedTerms] = useState(false);

  const acceptTerms = () => {
    if (rejectedTerms) {
      setRejectedTerms(false);
    }
    if (!rejectedTerms) {
      props.storeUserGavePermissions();
    }
  };

  const rejectTerms = () => {
    if (rejectedTerms) {
      history.push(ROUTES.LANDING);
    }
    setRejectedTerms(true);
  };

  return (
    <Modal
      show={props.showModalPermissions}
      onHide={null}
      backdrop="static"
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>Disclosures and Information</Modal.Title>
      </Modal.Header>
      {!rejectedTerms && (
        <Modal.Body>
          All of your results from assessments, the demographic survey, or any
          other data we collect will be used only by us. We will use this data
          to do research, develop new products, and improve the products we
          already have. None of this data will be used in connection with your
          name. In fact we will store your results under a random identifier.
          <hr />
          By clicking accept below, you understand and agree to allow all data
          provided for our research and development purposes.
        </Modal.Body>
      )}
      {rejectedTerms && (
        <Modal.Body>
          If you do not agree to the terms of service, we unfortunately cannot
          offer our services. Please click.
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={rejectTerms}>
          Reject
        </Button>
        <Button variant="primary" onClick={acceptTerms}>
          {!rejectedTerms && "Accept"}
          {rejectedTerms && "Review Terms"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DemographicSurveyIntro;
