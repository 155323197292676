import React, { useState, useContext } from "react";
import { withFirebase } from "../Firebase";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { compose } from "recompose";
import Spinner from "react-bootstrap/Spinner";
import * as SCREEN from "../../constants/values";
import MyTeam from "./myTeamsComponent";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import CreateTeamComponent from "./createTeamComponent";
import TeamManagementComponent from "./teamManagementComponent";
import JoinTeamComponenet from "./joinTeamComponent";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin-top: 60px;
  //Code to make a border gradient
  margin-left: 35vw;
  margin-right: 35vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
  @media only screen and (max-width: 1150px) {
    margin-left: 25vw;
    margin-right: 25vw;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;

const ProcessingTextWrapper = styled.p`
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  text-align: center;
`;

const StyledSpinner = styled(Spinner)`
  padding: 40px;
`;

const Team = ({ firebase }) => {
  const [resultsMap, setResultsMap] = useState({
    E: 0,
    I: 0,
    S: 0,
    N: 0,
    T: 0,
    F: 0,
    J: 0,
    P: 0,
    SJ: 0,
    SP: 0,
    NT: 0,
    NF: 0,
  });
  const [resultsMapSocial, setResultsMapSocial] = useState({
    significanceDom: 0,
    altruisticDom: 0,
    sensibilityDom: 0,
    entertainerDom: 0,
    significanceSec: 0,
    altruisticSec: 0,
    sensibilitySec: 0,
    entertainerSec: 0,
  });
  const [showCreate, setShowCreate] = useState(false);
  const handleCloseCreateTeam = () => setShowCreate(false);
  const openCreateTeam = () => setShowCreate(true);

  const [leadsATeam, setLeadsATeam] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const handleCloseManageTeam = () => setShowManage(false);
  const openManage = () => setShowManage(true);

  const [showJoin, setShowJoin] = useState(false);
  const handleCloseJoin = () => setShowJoin(false);
  const openJoin = () => setShowJoin(true);
  const [processing, setProcessing] = useState(false);

  const authUser = useContext(AuthUserContext);

  //Erase this once new options have been created/set
  // const renderDesktop = () => {
  //   return (
  //     <SubMenuDesktop>
  //       <SubMenuOption onClick={openCreateTeam}>Create Team</SubMenuOption>
  //       <SubMenuOption onClick={openJoin}>Join Team</SubMenuOption>
  //       {leadsATeam && (
  //         <SubMenuOption onClick={openManage}>Manage Team</SubMenuOption>
  //       )}
  //     </SubMenuDesktop>
  //   );
  // };

  let userListener = firebase.users().doc(authUser.uid);
  userListener.onSnapshot((user) => {
    let teamLead = user.data().director;
    if (teamLead) {
      setLeadsATeam(true);
    }
  });

  return (
    <div>
      <Modal show={showCreate} onHide={handleCloseCreateTeam}>
        <Modal.Header closeButton>
          <Modal.Title>Create Team</Modal.Title>
        </Modal.Header>
        <CreateTeamComponent
          firebase={firebase}
          onCloseModal={handleCloseCreateTeam}
          onOpenManageTeam={openManage}
        />
      </Modal>
      <Modal show={showManage} onHide={handleCloseManageTeam}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Team</Modal.Title>
        </Modal.Header>
        <TeamManagementComponent
          firebase={firebase}
          onCloseModal={handleCloseManageTeam}
          setLeadsATeam={setLeadsATeam}
        />
      </Modal>
      <Modal show={showJoin} onHide={handleCloseJoin}>
        <Modal.Header closeButton>
          <Modal.Title>Join Team</Modal.Title>
        </Modal.Header>
        <JoinTeamComponenet
          firebase={firebase}
          onCloseJoin={handleCloseJoin}
          authUser={authUser}
          setProcessing={setProcessing}
        />
      </Modal>

      <PageWrapper>
        {processing && (
          <LoadingBorder>
            <LoadingWrapper>
              <ProcessingTextWrapper>
                Adding you to a team...
              </ProcessingTextWrapper>
              <StyledSpinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </StyledSpinner>
            </LoadingWrapper>
          </LoadingBorder>
        )}
        <MyTeam
          resultsMap={resultsMap}
          resultsMapSocial={resultsMapSocial}
          setResultsMap={setResultsMap}
          setResultsMapSocial={setResultsMapSocial}
          firebase={firebase}
          authUser={authUser}
          openJoin={openJoin}
          openCreate={openCreateTeam}
          setProcessing={setProcessing}
        />
      </PageWrapper>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(Team);
