import React, { useState, useEffect, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { compose } from "recompose";
import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import styled, { css } from "styled-components";
import useForm from "../CustomHooks";
import GoogleSigninButtonImage from "./img/btn_google_signin_light_normal_web@2x.png";
import FacebookSigninButtonImage from "./img/signinButtonFacebook.png";
import StyledButton from "../../assests/buttons";
import useProgressiveImg from "../../assests/progressiveImage";
import fullResBRImg from "../../assests/images/fullResLoginBackground.jpg";
import lowResBRImg from "../../assests/images/lowResLoginBackground.jpg";

const BackgroundImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;

  ${(props) =>
    props.fadeIn &&
    css`
      opacity: 1;
      transition: opacity 0.1s ease-in;
    `}

  /* filter: ${(blur) => (blur ? "blur(200px)" : "none")};
  transition: ${(blur) => (blur ? "none" : "filter 0.3s ease-out")}; */
  filter: blur(20px);

  /* object-position: 0% 90%; */
  transform: scale(1.03);
  object-fit: cover;
  z-index: -2;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;
  justify-content: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PWForgetWrapper = styled.div`
  display: flex;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 12px;
  padding-top: 3px;
  margin-top: 15px;
`;

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  font-size: 18px;
  color: #5b5656;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 15px;
`;

//const mobile = useContext(MobileContext);
//TODO: get the code here to work then change the "left" below to 4% when mobile is true
const BoxDesktop = styled.div`
  position: absolute;
  top: 25vh;
  left: 33.3%;
  transform: translate(-50% -50%);
  width: 400px;
  background-color: white;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;
`;

const BoxMobile = styled.div`
  position: relative;
  top: 20vh;
  /* transform: translate(-50% -50%); */
  width: 350px;
  background-color: white;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;
  @media only screen and (max-height: 850px) {
    top: 10vh;
  }
  @media only screen and (max-height: 780px) {
    top: 5vh;
  }
  @media only screen and (max-height: 700px) {
    top: 2vh;
  }
  @media only screen and (max-height: 650px) {
    padding-bottom: 20px;
  }
`;

const Heading2 = styled.h2`
  color: #5b5656;
  text-align: center;
  margin-bottom: 30px;
`;

const InputBox = styled.div`
  position: relative;
  width: 300px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #85469b" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const OrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  position: relative;
  justify-content: center;
  height: 30px;
`;

const LoginInput = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const InvisibleButton = styled.button`
  background: transparent;
  border: none !important;
  font-size: 0;
`;

const LoginButtonImg = styled.img`
  height: 60px;
`;
const LoginButtonImgFB = styled.img`
  height: 55px;
`;

const SignInPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const displayMobile = windowWidth >= 900 ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const BlurredUpBackground = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [src, { blur, cancelAnimate }] = useProgressiveImg(
      lowResBRImg,
      fullResBRImg
    );
    setTimeout(() => {
      setFadeIn(true);
    }, 160);

    return (
      <BackgroundImageWrapper>
        <BackgroundImage
          fadeIn={fadeIn}
          style={
            blur
              ? { filter: "blur(20px) grayscale(100%)" }
              : cancelAnimate
              ? { filter: "grayscale(100%)" }
              : {
                  filter: "grayscale(100%)",
                  transition: "filter 0.3s ease-in",
                }
          }
          src={src}
        />
      </BackgroundImageWrapper>
    );
  };

  const SigninLayoutMobile = () => {
    return (
      <MobileWrapper>
        <BoxMobile>
          <Heading2>Login</Heading2>
          <SignInForm />
          <PWForgetWrapper>
            <PasswordForgetLink />
          </PWForgetWrapper>
          <ComponentWrapper>
            <SignInGoogle />
          </ComponentWrapper>
          <ComponentWrapper>
            <SignInFacebook />
          </ComponentWrapper>
          <OrWrapper>
            <p> - or - </p>
          </OrWrapper>
          <SignUpLink />
        </BoxMobile>
      </MobileWrapper>
    );
  };

  const SigninLayoutDesktop = () => {
    return (
      <React.Fragment>
        <DesktopWrapper>
          <BoxDesktop>
            <Heading2>Login</Heading2>
            <SignInForm />
            <PWForgetWrapper>
              <PasswordForgetLink />
            </PWForgetWrapper>
            <ComponentWrapper>
              <SignInGoogle />
            </ComponentWrapper>
            <ComponentWrapper>
              <SignInFacebook />
            </ComponentWrapper>
            <OrWrapper>
              <p> - or - </p>
            </OrWrapper>
            <SignUpLink />
          </BoxDesktop>
        </DesktopWrapper>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <BlurredUpBackground />
      {displayMobile ? <SigninLayoutMobile /> : <SigninLayoutDesktop />}
    </React.Fragment>
  );
};

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
An account with an Email address to 
this social account already exists.  Try to login from 
this account instead and associate your social accounts on 
your personal account page.
`;

const SignInFormBase = ({ firebase }) => {
  const [error, setError] = useState(null);
  const [hasFocusUN, setHasFocusUN] = useState(false);
  const [hasFocusPW, sethasFocusPW] = useState(false);
  const [emailMessage, setEmailMessage] = useState(null);
  const [emailEntered, setEmailEntered] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);

  const history = useHistory();

  const submitSignIn = async () => {
    //These are defined here because they are used in both flows.
    //User enters email => signin methods are fetched (stateVar emailEntered=false) => emailAndPassword overrides emailLink =>
    //  if the user has not password, a link will be sent the they are presented a message => if the user has a password, they have the option of
    //  entering it to login or leaving it blank to signin with email link
    var actionCodeSettings = {
      url: "https://teamtype.io/signinlink",
      handleCodeInApp: true,
    };
    //Do this if emailEntered is false (initially)
    if (!emailEntered) {
      //TODO: Enter code to check if login is password or email link, then setEmailEntered(true), and finally only display password entry if the
      // method is appropriate for that
      try {
        const loginMethod = await firebase.doFetchEmailLoginMethods(
          inputs.email
        );
        console.log(`Returned login method: ${loginMethod}`);
        if (loginMethod === "emailAndPassword") {
          setHasPassword(true);
        }
        if (loginMethod === "emailLink") {
          await firebase.doSendEmailLinkSignIn(
            actionCodeSettings,
            inputs.email
          );
          //Save email so user doesn't have to re-enter after following email link
          window.localStorage.setItem("emailForSignIn", inputs.email);
          setEmailMessage(
            `An email link for signin was sent to ${inputs.email}, please click the link in your email to complete signin.`
          );
          // .catch(function (error) {
          //   console.error(error.code);
          // });
        }
      } catch (e) {
        console.error(
          `Encountered error when fetching signin methods and deteremining flow: ${e}`
        );
      }
      buttonText.current = "Send Sign In Email";
    }
    //Do this if emailEntered is true (initially)
    if (emailEntered) {
      if (
        (typeof inputs.password === "string" ||
          inputs.password instanceof String) &&
        inputs.password !== ""
      ) {
        firebase
          .doSignInWithEmailAndPassword(inputs.email, inputs.password)
          .then((props) => {
            history.push(ROUTES.LANDING);
          })
          .catch((error) => {
            setError(error);
          });
      } else {
        firebase
          .doSendEmailLinkSignIn(actionCodeSettings, inputs.email)
          .then(function () {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem("emailForSignIn", inputs.email);
          })
          .then(
            setEmailMessage(
              `An email link for signin was sent to ${inputs.email}, please click the link in your email to complete signin.`
            )
          )
          .catch(function (error) {
            console.error(error.code);
          });
      }
    }
    setEmailEntered(true);
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(submitSignIn, {
    email: "",
    password: "",
  });

  const setFocusUN = (hasFocusUN) => {
    if (!inputs.email) {
      setHasFocusUN(hasFocusUN);
    }
  };

  const setFocusPW = (hasFocusPW) => {
    if (!inputs.password) {
      sethasFocusPW(hasFocusPW);
    }
  };

  var buttonText = useRef("Use Email");

  useEffect(() => {
    if (typeof inputs.password === "string" && inputs.password !== "") {
      buttonText.current = "Use Password";
    } else if (emailEntered) {
      buttonText.current = "Send Sign In Email";
    } else {
      buttonText.current = "Use Email";
    }
  }, [emailEntered, inputs.password]);

  const isInvalid =
    inputs.email === "" || inputs.email === null || inputs.email === undefined;

  return (
    <DesktopWrapper>
      {emailMessage && <Text>{emailMessage}</Text>}
      {!emailMessage && emailEntered && hasPassword && (
        <StyledForm onSubmit={handleSubmit}>
          <Text>Leave Password blank to sign in with an email link</Text>
          <InputBox>
            <LoginInput
              name="password"
              value={inputs.password}
              onChange={handleInputChange}
              type="password"
              onFocus={() => setFocusPW(true)}
              onBlur={() => setFocusPW(false)}
            />
            <InputLabel hasFocus={hasFocusPW}>Password</InputLabel>
          </InputBox>

          <StyledButton
            primary
            width="260px"
            fontSize="1.5rem"
            disabled={isInvalid}
            type="submit">
            {buttonText.current}
          </StyledButton>
        </StyledForm>
      )}
      {!emailMessage && !emailEntered && (
        <StyledForm onSubmit={handleSubmit}>
          <InputBox>
            <LoginInput
              name="email"
              value={inputs.email}
              onChange={handleInputChange}
              type="text"
              onFocus={() => setFocusUN(true)}
              onBlur={() => setFocusUN(false)}
            />
            <InputLabel hasFocus={hasFocusUN}>Email Address</InputLabel>
          </InputBox>

          <StyledButton
            primary
            width="260px"
            fontSize="1.5rem"
            disabled={isInvalid}
            type="submit">
            {buttonText.current}
          </StyledButton>

          {error && <Text>{error.message}</Text>}
        </StyledForm>
      )}
    </DesktopWrapper>
  );
};

const SignInGoogleBase = ({ firebase }) => {
  const [error, setError] = useState(null);

  const history = useHistory();

  const onSubmit = (event) => {
    firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        //Create a user in your Firebase Firestore too
        return firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: {},
          },
          {
            merge: true,
          }
        );
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.LANDING);
      })
      .catch((err) => {
        if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
          err.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setError({ err });
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      {/* <Button type="submit" variant="outline-success">
        Sign In with Google
      </Button> */}
      <InvisibleButton>
        <LoginButtonImg
          src={GoogleSigninButtonImage}
          alt="Google Sign In"
          onClick={onSubmit}
        />
      </InvisibleButton>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInFacebookBase = ({ firebase }) => {
  const [error, setError] = useState(null);

  const history = useHistory();

  const onSubmit = (event) => {
    firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        //Create a user in your Firebase Realtime Database too
        return firebase.user(socialAuthUser.user.uid).set(
          {
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: {},
          },
          {
            merge: true,
          }
        );
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.LANDING);
      })
      .catch((err) => {
        if (err.code === ERROR_CODE_ACCOUNT_EXISTS) {
          err.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setError(err);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <InvisibleButton>
        <LoginButtonImgFB
          src={FacebookSigninButtonImage}
          alt="Facebook Sign In"
          onClick={onSubmit}
        />
      </InvisibleButton>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

const SignInFacebook = compose(withRouter, withFirebase)(SignInFacebookBase);

const PasswordForget = compose(withRouter, withFirebase)(PasswordForgetLink);

export default SignInPage;
export { SignInForm, SignInGoogle, SignInFacebook, PasswordForget };
