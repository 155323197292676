var teamAssessmentQuestions = [
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "I can share something sensitive with my coworkers.",
        answerChoiceId: "1+Trust>1+Individual",
      },
      {
        content: "I have responsibility to make decisions",
        answerChoiceId: "1+Individual>1+Trust",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "My teammates will not use my mistakes against me.",
        answerChoiceId: "2+Trust>1+Ownership",
      },
      {
        content:
          "I have confidence in other members of my team to do their part",
        answerChoiceId: "1+Ownership>2+Trust",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content:
          "When times are difficult, I can have confidence my teammates will support me.",
        answerChoiceId: "3+Trust>1+Warm_Frankness",
      },
      {
        content:
          "If I have a problem with a member of my team I will share my concerns",
        answerChoiceId: "1+Warm_Frankness>3+Trust",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content:
          "I would describe my team members' communication as open and transparent.",
        answerChoiceId: "4+Trust>1+Balanced_Perspective",
      },
      {
        content:
          "My team does not lose sight of successes when faced with a failiure",
        answerChoiceId: "1+Balanced_Perspective>4+Trust",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "My teammates will not use my mistakes against me.",
        answerChoiceId: "2+Individual>2+Ownership",
      },
      {
        content:
          "I have confidence in other members of my team to do their part",
        answerChoiceId: "2+Ownership>2+Individual",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "I function with autonomy on my team",
        answerChoiceId: "3+Individual>2+Warm_Frankness",
      },
      {
        content:
          "If a team member is underperforming, other members will address it with them",
        answerChoiceId: "2+Warm_Frankness>3+Individual",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "My own inspiration energizes and motivates my work",
        answerChoiceId: "4+Individual>2+Balanced_Perspective",
      },
      {
        content: "My team faces the reality of a negative outcome head-on",
        answerChoiceId: "2+Balanced_Perspective>4+Individual",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "Members of my team take authority over their roles",
        answerChoiceId: "3+Ownership>3+Warm_Frankness",
      },
      {
        content:
          "When I'm confronted by a team member, I know they're on my side",
        answerChoiceId: "3+Warm_Frankness>3+Ownership",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content: "Candor and feedback are invited on our team",
        answerChoiceId: "4+Warm_Frankness>3+Balanced_Perspective",
      },
      {
        content: "My team is motivated by challenges",
        answerChoiceId: "3+Balanced_Perspective>4+Warm_Frankness",
      },
    ],
  },
  {
    questionText: "Which of these two are a greater strength of your team?",
    answers: [
      {
        content:
          "Members of my team see their purpose aligning with the mission of our organization",
        answerChoiceId: "4+Ownership>4+Balanced_Perspective",
      },
      {
        content: "My team is motivated by challenges",
        answerChoiceId: "4+Balanced_Perspective>4+Ownership",
      },
    ],
  },
  //Line of demarkation for negative dysfunction questions
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content:
          "It is not safe to talk about delicate matters with my teammates.",
        answerChoiceId: "1-Trust>1-Individual",
      },
      {
        content:
          "I have little say over how I complete jobs that are assigned to me",
        answerChoiceId: "1-Individual>1-Trust",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content: "An error on my part may be used by a teamate to get ahead.",
        answerChoiceId: "2-Trust>1-Ownership",
      },
      {
        content: "Other members often don't come through on their commitments",
        answerChoiceId: "1-Ownership>2-Trust",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content:
          "In trying times, I could not depend on my teammates to 'have my back'",
        answerChoiceId: "3-Trust>1-Warm_Frankness",
      },
      {
        content:
          "Our team doesn't make a habit of confronting other team members",
        answerChoiceId: "1-Warm_Frankness>3-Trust",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content:
          "I don't have clarity on what my teammates are really thinking",
        answerChoiceId: "4-Trust>1-Balanced_Perspective",
      },
      {
        content:
          "My team does not deal with a failiure when faced with a success",
        answerChoiceId: "1-Balanced_Perspective>4-Trust",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content: "My team members often interfer in my tasks",
        answerChoiceId: "2-Individual>2-Ownership",
      },
      {
        content:
          "When members of my team don't get their part of the work done, they claim it's not their fault",
        answerChoiceId: "2-Ownership>2-Individual",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content: "I would describe my team's character as micromanaging",
        answerChoiceId: "3-Individual>2-Warm_Frankness",
      },
      {
        content:
          "If a team member fails to come through, other members will not address the issue with them directly",
        answerChoiceId: "2-Warm_Frankness>3-Individual",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content:
          "I'm expected to follow the protocol/method given to me, my inspiration is not relevant",
        answerChoiceId: "4-Individual>2-Balanced_Perspective",
      },
      {
        content: "My team avoids 'facing the facts' of a negative outcome",
        answerChoiceId: "2-Balanced_Perspective>4-Individual",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content:
          "Members of my team rely on their leader/supervisor's authority instead of their own",
        answerChoiceId: "3-Ownership>3-Warm_Frankness",
      },
      {
        content:
          "If/when a team member confronts an issue with me, it feels cold and uncaring",
        answerChoiceId: "3-Warm_Frankness>3-Ownership",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content: "My team addresses issues passively, as candor is not welcome",
        answerChoiceId: "4-Warm_Frankness>3-Balanced_Perspective",
      },
      {
        content: "I would say my team dislikes challenges",
        answerChoiceId: "3-Balanced_Perspective>4-Warm_Frankness",
      },
    ],
  },
  {
    questionText:
      "Which of these two are a more significant dysfunction on your team?",
    answers: [
      {
        content:
          "Members of my team don't seem to work from personal purpose/mission",
        answerChoiceId: "4-Ownership>4-Balanced_Perspective",
      },
      {
        content: "Facts are spun into positive narratives as a team practice",
        answerChoiceId: "4-Balanced_Perspective>4-Ownership",
      },
    ],
  },
];
export default teamAssessmentQuestions;
