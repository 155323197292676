var DemographicSurveyQuestions = [
  {
    questionText: "What is your age?",
    questionName: "Age",
    answers: [
      {
        content: "10-20 years old",
        key: "10-20",
      },
      {
        content: "20-30 years old",
        key: "20-30",
      },
      {
        content: "30-40 years old",
        key: "30-40",
      },
      {
        content: "40-50 years old",
        key: "40-50",
      },
      {
        content: "50-60 years old",
        key: "50-60",
      },
      {
        content: "60-70 years old",
        key: "60-70",
      },
      {
        content: "70-100 years old",
        key: "70-100",
      },
    ],
  },
  {
    questionText: "What is your gender?",
    questionName: "Gender",
    answers: [
      {
        content: "female",
        key: "female",
      },
      {
        content: "male",
        key: "male",
      },
      {
        content: "other",
        key: "other",
      },
    ],
  },
  {
    questionText: "What is your ethnicity?",
    questionName: "Ethnicity",
    answers: [
      {
        content: "Caucasian",
        key: "Caucasian",
      },
      {
        content: "African-American",
        key: "African-American",
      },
      {
        content: "Latino or Hispanic",
        key: "Latino or Hispanic",
      },
      {
        content: "Asian",
        key: "Asian",
      },
      {
        content: "Native-American",
        key: "Native-American",
      },
      {
        content: "Native Hawaiian or Pacific Islander",
        key: "Native Hawaiian or Pacific Islander",
      },
      {
        content: "African",
        key: "African",
      },
      {
        content: "Two or More",
        key: "Two or More",
      },

      {
        content: "Other/Unknown",
        key: "Other/Unknown",
      },
    ],
  },
  {
    questionText: "Where is your home located?",
    questionName: "Residence",
    answers: [
      {
        content: "North America/Central America",
        key: "North America/Central America",
      },
      {
        content: "South America",
        key: "South America",
      },
      {
        content: "Europe",
        key: "Europe",
      },
      {
        content: "Africa",
        key: "Africa",
      },
      {
        content: "Asia",
        key: "Asia",
      },
      {
        content: "Australia",
        key: "Australia",
      },
      {
        content: "Caribbean Islands",
        key: "Caribbean Islands",
      },
      {
        content: "Pacific Islands",
        key: "Pacific Islands",
      },
      {
        content: "Other",
        key: "Other",
      },
    ],
  },
  {
    questionText:
      "What is the highest degree or level of education you have completed?",
    questionName: "Education",
    answers: [
      {
        content: "Some High School",
        key: "Some High School",
      },
      {
        content: "High School Diploma",
        key: "High School Diploma",
      },
      {
        content: "Bachelor's Degree",
        key: "Bachelor's Degree",
      },
      {
        content: "Master's Degree",
        key: "Master's Degree",
      },
      {
        content: "Ph.D. or higher (including all doctoral degrees)",
        key: "Ph.D. or higher",
      },
      {
        content: "Trade School",
        key: "Trade School",
      },
    ],
  },
  {
    questionText: "Are you married?",
    questionName: "Marriage Status",
    answers: [
      {
        content: "Yes",
        key: "true",
      },
      {
        content: "No",
        key: "false",
      },
    ],
  },
  {
    questionText: "What is your annual household income?",
    questionName: "Income",
    answers: [
      {
        content: "Less than $25,000",
        key: "<25",
      },
      {
        content: "$25,000 - $50,000",
        key: "25-50",
      },
      {
        content: "$50,000 - $100,000",
        key: "50-100",
      },
      {
        content: "$100,000 - $200,000",
        key: "100-200",
      },
      {
        content: "More than $200,000",
        key: ">200",
      },
      {
        content: "Prefer not to say",
        key: "undefined",
      },
    ],
  },
  {
    questionText: "What is your employment status?",
    questionName: "Employment",
    answers: [
      {
        content: "Employed Full-Time",
        key: "fulltime",
      },
      {
        content: "Employed Part-Time",
        key: "parttime",
      },
      {
        content: "Seeking Opportunities",
        key: "unemployed",
      },
      {
        content: "Retired",
        key: "retired",
      },
      {
        content: "Prefer not to say",
        key: "undefined",
      },
    ],
  },
];

export default DemographicSurveyQuestions;
