import React, { useState } from "react";
import styled from "styled-components";
import { black } from "@material-ui/core/colors";
import { Modal } from "@material-ui/core";
import AnimatedForm from "../../assests/forms";
import StyledButton from "../../assests/buttons";

const ModalDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalBorder = styled.div`
  padding: 3px;
  //Code to make a border gradient
  margin: 2vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 22px;
  margin: 30px;
`;

const ModalChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-right: 10px;
`;

const ModalTitle = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
`;

const DotSpan = styled.span`
  height: 15px;
  width: 15px;
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  @media only screen and (max-width: 1100px) {
    margin-top: 20px;
    height: 10px;
    width: 10px;
  }
  @media only screen and (max-width: 900px) {
    height: 7px;
    width: 7px;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
  }
  @media only screen and (max-width: 600px) {
    height: 5px;
    width: 5px;
    margin-top: 13px;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const StyledLineBottom = styled.div`
  height: 3px;
  width: 50vw;
  background-image: linear-gradient(
    to right,
    #ee3189,
    #f36d21,
    #70c8b8,
    #469dd7
  );
  @media only screen and (max-width: 1150px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 799px) {
    margin-bottom: 15px;
    width: 70vw;
  }
  z-index: 1;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  align-items: center;
`;

const SloganWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SloganTextOne = styled.p`
  font-family: "MontserratLight";
  font-size: 40px;
  font-weight: 500;
  @media only screen and (max-width: 1100px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const SloganTextTwo = styled.p`
  font-family: "MontserratMed";
  font-size: 40px;
  font-weight: 500;
  @media only screen and (max-width: 1100px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const SubText = styled.p`
  font-family: "MontserratReg";
  width: 50%;
  text-align: center;
`;

const BottomComponent = (props) => {
  const [open, setOpen] = useState(false);

  const submitContactForm = (inputs) => {
    handleClose();
    props.firebase.contacts().add(inputs);
    for (const [key, value] of Object.entries(inputs)) {
      console.log(`Key: ${key}, Value: ${value}`);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formInputs = [
    { name: "firstName", label: "First Name", type: "text" },
    { name: "lastName", label: "Last Name", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "phoneNumber", label: "Phone Number", type: "text" },
    {
      name: "contactMethodPreference",
      label: "Best Method for Contact",
      type: "radio",
      options: [
        { label: "Phone Call", value: "phone" },
        { label: "Email", value: "email" },
      ],
    },
    {
      name: "contactTimePreference",
      label: "Best Time for Contact",
      type: "radio",
      options: [
        { label: "Morning", value: "morning" },
        { label: "Afternoon", value: "afternoon" },
        { label: "Evening", value: "evening" },
      ],
    },
  ];

  const SubmitButtonStyle = {
    width: "220px",
    fontSize: "1.3rem",
  };

  const body = (
    <ModalDiv>
      <ModalBorder>
        <ModalInnerWrapper>
          <ModalTitle>I Want to Learn More</ModalTitle>
          <ModalChoiceWrapper>
            <AnimatedForm
              formInputs={formInputs}
              submitButtonText={"Contact Me"}
              submitFunction={submitContactForm}
              buttonStyle={SubmitButtonStyle}
              disableBox={true}
            />
          </ModalChoiceWrapper>
        </ModalInnerWrapper>
      </ModalBorder>
    </ModalDiv>
  );

  return (
    <BottomWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
      <StyledLineBottom />
      <SloganWrapper>
        <SloganTextOne>Know Your Team</SloganTextOne>
        <DotSpan />
        <SloganTextTwo>Grow Your Team</SloganTextTwo>
      </SloganWrapper>
      <SubText>
        We would love to give you more information about our range of products
        and services. Please feel free to contact us, we would love to answer
        any questions regarding how we may stregthen your team.
      </SubText>
      <StyledButton primary fontSize="1.5rem" callback={handleOpen}>
        Contact Us
      </StyledButton>
      <div style={{ height: "40px" }} />
    </BottomWrapper>
  );
};

export default BottomComponent;
