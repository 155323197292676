import React from "react";
import { useHistory } from "react-router-dom";
import { withFirebase } from "../Firebase";
import Button from "../../assests/buttons";
import * as ROUTES from "../../constants/routes";

const SignOutButton = ({ firebase }) =>
{
  const history = useHistory();

  const signOut = () => {
    console.log("Signing out");
    firebase.doSignOut().then(history.push(ROUTES.LANDING))
  }
  
  return <Button secondary fontSize="1.4rem"
    onClick={signOut}
  >
    Sign Out?
  </Button>;}
 
export default withFirebase(SignOutButton);
