import React, { useState } from "react";
import styled, { css } from "styled-components";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;
const Text = styled.p`
  padding-top: 20px;
  font-size: 20px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  @media only screen and (max-width: 850px) {
    padding-top: 18px;
  }
`;

const GraphBar1 = styled.div`
  height: 20px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ee3189, #f36d21);
  z-index: 2;
  width: 30px;
  top: -5px;
  transition: 1s ease-in;
  ${({ animate }) =>
    animate &&
    css`
      width: 62%;
    `}
  @media only screen and (max-width: 850px) {
    height: 15px;
    border-radius: 7px;
  }
`;
const GraphBar2 = styled.div`
  height: 20px;
  width: 30px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #469dd7, #70c8b8);
  z-index: 2;
  top: -5px;

  ${({ animate }) =>
    animate &&
    css`
      transition: width 0.8s ease-in;
      width: 90%;
    `}
  @media only screen and (max-width: 850px) {
    height: 15px;
    border-radius: 7px;
  }
`;
const GraphBar3 = styled.div`
  height: 20px;
  width: 30px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #85469b, #ee3189);
  z-index: 2;
  top: -5px;

  ${({ animate }) =>
    animate &&
    css`
      transition: width 0.7s ease-in;
      width: 72%;
    `}
  @media only screen and (max-width: 850px) {
    height: 15px;
    border-radius: 7px;
  }
`;
const GraphBar4 = styled.div`
  height: 20px;
  width: 30px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ffc516, #f36d21);
  z-index: 2;
  top: -5px;

  ${({ animate }) =>
    animate &&
    css`
      transition: width 0.3s ease-in;
      width: 50%;
    `}
  @media only screen and (max-width: 850px) {
    height: 15px;
    border-radius: 7px;
  }
`;

const EasyToRead = () => {
  const [animateBegin, setAnimateBegin] = useState(false);
  const [animateBegin2, setAnimateBegin2] = useState(false);
  const [animateBegin3, setAnimateBegin3] = useState(false);
  const [animateBegin4, setAnimateBegin4] = useState(false);

  setTimeout(() => {
    setAnimateBegin(true);
  }, 300);
  setTimeout(() => {
    setAnimateBegin2(true);
  }, 700);
  setTimeout(() => {
    setAnimateBegin3(true);
  }, 1000);
  setTimeout(() => {
    setAnimateBegin4(true);
  }, 1550);

  return (
    <CardWrapper>
      <Text>Honesty</Text>
      <GraphBar1 animate={animateBegin} />
      <Text>Ownership</Text>
      <GraphBar2 animate={animateBegin2} />
      <Text>Trust</Text>
      <GraphBar3 animate={animateBegin3} />
      <Text>Individualism</Text>
      <GraphBar4 animate={animateBegin4} />
    </CardWrapper>
  );
};

export default EasyToRead;
