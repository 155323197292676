import React from "react";
import styled from "styled-components";
import { GlobalStyles } from "../globalStyles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../../Theme";
import "bootstrap/dist/css/bootstrap.min.css";

import Nav from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import FinishSignUpPage from "../SignUp/finishSignUpPage";
import SignInPage from "../SignIn";
import SignInLinkPage from "../SignIn/signInLinkPage";
import PasswordForgetPage from "../PasswordForget";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import AssessmentsPage from "../Assessments";
import Team from "../Team";
import CoachesPortal from "../CoachesPortal";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

const AppWrapper = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100vw;
  background-color: white;
  z-index: -10;
`;

const App = () => {
  const reload = () => window.location.reload();

  return (
    // <ThemeProvider theme={lightTheme}>
    //   <GlobalStyles>
    <AppWrapper>
      <Router>
        <React.Fragment>
          <Nav />

          <Route exact path={ROUTES.LANDING} component={LandingPage} />

          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.FINISH_SIGN_UP} component={FinishSignUpPage} />
          <Route path={ROUTES.SIGNINLINK} component={SignInLinkPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ASSESSMENTS} component={AssessmentsPage} />
          <Route path={ROUTES.TEAM} component={Team} />
          <Route path={ROUTES.COACHES} component={CoachesPortal} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route
            path="/.wellknown/apple-developer-merchantid-domain-association.txt"
            onEnter={reload}
          />
        </React.Fragment>
      </Router>
    </AppWrapper>
    //   </GlobalStyles>
    // </ThemeProvider>
  );
};

export default withAuthentication(App);
