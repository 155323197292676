import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Link, useParams, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from "../Session";
import styled from "styled-components";
import { useForm, useForm2, useForm3 } from "../CustomHooks.js";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";

const CoachesList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const MembersCoached = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const UserListWrapper = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  border-top: 1px solid black;
`;

const EmailUsernameWrapper = styled.span`
  width: 450px;
  padding-left: 20px;
  font-family: roboto-light, sans-sarif;
  font-size: 18px;
`;

const IndividualItemWrapper = styled.span`
  width: 450px;
  padding-left: 20px;
  font-family: roboto-light, sans-sarif;
  font-size: 22px;
`;

const ResultItem = styled.span`
  padding-left: 5px;
  padding-right: 5px;
  font-family: roboto-light, sans-sarif;
  font-size: 22px;
  align-items: center;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
`;

const UserIndivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const AssessmentResultWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  font-family: roboto-bold, sans-sarif;
  font-size: 22px;
  align-items: center;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ToolsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const UserButton = styled.button`
  display: flex;
  width: 220px;
  height: 35px;
  justify-content: center;
  font-size: 16px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-top: 5px;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const UserPurchaseButton = styled.button`
  display: flex;
  width: 350px;
  height: 45px;
  justify-content: center;
  font-size: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-top: 5px;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const BackUserButton = styled.button`
  display: flex;
  width: 220px;
  height: 50px;
  justify-content: center;
  font-size: 22px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-top: 5px;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const ActionUserButton = styled.button`
  display: flex;
  width: 350px;
  height: 50px;
  justify-content: center;
  font-size: 22px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-top: 5px;
  margin-right: 20px;

  &:hover {
    background-color: red;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const GenCodesButton = styled.button`
  display: flex;
  width: 220px;
  height: 35px;
  justify-content: center;
  font-size: 16px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-left: 10px;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const GenerateCodesInput = styled.input`
  width: 600px;
  padding-left: 30px;
  font-family: roboto-light, sans-sarif;
  font-size: 18px;
`;

const AddUsersInput = styled.input`
  width: 1200px;
  padding-left: 30px;
  font-family: roboto-light, sans-sarif;
  font-size: 18px;
`;

const FormToolWrapper = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px;
`;

const HeaderDiv = styled.div`
  width: 100vw;
  height: 65px;
`;

const UserCharges = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 30px;
`;

const ChargesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChargeListItem = styled.div`
  font-family: roboto, sans-sarif;
  font-size: 22px;
  padding-top: 5px;
`;

const ChargeDetails = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  margin-left: 20px;
  font-size: 24px;
`;

const AdminPage = () => (
  <div>
    <HeaderDiv />

    <Switch>
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.ADMIN} component={UserList} />
    </Switch>
  </div>
);

const UserListBase = ({ firebase }) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);

  const authUser = useContext(AuthUserContext);

  const storeCode = () => {
    const generateUniqueCode = async () => {
      let generatedCode = undefined;
      let unusedCode = undefined;
      do {
        generatedCode = Math.random().toString(36).substr(2, 7);
        const firebaseCodeQueryObj = await firebase
          .codes()
          .where("accessCode", "==", generatedCode)
          .get();
        unusedCode = firebaseCodeQueryObj.empty;
        console.log(
          `Generated code: ${generatedCode} and it is unused: ${unusedCode}`
        );
      } while (!unusedCode);
      return generatedCode;
    };

    if (inputs.userEmail && inputs.userEmail !== "") {
      var emailsArr = inputs.userEmail.split(", ");
      console.log(
        `Generate a codes for ${emailsArr.length} users with emails attached`
      );
      let emailedCodes = [];
      emailsArr.forEach((emailAddress) => {
        generateUniqueCode().then(async (code) => {
          if (
            inputs.coach &&
            inputs.coach !== undefined &&
            inputs.coach !== ""
          ) {
            const coachDoc = await firebase
              .users()
              .where("email", "==", inputs.coach)
              .get();
            if (coachDoc.empty) {
              return alert(
                `We couldn't find the coach with email: ${inputs.coach}`
              );
            } else {
              let addCoach = coachDoc.docs[0].id;
              firebase.codes().add({
                used: false,
                userEmail: emailAddress,
                accessCode: code,
                source:
                  "Generated by admin, linked to user via email and coach",
                addCoach: addCoach,
              });
              emailedCodes.push(emailAddress);
            }
          } else {
            firebase.codes().add({
              used: false,
              userEmail: emailAddress,
              accessCode: code,
              source:
                "Generated by admin, linked to user via email but no coach",
            });
            emailedCodes.push(emailAddress);
          }
        });
      });
      alert(`Added codes ${emailedCodes}`);
    } else {
      alert(
        `no user emails have been entered!  Please enter a list of emails.`
      );
    }
  };

  const submitMassJoinTeam = async () => {
    //teamID is the doc.id for the team you want to add all users to
    var teamID = "";
    //Store errors so they can be displayed later.  This should be added to a state function like setErrorsMassAdding
    const errorsAddingMembers = [];
    console.log(
      `The team code used for the query is: ${inputs2.joinTeamPassword}`
    );

    //Check if there is a team with the entered password
    const snapshot = await firebase
      .teams()
      .where("teamCode", "==", inputs2.joinTeamPassword)
      .get();
    if (snapshot.empty) {
      alert("No team found with that password");
      return;
    }

    //Get the data from the team that matches this code
    snapshot.forEach((doc) => {
      //Display retrieved teams
      console.log(
        `The id of the team retrieved is ${
          doc.id
        } and the contents of it are ${doc.data()}`
      );
      teamID = doc.id;
    });

    //Get all the members of the team, we can see if this user is already on the team
    const membersSnapshot = await firebase
      .teams()
      .doc(teamID)
      .collection("members")
      .get();

    //Get email addresses in the list from form into array
    const userEmailsToAdd = inputs2.emailsOfUsersToAdd.split(", ");
    //Get only user objects in the email list by filtering out of the user objects that the page loads
    const userObjectsOfMembersToAddUnfiltered = users.filter((user) => {
      var matched = true;
      if (userEmailsToAdd.indexOf(user.email) === -1) {
        matched = false;
      }
      return matched;
    });
    console.log(
      `Users object we are trying to add, ${userObjectsOfMembersToAddUnfiltered.map(
        (user) => user
      )} `
    );

    var userObjectsOfMembersToAdd;
    //Remove user objects from the array who have already added the team
    if (!membersSnapshot.empty) {
      //Build array of members on the team (using UIDs)
      var membersAlreadyOnTeam = [];
      membersSnapshot.forEach((doc) => {
        membersAlreadyOnTeam.push(doc.data().memberId);
      });
      userObjectsOfMembersToAdd = userObjectsOfMembersToAddUnfiltered.filter(
        (user) => {
          var matched = false;
          if (membersAlreadyOnTeam.indexOf(user.uid) === -1) {
            matched = true;
          }
          return matched;
        }
      );
    } else {
      //Nobody is on the team yet, so we don't need to fileter members who have already added
      userObjectsOfMembersToAdd = userObjectsOfMembersToAddUnfiltered;
    }
    userObjectsOfMembersToAdd.forEach((userToAddObject) => {
      console.log(
        `After filter, trying to add member with: member:${
          userToAddObject.username
        }, email:${userToAddObject.email}, memberId:${
          userToAddObject.uid
        }, tookTypology:${
          userToAddObject.userTookAssessmentTypology
            ? userToAddObject.userTookAssessmentTypology
            : false
        }, tookSocial:${
          userToAddObject.userTookAssessmentSocial
            ? userToAddObject.userTookAssessmentSocial
            : false
        }`
      );
    });

    //Add the users to the team
    const fetchPromises = [];
    const usersAdded = [];
    userObjectsOfMembersToAdd.forEach((userObject) => {
      const nextPromise = firebase
        .teams()
        .doc(teamID)
        .collection("members")
        .doc(userObject.uid)
        .set({
          member: userObject.username,
          memberId: userObject.uid,
          tookTypology: userObject.userTookAssessmentTypology
            ? userObject.userTookAssessmentTypology
            : false,
          tookSocial: userObject.userTookAssessmentSocial
            ? userObject.userTookAssessmentSocial
            : false,
        })
        .then(usersAdded.push(userObject.email))
        .catch((error) => {
          errorsAddingMembers.push(
            error.code.concat(` on email ${userObject.email}`)
          );
        });
      fetchPromises.push(nextPromise);
    });
    await Promise.all(fetchPromises);
    console.log(
      `Succesfully added ${usersAdded} and encountered errors ${errorsAddingMembers}`
    );
  };

  useEffect(() => {
    setLoading(true);
    firebase.users().onSnapshot((snapshot) => {
      let users = [];

      snapshot.forEach((doc) => users.push({ ...doc.data(), uid: doc.id }));

      setUsers(users);
      setLoading(false);
    });
  }, [firebase]);

  const { inputs, handleSubmit, handleInputChange } = useForm(storeCode);
  const { inputs2, handleSubmit2, handleInputChange2 } =
    useForm2(submitMassJoinTeam);

  useEffect(() => {
    var searchString;
    var usersFilteredTemp;
    if (inputs.search) {
      searchString = inputs.search.toLowerCase();
    } else {
      searchString = false;
    }

    if (searchString) {
      usersFilteredTemp = users.filter((user) =>
        user.username.toLowerCase().includes(searchString)
      );
    } else {
      usersFilteredTemp = users.map((user) => user);
    }

    setUsersFiltered(usersFilteredTemp);
  }, [inputs.search, users]);

  // const runAdminFunction = () => {
  //   firebase
  //     .users()
  //     .doc(authUser.uid)
  //     .collection(`adminFunctions`)
  //     .add({ purpose: "updateUsersAccessDocs" });
  // };

  return (
    <PageWrapper>
      <h2>Users</h2>
      {loading && <div>Loading ...</div>}
      <ToolsWrapper>
        <FormToolWrapper>
          <input
            name="search"
            type="string"
            placeholder="Filter Users"
            value={inputs.search}
            onChange={handleInputChange}
          />
        </FormToolWrapper>
        <FormToolWrapper onSubmit={handleSubmit}>
          <GenerateCodesInput
            name="userEmail"
            value={inputs.userEmail}
            onChange={handleInputChange}
            type="text"
            placeholder="User Emails (seperate with comma and space)"
          />
          <input
            name="coach"
            type="string"
            placeholder="Default Coach's Email"
            value={inputs.coach}
            onChange={handleInputChange}
          />

          <GenCodesButton type="submit" variant="danger">
            Generate User Code(s)
          </GenCodesButton>
        </FormToolWrapper>
      </ToolsWrapper>
      <ul>
        {usersFiltered.map((user) => (
          <UserListWrapper key={user.uid}>
            <span>
              <Link
                to={{
                  pathname: `${ROUTES.ADMIN}/${user.uid}`,
                  state: { user },
                }}>
                <UserButton>{user.username}</UserButton>
              </Link>
            </span>
            <EmailUsernameWrapper>
              <strong>Email:</strong> {user.email}
            </EmailUsernameWrapper>
          </UserListWrapper>
        ))}
      </ul>
      <FormToolWrapper onSubmit={handleSubmit2}>
        <GenerateCodesInput
          name="emailsOfUsersToAdd"
          type="text"
          placeholder="list emails of users, separated by a comma and a space"
          value={inputs2.emailsOfUsersToAdd}
          onChange={handleInputChange2}
        />
        <input
          name="joinTeamPassword"
          type="text"
          placeholder="Enter Team Password"
          value={inputs2.joinTeamPassword}
          onChange={handleInputChange2}
        />
        <button type="submit">Mass Join Team</button>
      </FormToolWrapper>
      {/* <button onClick={() => runAdminFunction()}>Admin Function</button> */}
    </PageWrapper>
  );
};

const UserItemBase = ({ firebase }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [charges, setCharges] = useState([]);
  const [chargeDetailsToDisplay, setChargeDetailsToDisplay] = useState(null);
  const [accessTypology, setAccessTypology] = useState(false);
  const [accessSocial, setAccessSocial] = useState(false);
  const [coachingCohort, setCoachingCohort] = useState(undefined);

  let location = useLocation();
  let params = useParams();

  useEffect(() => {
    if (user) {
      return;
    }
    var chargesArr = [];
    setLoading(true);
    let coach = false;
    try {
      firebase.user(params.id).onSnapshot((snapshot) => {
        setUser(snapshot.data());

        firebase
          .codes()
          .where("userEmail", "==", snapshot.data().email)
          .get()
          .then((doc) => {
            if (doc && Object.keys(doc).length > 0) {
              doc.forEach((snap) =>
                console.log(`code is: ${snap.data().accessCode}`)
              );
            }
          });
        coach = snapshot.data().coach;
        setLoading(false);
      });
      firebase
        .user(params.id)
        .collection(`charges`)
        .get()
        .then((chargesDocs) => {
          // if (!chargesDocs) {
          chargesDocs.docs.forEach((charge) => chargesArr.push(charge.data()));
          // } else {
          //   return;
          // }
        })
        .then(() => {
          console.log(chargesArr);
          setCharges(chargesArr);
        });
      firebase
        .user(params.id)
        .collection(`access`)
        .doc(`hasAccessTypology`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAccessTypology(doc.data().access);
          }
        });
      firebase
        .user(params.id)
        .collection(`access`)
        .doc(`hasAccessSocial`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAccessSocial(doc.data().access);
          }
        });
      let coachingCohortTemp = [];
      firebase
        .user(params.id)
        .collection(`coachingCohort`)
        .get()
        .then((coachingCohortDocs) => {
          coachingCohortDocs.docs.forEach((member) =>
            coachingCohortTemp.push(member.data().member)
          );
          coachingCohortTemp.forEach((doc) => console.log(doc));
          setCoachingCohort(coachingCohortTemp);
        });
    } catch (e) {
      console.error(`Error getting user: ${e}`);
    }
  }, [firebase, params.id, user]);

  // Legacy code for adding codes
  const createCode = async () => {
    console.log("Generate a code");
    var currentCode = Math.random().toString(36).substr(2, 7);
    try {
      firebase
        .codes()
        .where("accessCode", "==", currentCode)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log(
              "This code already exists, this code cannot be utilized"
            );
            //This is to repeat if the code matches a code that already exists
            return createCode();
          }
        });
    } catch (err) {
      console.log("Error getting documents: ", err);
    }
    try {
      await firebase
        .codes()
        .add({
          used: false,
          source: "Generated by admin",
          accessCode: currentCode,
          userEmail: user.email,
          belongsToUser: params.id,
        })
        .then((ref) => {
          console.log("Added document with ID: ", ref.id);
        });
    } catch (err) {
      console.error("Error creating code in firebase", err);
    }
  };

  const submitMassJoinCoach = async () => {
    //TODO: Need to add code to trigger re-calculate for team

    const userEmailsToAdd = inputs3.emailsOfUsersToAdd.split(", ");

    const memberSnapshotsToJoin = await firebase
      .users()
      .where("email", "in", userEmailsToAdd)
      .get();

    memberSnapshotsToJoin.forEach(async (userDoc) => {
      if (
        userDoc.data().assessmentResult &&
        userDoc.data().assessmentResultSocial
      ) {
        console.log(
          `The user ${
            userDoc.data().username
          } has both assessments, recording them now`
        );
        //Add assessment results for both to the coach's doc
        await firebase
          .user(params.id)
          .collection("coachingCohort")
          .doc(userDoc.id)
          .set({
            member: userDoc.data().username,
            typologyResult: userDoc.data().assessmentResult,
            typologyResultNums: userDoc.data().assessmentResultNums,
            socialStylesResult: userDoc.data().assessmentResultSocial,
          });
      } else if (userDoc.data().assessmentResult) {
        console.log(
          `The user ${
            userDoc.data().username
          } has only Typology, recording it now`
        );
        //Add assessment result for Typology to the coach's doc, add SocialStyles link to the coach to access doc for user
        await firebase
          .user(params.id)
          .collection("coachingCohort")
          .doc(userDoc.id)
          .set({
            member: userDoc.data().username,
            typologyResultNums: userDoc.data().assessmentResultNums,
            typologyResult: userDoc.data().assessmentResult,
          });
      } else if (userDoc.data().assessmentResultSocial) {
        console.log(
          `The user ${
            userDoc.data().username
          } has only SocialStyles, recording it now`
        );
        //Add assessment result for Typology to the coach's doc, add SocialStyles link to the coach to access doc for user
        await firebase
          .user(params.id)
          .collection("coachingCohort")
          .doc(userDoc.id)
          .set({
            member: userDoc.data().username,
            socialStylesResult: userDoc.data().assessmentResultSocial,
          });
      } else {
        console.log(
          `The user ${
            userDoc.data().username
          } has no assessment results, simply adding them to the coach`
        );
        await firebase
          .user(params.id)
          .collection("coachingCohort")
          .doc(userDoc.id)
          .set({
            member: userDoc.data().username,
          });
      }
      await firebase
        .user(userDoc.id)
        .collection(`access`)
        .doc(`hasAccessTypology`)
        .set({ coach: params.id });
      await firebase
        .user(userDoc.id)
        .collection(`access`)
        .doc(`hasAccessSocial`)
        .set({ coach: params.id });
      userDoc.data().teams.forEach(async (teamID) => {
        await firebase
          .team(teamID)
          .collection(`members`)
          .doc(userDoc.id)
          .update({ coachedBy: params.id });
        const teamObj = await firebase.team(teamID).get();
        if (teamObj.data().typologyResults && teamObj.data().socialResults) {
          await firebase
            .user(params.id)
            .collection(`coachingCohort`)
            .doc(userDoc.id)
            .collection(`teams`)
            .doc(teamID)
            .set({
              teamName: teamObj.data().teamName,
              typologyResults: teamObj.data().typologyResults,
              socialResults: teamObj.data().socialResults,
            });
        } else if (teamObj.data().typologyResults) {
          await firebase
            .user(params.id)
            .collection(`coachingCohort`)
            .doc(userDoc.id)
            .collection(`teams`)
            .doc(teamID)
            .set({
              teamName: teamObj.data().teamName,
              typologyResults: teamObj.data().typologyResults,
              socialResults: false,
            });
        } else if (teamObj.data().socialResults) {
          await firebase
            .user(params.id)
            .collection(`coachingCohort`)
            .doc(userDoc.id)
            .collection(`teams`)
            .set(teamID)
            .update({
              teamName: teamObj.data().teamName,
              typologyResults: false,
              socialResults: teamObj.data().socialResults,
            });
        } else {
          await firebase
            .user(params.id)
            .collection(`coachingCohort`)
            .doc(userDoc.id)
            .collection(`teams`)
            .doc(teamID)
            .set({
              teamName: teamObj.data().teamName,
              typologyResults: false,
              socialResults: false,
            });
        }
      });
    });
    await firebase.user(params.id).update({ coach: true });
  };

  const { inputs3, handleSubmit3, handleInputChange3 } =
    useForm3(submitMassJoinCoach);

  const onSendPasswordResetEmail = () => {
    firebase.doPasswordReset(user.email);
  };

  const displayCharge = (indexPosition) => {
    console.log(`Display postion: ${indexPosition}`);
    setChargeDetailsToDisplay(indexPosition);
  };

  return (
    <PageWrapper>
      <h2>Individual User</h2>
      {loading && <div>Loading ...</div>}
      <Link
        to={{
          pathname: `${ROUTES.ADMIN}`,
        }}
        onClick={() => setChargeDetailsToDisplay(null)}>
        <BackUserButton>Back to Users</BackUserButton>
      </Link>
      {user && (
        <UserIndivWrapper>
          <UserInfo>
            <IndividualItemWrapper>
              <strong>Email:</strong> {user.email}
            </IndividualItemWrapper>
            <IndividualItemWrapper>
              <strong>Username:</strong> {user.username}
            </IndividualItemWrapper>
            <IndividualItemWrapper>
              <strong>ID:</strong> {params.id}
            </IndividualItemWrapper>
          </UserInfo>
          <UserInfo>
            <ActionUserButton
              type="button"
              variant="danger"
              onClick={onSendPasswordResetEmail}>
              Send Password Reset
            </ActionUserButton>
            <ActionUserButton onClick={createCode} variant="danger">
              Generate User Code
            </ActionUserButton>
          </UserInfo>
          <UserInfo>
            <AssessmentResultWrapper>
              {accessTypology && <h5>Typology: </h5>}
              {accessTypology &&
                user.assessmentResult &&
                user.assessmentResult.map((result) => (
                  <ResultItem>{result}, </ResultItem>
                ))}
            </AssessmentResultWrapper>
            <AssessmentResultWrapper>
              {accessSocial && <h5>Social: </h5>}
              {accessSocial &&
                user.assessmentResultSocial &&
                Object.entries(user.assessmentResultSocial).forEach(
                  (resultPair) =>
                    resultPair.forEach((result) => (
                      <ResultItem>{result}, </ResultItem>
                    ))
                )}
            </AssessmentResultWrapper>
          </UserInfo>
          <UserInfo>
            {accessTypology ? (
              <IndividualItemWrapper>
                Has Access to Typology Assessment
              </IndividualItemWrapper>
            ) : (
              <IndividualItemWrapper>
                Does <strong>not</strong> have access to Typology
              </IndividualItemWrapper>
            )}
            {accessSocial ? (
              <IndividualItemWrapper>
                Has Access to Social Assessment
              </IndividualItemWrapper>
            ) : (
              <IndividualItemWrapper>
                Does <strong>not</strong> have access to Social Assessment
              </IndividualItemWrapper>
            )}
          </UserInfo>
          <hr />
          <CoachesList>
            <h3>The user has the following members they are coaching</h3>
            {coachingCohort && coachingCohort.length > 0 ? (
              <MembersCoached>
                {coachingCohort.map((member) => (
                  <p>{member}</p>
                ))}
              </MembersCoached>
            ) : (
              <p>The user does not have any members they are coaching</p>
            )}
          </CoachesList>
          <UserCharges>
            <ChargesList>
              {charges.length > 0 &&
                charges.map((charge, index) => (
                  <ChargeListItem>
                    <UserPurchaseButton onClick={() => displayCharge(index)}>
                      ${charge.amount / 100} on{" "}
                      {charge.payment &&
                        charge.payment.created_at.split("T")[0]}{" "}
                      at{" hour "}
                      {charge.payment &&
                        charge.payment.created_at.split("T")[1].split(":")[0]}
                    </UserPurchaseButton>
                  </ChargeListItem>
                ))}
            </ChargesList>
            {chargeDetailsToDisplay !== null && (
              <ChargeDetails>
                <p>Amount: $ {charges[chargeDetailsToDisplay].amount / 100}</p>
                <p>Used for: {charges[chargeDetailsToDisplay].purpose}</p>
                <p>
                  Payment Status:{" "}
                  {charges[chargeDetailsToDisplay].payment &&
                    charges[chargeDetailsToDisplay].payment.status}
                </p>
                <p>
                  Card's Last 4:
                  {charges[chargeDetailsToDisplay].payment &&
                    charges[chargeDetailsToDisplay].payment.card_details.card
                      .last_4}
                </p>
                <p>
                  Payment Source:{" "}
                  {charges[chargeDetailsToDisplay].payment &&
                    charges[chargeDetailsToDisplay].payment.source_type}
                </p>
              </ChargeDetails>
            )}
          </UserCharges>
          <FormToolWrapper onSubmit={handleSubmit3}>
            <AddUsersInput
              name="emailsOfUsersToAdd"
              type="text"
              placeholder="list emails of users, separated by a comma and a space"
              value={inputs3.emailsOfUsersToAdd}
              onChange={handleInputChange3}
            />
            <button onSubmit={handleSubmit3}>Add Members to Coach</button>
          </FormToolWrapper>
        </UserIndivWrapper>
      )}
    </PageWrapper>
  );
};

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminPage);
