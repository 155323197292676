import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  pulse,
  fadeInLeftBig,
  fadeOutLeftBig,
  slideOutRight,
} from "react-animations";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import StyledButton from "../../../../assests/buttons";

const pulseAnimation = keyframes`${pulse}`;
const fadeInLeftAnimation = keyframes`${fadeInLeftBig}`;
const fadeOutLeftAnimation = keyframes`${fadeOutLeftBig}`;
const slideOutRightAnimation = keyframes`${slideOutRight}`;

const Answers = styled.ul`
  list-style: none;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const StrengthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
`;

const InstructionsWrapper = styled.p`
  font-family: "MontserratReg";
  font-size: 18px;
  text-align: center;
`;

const RankLabelText = styled.p`
  font-family: "MontserratSemiBold";
  display: flex;
  text-align: center;
  margin: 1vw;
`;

const RankChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const AnswerOption = styled.li`
  font-size: 18px;

  &:hover {
    animation: 0.5s ${pulseAnimation};
    font-weight: 900;
  }
`;

const RadioInputAnswerChoice = styled.input`
  visibility: hidden;
`;

const AnswerChoiceContainer = styled.label`
  display: flex;
  flex-direction: row;
  margin-right: 3vw;
  padding-left: 2vw;
  padding-right: 2vw;
  border-radius: 4px;
  width: 30vw;
  @media only screen and (max-width: 1200px) {
    width: 37vw;
    margin-right: 1vw;
  }

  @media only screen and (max-width: 799px) {
    width: 75vw;
  }

  padding-top: 1vw;
  cursor: pointer;

  &:before {
    width: 28px;
    height: 28px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: white;
    border: 1px solid #bbb;
    border-radius: 50%;
  }

  ${({ checked }) =>
    checked &&
    css`
      &:before {
        background: black;
      }
    `}

  ${(props) =>
    props.checked
      ? "background-image: linear-gradient(to right, #EE3189, #F36D21);"
      : ""}
  ${(props) => (props.checked ? "color: white;" : "")}
  ${(props) => (props.checked ? "font-weight: 900;" : "")}
  animation: 0.5s ${(props) =>
    props.selected ? slideOutRightAnimation : fadeOutLeftAnimation};
`;

const AnswerChoiceText = styled.p`
  font-family: "MontserratMed";
  font-size: 20px;
`;

const AssessmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 3vw;
  width: 70vw;
  @media (max-width: 1200px) {
    width: 80vw;
  }
  @media (max-width: 500px) {
    width: 100vw;
  }
`;

const AssessmentBorder = styled.div`
  padding: 3px;
  //Code to make a border gradient
  margin: 2vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;

  animation: 0.5s
    ${(props) =>
      props.animationState === "comingIn"
        ? fadeInLeftAnimation
        : fadeOutLeftAnimation};
`;

const QuestionText = styled.h3`
  font-family: "MontserratMed";
  margin-left: 3vw;
  margin-bottom: 2rem;
`;

const QuestionNumberText = styled.p`
  font-family: "MontserratSemiBold";
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 600;
`;

const RankLine = styled.div`
  height: 2px;
  color: grey;
`;

const AssessmentBase = (props) => {
  const [timeouts, setTimeouts] = useState(undefined);
  const [animationTimeouts, setAnimationTimeouts] = useState(undefined);
  const [currentCheckedAnswer, setCurrentCheckedAnswer] = useState(undefined);
  const [currentRank, setCurrentRank] = useState(undefined);
  const [animationState, setAnimationState] = useState("comingIn");
  const [animateAsSelected, setAnimateAsSelected] = useState(undefined);

  let storeAnswerAndIncrement = props.storeAnswerAndIncrement;
  let questionData = props.questionData;

  //onChange fires this event every time, newer clicks override by clearing the timeouts
  useEffect(() => {
    console.log(`Use Effect Triggered`);

    const onBothAnswersSelected = () => {
      //clear the timeouts from previous instances of onAnswerSelected, if it has already been clicked but not yet submitted via timeout
      if (timeouts) {
        console.log(`Clearning a previous timeout function`);
        clearTimeout(timeouts);
        clearTimeout(animationTimeouts);
      }

      //Create and store a timeout, this will store the question and increment it to the next question using the parent component
      const timeoutNewQuestion = setTimeout(() => {
        //Set states for animations
        setAnimationState("goingOut");
        setAnimateAsSelected(currentCheckedAnswer);
        //StoreValue & increment currentQuestionNumber
        storeAnswerAndIncrement({
          questionID: questionData.questionID,
          memberSurety: currentRank,
          type: currentCheckedAnswer,
        });
        setCurrentCheckedAnswer(undefined);
        setCurrentRank(undefined);
        setTimeouts(undefined);
      }, 1000);

      //Set timeout for the animation, this is 0.5s longer than the other to give the 0.5s animation time to finish
      const timeoutAnimation = setTimeout(() => {
        setAnimationState("comingIn");
        setAnimationTimeouts(undefined);
      }, 1500);

      setTimeouts(timeoutNewQuestion);
      setAnimationTimeouts(timeoutAnimation);
    };

    if (
      currentCheckedAnswer !== undefined &&
      typeof currentCheckedAnswer === "string" &&
      currentRank !== undefined &&
      typeof currentRank === "number"
    ) {
      console.log(`Both have been selected`);
      onBothAnswersSelected();
    }
  }, [
    currentRank,
    setCurrentCheckedAnswer,
    currentCheckedAnswer,
    questionData.questionID,
  ]);

  const onAnswerSelected = (event) => {
    let value = event.currentTarget.value;
    let maybeInteger = parseInt(value);
    let isInteger = Number.isInteger(maybeInteger);
    if (isInteger) {
      console.log(`Integer Selected`);
      setCurrentRank(maybeInteger);
    } else if (
      !isInteger &&
      (value === "Extrovert" ||
        value === "Introvert" ||
        value === "Sensor" ||
        value === "Intuitor" ||
        value === "Thinker" ||
        value === "Feeler" ||
        value === "Judger" ||
        value === "Perceiver")
    ) {
      console.log(`Type selected`);
      setCurrentCheckedAnswer(value);
    } else {
      console.error(`Input error, it was not an accepted choice: ${value}`);
    }
  };

  const onBackAQuestion = () => {
    if (props.currentQuestionNumber !== 0)
      props.setCurrentQuestionNumber(props.currentQuestionNumber - 1);
  };

  let nameRef = {};
  let valueForStrength = [];
  let maxValueForWeight = 5;
  for (let i = 1; i < maxValueForWeight + 1; i++) {
    valueForStrength.push(i);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    nameRef[i] = useRef(null);
  }

  return (
    <AssessmentBorder
      key={props.currentQuestionNumber}
      animationState={animationState}>
      <AssessmentWrapper>
        <QuestionNumberText>
          {/* +1 here is to account for zero array postion being first */}
          Question {props.currentQuestionNumber + 1}/{props.totalQuestions}
        </QuestionNumberText>
        {/* Using the key here to force react to recognize a new question and then trigger an animation */}
        <QuestionText>{props.questionData.questionText}</QuestionText>
        <OptionsWrapper>
          <Answers>
            {props.questionData.answerChoices.map((ans) => (
              <AnswerOption key={ans.type}>
                <AnswerChoiceContainer
                  key={ans.type}
                  checked={ans.type === currentCheckedAnswer}
                  selected={ans.type === animateAsSelected}>
                  <RadioInputAnswerChoice
                    type="radio"
                    name="RadioCustomAnswers"
                    value={ans.type}
                    key={ans.type}
                    onChange={onAnswerSelected}
                  />
                  <AnswerChoiceText>{ans.content}</AnswerChoiceText>
                </AnswerChoiceContainer>
              </AnswerOption>
            ))}
          </Answers>
          <StrengthWrapper>
            <InstructionsWrapper>
              Rank how well the answer you selected describes you.
            </InstructionsWrapper>
            <RankLine />
            <RankChoiceWrapper>
              <RankLabelText>Does not describe me</RankLabelText>
              <ButtonGroup color="primary" aria-label="StrengthChooser">
                {valueForStrength.map((value) => (
                  <Button
                    key={value}
                    ref={nameRef[value]}
                    onClick={(value) => onAnswerSelected(value)}
                    value={value}>
                    {value}
                  </Button>
                ))}
              </ButtonGroup>
              <RankLabelText>Describes me perfectly</RankLabelText>
            </RankChoiceWrapper>
          </StrengthWrapper>
        </OptionsWrapper>

        <StyledButton
          tertiary
          width="300px;"
          fontSize="1.5rem"
          callback={onBackAQuestion}
          disabled={props.currentQuestionNumber === 0}>
          Back One Question
        </StyledButton>
      </AssessmentWrapper>
    </AssessmentBorder>
  );
};

export default AssessmentBase;
