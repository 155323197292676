import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "../CustomHooks";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import styled from "@emotion/styled";
import StyledButton from "../../assests/buttons";

const PasswordLinkWrapper = styled.p`
  padding-bottom: 10px;
`;

const InputBox = styled.div`
  position: relative;
  padding-bottom: 15px;
  padding-top: 7px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #85469b" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 5px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const PasswordForgetPage = () => (
  <div>
    <h1>Forgot Your Password?</h1>
    <PasswordForgetForm />
  </div>
);

const PasswordForgetFormBase = ({ firebase }) => {
  const [error, setError] = useState(null);

  const [hasFocus, setHasFocus] = useState(false);

  const onSubmit = () => {
    firebase
      .doPasswordReset(inputs.email)
      .then(() => {
        alert("An email has been sent ");
      })
      .catch((error) => {
        setError(error);
      });
  };

  const { inputs, handleSubmit, handleInputChange } = useForm(onSubmit);

  const setFocus = (hasFocus) => {
    if (!inputs.email) {
      setHasFocus(hasFocus);
    }
  };

  const isInvalid = inputs.email === "";

  return (
    <form onSubmit={handleSubmit}>
      <InputBox>
        <Input
          name="email"
          value={inputs.email}
          onChange={handleInputChange}
          type="text"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        <InputLabel hasFocus={hasFocus}>Email Address</InputLabel>
      </InputBox>

      <StyledButton
        primary
        width="300px"
        fontSize="1.5rem"
        disabled={isInvalid}
        type="submit">
        Reset My Password
      </StyledButton>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const PasswordForgetLink = () => (
  <PasswordLinkWrapper>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </PasswordLinkWrapper>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
