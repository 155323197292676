import React, { useContext, useState } from "react";
import { useForm } from "../CustomHooks.js";
import { AuthUserContext } from "../Session";
import styled from "@emotion/styled";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const CreateTeamWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
background-color: white;
align-self: center;
flex: none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ]
border: 7px solid black;
margin: 20px;
padding: 15px;
`;

const CreateText = styled.p`
  font-family: roboto-light, sans-sarif;
  font-size: 18px;
`;

const BlackButton = styled.button`
  display: flex;
  width: 170px;
  margin-right: 20px;
  height: 48px;
  justify-content: center;
  font-size: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const CancelButton = styled.button`
  display: flex;
  width: 90px;
  margin-left: 10px;
  height: 48px;
  justify-content: center;
  font-size: 20px;
  background-color: #696969;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;

  &:hover {
    background-color: red;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const InputBox = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #7abf5f" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const FormWrapper = styled.form`
  width: 250px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space around;
`;

const SubmitButton = styled(Button)`
  background-color: #7abf5f;
`;

const TeamUsed = styled.p`
  font-weight: 550;
  font-family: roboto, sans-sarif;
  font-size: 20px;
`;

const CreateTeamComponent = (props) => {
  const authUser = useContext(AuthUserContext);

  //The callback function from the custom hook is defined here, then passed into the hook as an arg
  const createTeam = () => {
    onCreateTeam(authUser);
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(createTeam);

  const [hasFocusCo, setHasFocusCo] = useState(false);
  const [hasFocusDept, setHasFocusDept] = useState(false);
  const [hasFocusTeamName, setHasFocusTeamName] = useState(false);
  const [hasFocusCode, setHasFocusCode] = useState(false);
  const [teamUsed, setTeamUsed] = useState(false);

  const setFocusCo = (hasFocusCo) => {
    if (!inputs.companyName) {
      setHasFocusCo(hasFocusCo);
    }
  };

  const setFocusDept = (hasFocusDept) => {
    if (!inputs.department) {
      setHasFocusDept(hasFocusDept);
    }
  };

  const setFocusTeamName = (hasFocusTeamName) => {
    if (!inputs.teamName) {
      setHasFocusTeamName(hasFocusTeamName);
    }
  };

  const setFocusCode = (hasFocusCode) => {
    if (!inputs.teamCode) {
      setHasFocusCode(hasFocusCode);
    }
  };

  const onCreateTeam = async (authUser) => {
    try {
      //Check if there is already a team with that code
      var teamsWithThatCode = await props.firebase
        .teams()
        .where("teamCode", "==", inputs.teamCode)
        .get();
      if (teamsWithThatCode.empty) {
        await props.firebase.teams().add({
          companyName: inputs.companyName,
          department: inputs.department,
          teamName: inputs.teamName,
          teamCode: inputs.teamCode,
          teamLead: authUser.username,
          leaderId: authUser.uid,
        });
        await props.firebase
          .users()
          .doc(authUser.uid)
          .update({ director: true });
        props.onCloseModal();
        props.onOpenManageTeam();
        console.log(`Team Created!
        Company Name: ${inputs.companyName} 
        Department: ${inputs.department}
        Team Name: ${inputs.teamName}
        Team Code: ${inputs.teamCode}
        Team Lead: ${authUser.username}`);
        setTeamUsed(false);
      } else {
        setTeamUsed(true);
      }
    } catch (err) {
      console.log("Error saving docs to Db", err);
    }
  };

  const renderTooltipJoinCode = () => {
    return (
      <Tooltip id="createJoinCodeTip" {...props}>
        A password you make up, and give other members to join your team. Short
        and simple. example: COSTEAM
      </Tooltip>
    );
  };

  return (
    <CreateTeamWrapper>
      <CreateText>
        To create a team, fill out the information below. Then, share the
        password with members to start collecting data from the assessments! You
        can create multiple teams.
      </CreateText>
      <FormWrapper onSubmit={handleSubmit}>
        <InputBox>
          <Input
            name="companyName"
            value={inputs.companyName}
            onChange={handleInputChange}
            type="text"
            onFocus={() => setFocusCo(true)}
            onBlur={() => setFocusCo(false)}
            required
          />
          <InputLabel hasFocus={hasFocusCo}>Company</InputLabel>
        </InputBox>
        <InputBox>
          <Input
            name="department"
            value={inputs.department}
            onChange={handleInputChange}
            type="text"
            onFocus={() => setFocusDept(true)}
            onBlur={() => setFocusDept(false)}
            required
          />
          <InputLabel hasFocus={hasFocusDept}>Department</InputLabel>
        </InputBox>
        <InputBox>
          <Input
            name="teamName"
            value={inputs.teamName}
            onChange={handleInputChange}
            type="text"
            onFocus={() => setFocusTeamName(true)}
            onBlur={() => setFocusTeamName(false)}
            required
          />
          <InputLabel hasFocus={hasFocusTeamName}>Team Name</InputLabel>
        </InputBox>
        <OverlayTrigger
          placement="top-end"
          delay={{ show: 200, hide: 400 }}
          overlay={renderTooltipJoinCode()}
        >
          <InputBox>
            <Input
              name="teamCode"
              value={inputs.teamCode}
              onChange={handleInputChange}
              type="text"
              onFocus={() => setFocusCode(true)}
              onBlur={() => setFocusCode(false)}
              required
            />
            <InputLabel hasFocus={hasFocusCode}>
              Create Team Password
            </InputLabel>
          </InputBox>
        </OverlayTrigger>
        {teamUsed && (
          <TeamUsed>
            That team password has been used, please try another!
          </TeamUsed>
        )}
        <ButtonsWrapper>
          <BlackButton type="submit">Create Team</BlackButton>
          <CancelButton variant="secondary" onClick={props.onCloseModal}>
            Cancel
          </CancelButton>
        </ButtonsWrapper>
      </FormWrapper>
    </CreateTeamWrapper>
  );
};

export default CreateTeamComponent;
