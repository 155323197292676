import React from "react";
import PieChart from "react-minimal-pie-chart";
import styled from "styled-components";

const TwoPieChartsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-start;
  flex-wrap: wrap;
`;

const OnePieChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-start;
  margin: 10px;
  padding: 5px;
  min-width: 300px;
`;

const LegendBorder = styled.div`
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  margin: 6px;
`;

const AllCharts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const ResultsSectionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  background-color: white;
`;

const ResultsBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin-top: 60px;
  //Code to make a border gradient
  margin-left: 10vw;
  margin-right: 10vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
  @media only screen and (max-width: 1150px) {
    margin-left: 25vw;
    margin-right: 25vw;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }
`;

const GraphColorOne = "#EE3189";
const GraphColorTwo = "#F36d21";
const GraphColorThree = "#70c8b8";
const GraphColorFour = "#469dd7";

//color option #7abf5f
// border: 2px solid black;
const TeamGraphsComponent = (props) => {
  var coreTempChartData = [
    {
      color: GraphColorOne,
      title: "Negotiator (SP)",
      value: props.resultsMap.SP,
    },
    {
      color: GraphColorTwo,
      title: "Stabilizer (SJ)",
      value: props.resultsMap.SJ,
    },
    {
      color: GraphColorThree,
      title: "Visionary (NT)",
      value: props.resultsMap.NT,
    },
    {
      color: GraphColorFour,
      title: "Catalyst (NF)",
      value: props.resultsMap.NF,
    },
  ];

  var EIChartData = [
    {
      color: GraphColorOne,
      title: "Extrovert",
      value: props.resultsMap.E,
    },
    {
      color: GraphColorTwo,
      title: "Introvert",
      value: props.resultsMap.I,
    },
  ];

  var NSChartData = [
    {
      color: GraphColorThree,
      title: "Sensor",
      value: props.resultsMap.S,
    },
    {
      color: GraphColorFour,
      title: "Intuitor",
      value: props.resultsMap.N,
    },
  ];

  var TFChartData = [
    {
      color: GraphColorOne,
      title: "Thinker",
      value: props.resultsMap.T,
    },
    {
      color: GraphColorTwo,
      title: "Feeler",
      value: props.resultsMap.F,
    },
  ];

  var JPChartData = [
    {
      color: GraphColorThree,
      title: "Judger",
      value: props.resultsMap.J,
    },
    {
      color: GraphColorFour,
      title: "Perceiver",
      value: props.resultsMap.P,
    },
  ];

  var domSocialChartData = [
    {
      color: GraphColorOne,
      title: "Significance",
      value: props.resultsMapSocial.significanceDom,
    },
    {
      color: GraphColorTwo,
      title: "Altruistic",
      value: props.resultsMapSocial.altruisticDom,
    },
    {
      color: GraphColorThree,
      title: "Sensibility",
      value: props.resultsMapSocial.sensibilityDom,
    },
    {
      color: GraphColorFour,
      title: "Entertainer",
      value: props.resultsMapSocial.entertainerDom,
    },
  ];

  var secSocialChartData = [
    {
      color: GraphColorOne,
      title: "Significance",
      value: props.resultsMapSocial.significanceSec,
    },
    {
      color: GraphColorTwo,
      title: "Altruistic",
      value: props.resultsMapSocial.altruisticSec,
    },
    {
      color: GraphColorThree,
      title: "Sensibility",
      value: props.resultsMapSocial.sensibilitySec,
    },
    {
      color: GraphColorFour,
      title: "Entertainer",
      value: props.resultsMapSocial.entertainerSec,
    },
  ];

  var filteredCoreTempChartData = coreTempChartData.filter(
    (element) => element.value > 0
  );

  var filteredEIChartData = EIChartData.filter((element) => element.value > 0);

  var filteredNSChartData = NSChartData.filter((element) => element.value > 0);

  var filteredTFChartData = TFChartData.filter((element) => element.value > 0);

  var filteredJPChartData = JPChartData.filter((element) => element.value > 0);

  var filteredDomSocialChartData = domSocialChartData.filter(
    (element) => element.value > 0
  );

  var filteredSecSocialChartData = secSocialChartData.filter(
    (element) => element.value > 0
  );

  return (
    <div>
      {props.resultsMap && (
        <ResultsSectionBox>
          <h1>{props.teamName} Typology Results</h1>
          <AllCharts>
            <OnePieChartWrapper>
              {/*   SensorPerceiver: "Negotiator",
                IntuitorFeeler: "Catalyst",
                IntuitorThinker: "Competence",
                SensorJudger: "Stabilizer" */}

              <PieChart
                animate
                animationDuration={700}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={filteredCoreTempChartData}
                label={true}
                labelStyle={{
                  fill: "white",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                labelPosition={50}
                lengthAngle={360}
                lineWidth={100}
                onClick={undefined}
                onMouseOut={undefined}
                onMouseOver={undefined}
                paddingAngle={0}
                radius={40}
                rounded={false}
                startAngle={0}
                viewBoxSize={[100, 100]}
              />
              <LegendBorder>
                <LegendWrapper>
                  <h4>
                    <font color={GraphColorOne}> Negotiator-SP </font>
                  </h4>
                  <h4>
                    <font color={GraphColorTwo}> Stabilizer-SJ </font>
                  </h4>
                  <h4>
                    <font color={GraphColorThree}> Visionary-NT </font>
                  </h4>
                  <h4>
                    <font color={GraphColorFour}> Catalyst-NF </font>
                  </h4>
                </LegendWrapper>
              </LegendBorder>
            </OnePieChartWrapper>
            <TwoPieChartsWrapper>
              <OnePieChartWrapper>
                <PieChart
                  animate
                  animationDuration={700}
                  animationEasing="ease-out"
                  cx={50}
                  cy={50}
                  data={filteredEIChartData}
                  label={true}
                  labelStyle={{
                    fill: "white",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                  labelPosition={50}
                  lengthAngle={360}
                  lineWidth={100}
                  onClick={undefined}
                  onMouseOut={undefined}
                  onMouseOver={undefined}
                  paddingAngle={0}
                  radius={40}
                  rounded={false}
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                />
                <LegendBorder>
                  <LegendWrapper>
                    <h4>
                      <font color={GraphColorOne}> Extrovert </font>
                    </h4>
                    <h4>
                      <font color={GraphColorTwo}> Introvert </font>
                    </h4>
                  </LegendWrapper>
                </LegendBorder>
              </OnePieChartWrapper>
              <OnePieChartWrapper>
                <PieChart
                  animate
                  animationDuration={700}
                  animationEasing="ease-out"
                  cx={50}
                  cy={50}
                  data={filteredNSChartData}
                  label={true}
                  labelStyle={{
                    fill: "white",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                  labelPosition={50}
                  lengthAngle={360}
                  lineWidth={100}
                  onClick={undefined}
                  onMouseOut={undefined}
                  onMouseOver={undefined}
                  paddingAngle={0}
                  radius={40}
                  rounded={false}
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                />
                <LegendBorder>
                  <LegendWrapper>
                    <h4>
                      <font color={GraphColorThree}> Sensor </font>
                    </h4>
                    <h4>
                      <font color={GraphColorFour}> Intuitor </font>
                    </h4>
                  </LegendWrapper>
                </LegendBorder>
              </OnePieChartWrapper>
            </TwoPieChartsWrapper>

            <TwoPieChartsWrapper>
              <OnePieChartWrapper>
                <PieChart
                  animate
                  animationDuration={700}
                  animationEasing="ease-out"
                  cx={50}
                  cy={50}
                  data={filteredTFChartData}
                  label={true}
                  labelStyle={{
                    fill: "white",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                  labelPosition={50}
                  lengthAngle={360}
                  lineWidth={100}
                  onClick={undefined}
                  onMouseOut={undefined}
                  onMouseOver={undefined}
                  paddingAngle={0}
                  radius={40}
                  rounded={false}
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                />
                <LegendBorder>
                  <LegendWrapper>
                    <h4>
                      <font color={GraphColorOne}> Thinker </font>
                    </h4>
                    <h4>
                      <font color={GraphColorTwo}> Feeler </font>
                    </h4>
                  </LegendWrapper>
                </LegendBorder>
              </OnePieChartWrapper>
              <OnePieChartWrapper>
                <PieChart
                  animate
                  animationDuration={700}
                  animationEasing="ease-out"
                  cx={50}
                  cy={50}
                  data={filteredJPChartData}
                  label={true}
                  labelStyle={{
                    fill: "white",
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                  }}
                  labelPosition={50}
                  lengthAngle={360}
                  lineWidth={100}
                  onClick={undefined}
                  onMouseOut={undefined}
                  onMouseOver={undefined}
                  paddingAngle={0}
                  radius={40}
                  rounded={false}
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                />
                <LegendBorder>
                  <LegendWrapper>
                    <h4>
                      <font color={GraphColorThree}>Judger</font>
                    </h4>
                    <h4>
                      <font color={GraphColorFour}>Perceiver</font>
                    </h4>
                  </LegendWrapper>
                </LegendBorder>
              </OnePieChartWrapper>
            </TwoPieChartsWrapper>
          </AllCharts>
        </ResultsSectionBox>
      )}
      {!props.resultsMapSocial &&
        (props.source === "Coach" ? (
          <h5>
            Less than 2 members of the member's team have taken the Social
            Styles assessment, so we cannot calculate team data yet.
          </h5>
        ) : (
          <h3>
            Less than 2 members of your team have taken the Social Styles
            assessment, so there are not enough results to tabulate Social
            Styles team data.
          </h3>
        ))}
      {props.resultsMapSocial && (
        <ResultsSectionBox>
          <h1>{props.teamName} Social Styles Results</h1>
          <AllCharts>
            <OnePieChartWrapper>
              <h3>Dominant Styles</h3>

              <PieChart
                animate
                animationDuration={700}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={filteredDomSocialChartData}
                label={true}
                labelStyle={{
                  fill: "white",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                labelPosition={50}
                lengthAngle={360}
                lineWidth={100}
                onClick={undefined}
                onMouseOut={undefined}
                onMouseOver={undefined}
                paddingAngle={0}
                radius={40}
                rounded={false}
                startAngle={0}
                viewBoxSize={[100, 100]}
              />
              <LegendWrapper>
                {props.resultsMapSocial.significanceDom > 0 && (
                  <h4>
                    <font color={GraphColorOne}> Significance </font>
                  </h4>
                )}
                {props.resultsMapSocial.altruisticDom > 0 && (
                  <h4>
                    <font color={GraphColorTwo}> Altruistic </font>
                  </h4>
                )}
                {props.resultsMapSocial.sensibilityDom > 0 && (
                  <h4>
                    <font color={GraphColorThree}> Sensibility </font>
                  </h4>
                )}
                {props.resultsMapSocial.entertainerDom > 0 && (
                  <h4>
                    <font color={GraphColorFour}> Entertainer </font>
                  </h4>
                )}
              </LegendWrapper>
            </OnePieChartWrapper>
            <OnePieChartWrapper>
              <h3>Dominant Styles</h3>
              <PieChart
                animate
                animationDuration={700}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={filteredSecSocialChartData}
                label={true}
                labelStyle={{
                  fill: "white",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
                labelPosition={50}
                lengthAngle={360}
                lineWidth={100}
                onClick={undefined}
                onMouseOut={undefined}
                onMouseOver={undefined}
                paddingAngle={0}
                radius={40}
                rounded={false}
                startAngle={0}
                viewBoxSize={[100, 100]}
              />
              <LegendWrapper>
                {props.resultsMapSocial.significanceSec > 0 && (
                  <h4>
                    <font color={GraphColorOne}> Significance </font>
                  </h4>
                )}
                {props.resultsMapSocial.altruisticSec > 0 && (
                  <h4>
                    <font color={GraphColorTwo}> Altruistic </font>
                  </h4>
                )}
                {props.resultsMapSocial.sensibilitySec > 0 && (
                  <h4>
                    <font color={GraphColorThree}> Sensibility </font>
                  </h4>
                )}
                {props.resultsMapSocial.entertainerSec > 0 && (
                  <h4>
                    <font color={GraphColorFour}> Entertainer </font>
                  </h4>
                )}
              </LegendWrapper>
            </OnePieChartWrapper>
          </AllCharts>
        </ResultsSectionBox>
      )}
      {!props.resultsMap &&
        (props.source === "Coach" ? (
          <h5>
            Less than 2 members of the member's team have taken the Typology
            assessment, so we cannot calculate team data yet.
          </h5>
        ) : (
          <h3>
            Less than 2 members of your team have taken the Typology assessment,
            so there are not enough results to tabulate Typology team data.
          </h3>
        ))}
    </div>
  );
};

export default TeamGraphsComponent;
