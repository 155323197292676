import React, { useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "../CustomHooks";

const JoinTeamWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
align-content: stretch;
background-color: white;
align-self: center;
flex: none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ]
border: 7px solid black;
margin: 20px;
padding: 15px;
`;

const BlackButton = styled.button`
  display: flex;
  width: 250px;
  height: 48px;
  justify-content: center;
  font-size: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const JoinText = styled.p`
  font-family: roboto-light, sans-sarif;
  font-size: 18px;
`;

const InputBox = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #7abf5f" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const JoinTeamComponenet = (props) => {
  const joinTeam = () => {
    onJoinTeam(props.authUser);
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(joinTeam);

  const [hasFocus, setHasFocus] = useState(false);

  const setFocus = (hasFocus) => {
    if (!inputs.joinTeamPassword) {
      setHasFocus(hasFocus);
    }
  };

  const onJoinTeam = async (authUser) => {
    props.setProcessing(true);
    var teamID = "";
    var nameAlreadyExists = false;
    console.log(
      `The team code used for the query is: ${inputs.joinTeamPassword}`
    );

    //Check if there is a team with that code
    const snapshot = await props.firebase
      .teams()
      .where("teamCode", "==", inputs.joinTeamPassword)
      .get();
    if (snapshot.empty) {
      alert(
        `I can't remember any team with the code ${inputs.joinTeamPassword}, try again`
      );
      props.setProcessing(false);
      return;
    }

    console.log("teams snapshot: ", snapshot);

    //Get the data from the team that matches this code
    snapshot.forEach((doc) => {
      console.log("Docs from teams snapshot ", doc.id, "=>", doc.data());
      teamID = doc.id;
    });

    //Get all the members of the team, we can see if this user is already on the team
    const membersSnapshot = await props.firebase
      .teams()
      .doc(teamID)
      .collection("members")
      .get();

    //Get the user's doc to check what assessments they have taken
    const userSnapshot = await props.firebase.users().doc(authUser.uid).get();
    const tookTypology =
      userSnapshot.data().userTookAssessmentTypology || false;

    if (!membersSnapshot.empty) {
      membersSnapshot.forEach((doc) => {
        if (doc.data().memberId === authUser.uid) {
          nameAlreadyExists = true;
          alert(
            "You are already on this team, dummy!  ...sorry I should be more polite"
          );
        }
        console.log("ITERATING Team memberID: ", doc.data().memberId);
      });
    }
    // Temporarily comment out for testing (adds to FB)
    if (!nameAlreadyExists) {
      props.firebase
        .teams()
        .doc(teamID)
        .collection("members")
        .doc(authUser.uid)
        .set({
          member: authUser.username,
          memberId: authUser.uid,
          tookTypology: tookTypology,
        })
        .then(() => {
          console.log("Member successfully added to team in Firebase!");
        });
      //Cloud Function then adds member to team in the member doc
    } else {
      console.log("The user was already a member");
    }
  };

  return (
    <JoinTeamWrapper>
      <JoinText>
        Please enter the password provided by your team director to join your
        team.
      </JoinText>
      <JoinText>
        If you would like to create your own team, please close this window and
        select that option on the navigation bar.
      </JoinText>
      <form onSubmit={handleSubmit}>
        <InputBox>
          <Input
            name="joinTeamPassword"
            value={inputs.joinTeamPassword}
            onChange={handleInputChange}
            type="text"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            required
          />
          <InputLabel hasFocus={hasFocus}>Team Password</InputLabel>
        </InputBox>
        <BlackButton onClick={props.onCloseJoin} type="submit">
          Join Team
        </BlackButton>
      </form>
    </JoinTeamWrapper>
  );
};

export default JoinTeamComponenet;
