const typeBlurb = {
  Extrovert: "are energized from the world of other people",
  Introvert: "are energized by thoughts and ideas",
  Sensor: "perceive things with literal data",
  Intuitor: "perceive things through meanings and relationships",
  Thinker: "make decisions firm minded and objectively (Left brain)",
  Feeler: "make decisions subjectively/tender-hearted (Right brain)",
  Judger: "relate to the world as resolved and definite",
  Perceiver: "relate to the world as pending and tentative",
};

const tempermentLeadershipStyleBlurb = {
  SensorPerceiver: "Negotiator",
  IntuitorFeeler: "Catalyst",
  IntuitorThinker: "Competence",
  SensorJudger: "Stabilizer",
};

const coreValueBlurb = {
  SensorPerceiver: "Freedom to act and make an impact",
  IntuitorFeeler: "Authenticity, Integrity and Harmony",
  IntuitorThinker: "Logic, knowledge, truth and theory",
  SensorJudger: "Nurturing, social stability and order",
};

const coreQuestBlurb = {
  SensorPerceiver: "Action",
  IntuitorFeeler: "Identity",
  IntuitorThinker: "Competence",
  SensorJudger: "Belonging/Loyalty",
};

const coreStrengthsBlurb = {
  SensorPerceiver:
    "Accurate Observation; Stimulated to action in crisis, Flexible and pragmatic;  Open minded, tolerant; Super-realistic; Inter present moment; Master of the grand gesture.",
  IntuitorFeeler:
    "Naturally empathetic; Bring harmony between people; can sense where people are emotionally and will adapt accordingly; highly moral; best giver of strokes; have charisma; attuned to possibilities of people and organizations.",
  IntuitorThinker:
    "Give striaght answers; can delay action and gratification for a future fascinating plan or goal; speak clearly; write precisely.",
  SensorJudger:
    "Attention to detail; stabilize systems; preserve values of society; Applied thinker; Impartial and patient; Common Sense; Excellent at rule setting and decision making; Extremely responsible and dependable; Organize/schedule time and meet deadlines.",
};

const coreWeaknessesBlurb = {
  SensorPerceiver:
    "Impatient in general, especially with abstractions; Can’t postpone pleasure very well, their way is the only way, clutter is acceptable; Not very open to outside influence; Self-discipline is extremely difficult; Miss romantic nuances.",
  IntuitorFeeler:
    "Over-involvement; Difficulty with structure, authority figures and rules (considers them impersonal); Too lenient; Don’t ask for what they need; Take criticisms too personally.",
  IntuitorThinker:
    "Do not give or receive compliments well; Lack awareness of feelings (feelings not important); Get lost in strategies, overlook day-to-day business; Fatigue others by working a given point to death; Tend to develop compulsive behaviors.",
  SensorJudger:
    "Impatient with complications; Worry over anticipated problems; Want to punish bad people (higher sense of justice than mercy); So into today’s tasks, they may neglect tomorrow’s.",
};

const coreStressorsBlurb = {
  SensorPerceiver:
    "Constraint or delay (having to sit still); Discipline, practice, homework; Lack of crisis (harmony is dull).",
  IntuitorFeeler:
    "Loss of integrity or identify; Rebellious and nonconformist people that ruin harmony.",
  IntuitorThinker:
    "Incompetence, not being able to do all things well; Not knowing all there is to know about everything.",
  SensorJudger:
    "Rejection, exclusion. Chaos; The unkempt; When a source of authority is challenged; When roles or expectations are not met; Anything that rocks the boat.",
};

const coreRecommendationsBlurb = {
  SensorPerceiver:
    "Fight philosophies and goals; Into the play ethic (play is play and work is play); One-track mind; In trouble with the law more; Gatherings have to be fun or SPs won’t come.",
  IntuitorFeeler:
    "Consider their values the only ones; Bring out the best in people; 90% of books are written by NFs; They love to get together and talk (seminars).",
  IntuitorThinker:
    "Think architecturally: want all life to fit into a grand system; Easily misunderstood and rejected because they learn by challenging any authority; Escalate the standard of everything; Prefer theoretical over practical; Love technology and philosophy; Need a staff to carry out their ideas.",
  SensorJudger:
    "Can be negative and critical, but are the foundation and backbone of society and its group; They love to buy and fill out workbooks; Careers are dependent upon long periods of learning; Sentimental and traditional; Yearn to belong to meaningful institutions; the bill payers of life.",
};

export default typeBlurb;
export {
  tempermentLeadershipStyleBlurb,
  coreValueBlurb,
  coreQuestBlurb,
  coreStrengthsBlurb,
  coreWeaknessesBlurb,
  coreStressorsBlurb,
  coreRecommendationsBlurb,
};
