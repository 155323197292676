import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { slideInRight, slideOutDown, pulse } from "react-animations";

const slideInAnimation = keyframes`${slideInRight}`;
const slideDownAnimation = keyframes`${slideOutDown}`;
const pulseAnimation = keyframes`${pulse}`;

const EntireSurveyComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-itmes: flex-start;
`;

const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: stretch;
  animation: 0.5s ${slideInAnimation};
  padding: 20px;
`;

const Question = styled.h3`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnswerWrapper = styled.label`
  display: flex;
  flex-direction: row;
  padding-top: 8px;

  ${(props) =>
    props.checked ? "border-top: solid 2px #8ABC6A" : "border-top: 0;"}
  ${(props) =>
    props.checked ? "border-bottom: solid 2px #8ABC6A" : "border-bottom: 0;"}
`;

const InputAnswerChoice = styled.input`
  visibility: hidden;
`;
const QuestionNameText = styled.h3`
  font-size: 20px;
`;

const BlurredSurveyQuestion = styled.button`
  display: flex;
  flex: auto;
  flex-direction: row;
  padding: 8px;
  background-color: light-grey;
  transition: flex-grow 500ms ease-in-out;
  border: 0;
  border-top: solid 1px #8abc6a;
  border-bottom: solid 1px #8abc6a;
  padding: 8px;
  background-color: white;

  &:hover {
    animation: 0.5s ${pulseAnimation};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const AnswerChoiceText = styled.p`
  display: flex;
  flex-direction: row;
  font-size: 18px;

  &:hover {
    font-weight: 900;
    animation: 0.5s ${pulseAnimation};
  }

  ${(props) => (props.checked ? "font-weight: 900;" : "font-weight: 400;")}
  ${(props) => (props.checked ? "font-color: #8ABC6A;" : "font-color: black;")}
`;

//The following animation in the component above has a strange effect of not "sticking" with continued hover
// &:before {
//   position: absolute;
//   content: "";
//   top: 30px;
//   transform: translate(-35px);
//   width: 90%;
//   height: 2px;
//   background-color: black;
//   visibility: hidden;
//   -webkit-transform: scaleX(0);
//   transform: scaleX(0);
//   -webkit-transition: all 0.3s ease-in-out 0s;
//   transition: all 0.3s ease-in-out 0s;
// }

// &:hover:before {
//   visibility: visible;
//   -webkit-transform: scaleX(1);
//   transform: scaleX(1);
// }

const DemographicSurveyBase = (props) => {
  const renderQuestionFocused = () => {
    return (
      <React.Fragment>
        <BlurredSurveyQuestion>
          <QuestionNameText>
            <strong>{props.questionName}</strong>
          </QuestionNameText>
        </BlurredSurveyQuestion>
        <SurveyWrapper>
          <Question>{props.questionText}</Question>
          {props.answers.map((ans) => (
            <AnswerWrapper
              key={ans.key.concat("Wrapper")}
              checked={ans.key === props.checkedAnswer}>
              <InputAnswerChoice
                type="radio"
                name={props.questionName}
                key={ans.key}
                value={ans.key}
                onChange={props.onAnswerSelected}
              />
              <AnswerChoiceText
                key={ans.key.concat("AnswerText")}
                checked={ans.key === props.checkedAnswer}>
                {ans.content}
              </AnswerChoiceText>
            </AnswerWrapper>
          ))}
        </SurveyWrapper>
      </React.Fragment>
    );
  };

  const renderQuestionBlurred = () => {
    return (
      <BlurredSurveyQuestion
        onClick={() => props.setQuestionFocus(props.questionName)}>
        <QuestionNameText>{props.questionName}</QuestionNameText>
        {props.answersObject && props.answersObject[props.questionName] && (
          <QuestionNameText>
            : {props.answersObject[props.questionName]}
          </QuestionNameText>
        )}
      </BlurredSurveyQuestion>
    );
  };

  return (
    <EntireSurveyComponentWrapper>
      {props.questionFocus === props.questionName
        ? renderQuestionFocused()
        : renderQuestionBlurred()}
    </EntireSurveyComponentWrapper>
  );
};

export default DemographicSurveyBase;
