import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import * as ROUTES from "../../constants/routes";
import StyledButton from "../../assests/buttons";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";

import { Modal } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { grey, orange, purple, blue, green } from "@material-ui/core/colors";
import CarouselSlide from "../../assests/reusableComponents";
import EasyToRead from "./easyToReadCard";
import MachineLearningAnalyzed from "./machineLearningCard";

import BottomComponent from "./bottomComponent";

import BRImage from "../../assests/images/fullBackgroundImg.jpg";
import CentralLogo from "./WhiteText_Home_Page_Logo.svg";
import PersonalityLogo from "../../assests/logos/Personality.svg";
import TeamLogo from "../../assests/logos/Team.svg";
import DataLogo from "../../assests/logos/Data.svg";
import GrowthLogo from "../../assests/logos/Growth.svg";

const MainImage = styled.img`
  position: absolute;
  border-radius: 1rem;
  width: 96vw;
  height: 600px;
  object-fit: cover;
  object-position: 0% 90%;

  @media only screen and (max-width: 1450px) {
    height: 550px;
  }
  @media only screen and (max-width: 1150px) {
    height: 450px;
  }
  @media only screen and (max-width: 799px) {
    height: 600px;
  }

  z-index: -2;
  margin: 2vw;
`;

const MainImageSpacerDiv = styled.div`
  opacity: 0;
  ${(props) =>
    props.animate &&
    css`
      backdrop-filter: blur(4px) grayscale(20%) contrast(70%);
      transition: opacity 2s ease-in;
      opacity: 1;
    `}

  border-radius: 1rem;

  height: 600px;
  @media only screen and (max-width: 1450px) {
    height: 550px;
  }
  @media only screen and (max-width: 1150px) {
    height: 450px;
  }
  @media only screen and (max-width: 799px) {
    height: 600px;
  }
  z-index: -1;
  margin: 2vw;
  background: transparent;
`;

// const LandingWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

const SloganText = styled.p`
  font-family: "MontserratSemiBold";
  font-size: 26px;
  color: white;
  margin-top: 8px;
  @media only screen and (max-width: 1450px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 950px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 470px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 380px) {
    font-size: 16px;
  }
`;

const CentralLogoImg = styled.img`
  width: 35vw;
  margin-top: 70px;
  @media only screen and (max-width: 1450px) {
    width: 30vw;
  }
  @media only screen and (max-width: 1150px) {
    margin-top: 40px;
    width: 38vw;
  }
  @media only screen and (max-width: 799px) {
    width: 60vw;
  }
  z-index: 1;
`;

const CallToActionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  z-index: 1;
`;

const StyledLineTop = styled.div`
  height: 3px;
  width: 50vw;
  background-image: linear-gradient(
    to right,
    #ee3189,
    #f36d21,
    #70c8b8,
    #469dd7
  );
  margin-bottom: 25px;
  @media only screen and (max-width: 1150px) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 799px) {
    margin-bottom: 40px;
    width: 70vw;
  }
  z-index: 1;
`;

const CopyTextCallToAction = styled.p`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "MontserratSemiBold";
  font-size: 24px;
  color: white;
  width: 50vw;
  @media only screen and (max-width: 1150px) {
    width: 65vw;
    font-size: 20px;
  }
  @media only screen and (max-width: 420px) {
    width: 75vw;
  }
  text-shadow: -0.2px -0.2px 0 black, 0.2px -0.2px black, -0.2px 0.2px black,
    0.2px 0.2px black;
  z-index: 1;
`;

const CoreServicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CatchyLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  width: 24vw;
  @media only screen and (max-width: 799px) {
    width: 70vw;
  }
`;

const CatchyLineLogo = styled.img`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3vw;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 799px) {
    width: 8vw;
  }
  @media only screen and (max-width: 480px) {
    width: 12vw;
  }
`;

const CatcheyLineTitle = styled.p`
  font-size: 22px;
  height: 60px;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 1350px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 16px;
    min-height: 45px;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 799px) {
    font-size: 20px;
    height: 20px;
    margin-bottom: 1.5rem;
  }

  font-family: "MontserratSemiBold";
  text-align: center;
  font-weight: 600;
  margin-right: 1rem;
  margin-left: 1rem;
`;

const CatcheyLineDetailText = styled.p`
  font-size: 18px;
  font-family: "MontserratReg";
  text-align: center;
  margin-right: 2rem;
  margin-left: 2rem;
  @media only screen and (max-width: 799px) {
    width: 70vw;
  }
`;

const ModalDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalBorder = styled.div`
  padding: 3px;
  //Code to make a border gradient
  margin: 2vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 22px;
  margin: 30px;
`;

const ModalChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const BecomeACoachText = styled.p`
  font-family: "MontserratReg";
  font-size: 18px;
  margin-right: 10px;
`;

const BecomeACoachTitle = styled.p`
  font-family: "MontserratBold";
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledSwitch = withStyles({
  switchBase: {
    color: grey,
    fontSize: "18px",
    "&$checked": {
      color: "#85469b",
    },
    "&$checked + $track": {
      backgroundColor: "#85469b",
    },
  },
  checked: {},
  track: {},
})(Switch);

const LandingPageAuth = ({ firebase, auth }) => {
  const [alreadyTookAssessment, setAlreadyTookAssessment] = useState(undefined);
  const [animate, setAnimate] = useState(false);
  const [animateButton, setAnimateButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [becomeCoach, setBecomeCoach] = useState(false);
  const [becomeACoach2, setBecomeACoach2] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setBecomeCoach(!becomeCoach);
  };

  const handleChange2 = () => {
    setBecomeACoach2(!becomeACoach2);
  };

  const addCoach = () => {
    firebase.user(auth.uid).update({ coach: true });
    setOpen(false);
  };

  const body = (
    <ModalDiv>
      <ModalBorder>
        <ModalInnerWrapper>
          <BecomeACoachTitle>Sign Up to Lead with Data</BecomeACoachTitle>
          <ModalChoiceWrapper>
            <BecomeACoachText>
              Sign me up to give assessments to my employees or coachees.
            </BecomeACoachText>
            <StyledSwitch checked={becomeCoach} onChange={handleChange} />
          </ModalChoiceWrapper>
          <ModalChoiceWrapper>
            <BecomeACoachText>
              I will not use their assessment results in any unethical or
              illegal ways.
            </BecomeACoachText>
            <StyledSwitch checked={becomeACoach2} onChange={handleChange2} />
          </ModalChoiceWrapper>
          {becomeCoach && becomeACoach2 && (
            <StyledButton primary fontSize="1.3rem" callback={addCoach}>
              Become a Coach
            </StyledButton>
          )}
        </ModalInnerWrapper>
      </ModalBorder>
    </ModalDiv>
  );

  const [loading, setLoading] = useState(true);
  const imagesLoaded = useRef(0);
  const loadedImage = () => {
    imagesLoaded.current += 1;
    console.log(`Images loaded: ${imagesLoaded.current}`);
    if (imagesLoaded.current === 2) {
      setLoading(false);
    }
  };

  useEffect(() => {
    //Only start the animation once all images have been loaded
    if (loading) {
      setTimeout(() => {
        setAnimate(true);
      }, 300);
      setTimeout(() => {
        setAnimateButton(true);
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    if (animate) {
      console.log("Animate set to true");
    }
  }, [animate]);

  firebase
    .user(auth.uid)
    .get()
    .then((doc) =>
      setAlreadyTookAssessment(doc.data().userTookAssessmentTypology || false)
    );

  //Temporary for testing the carousel
  const SLIDE_CONTENTS = [
    { detail: EasyToRead, title: "Easy to Read" },
    { detail: MachineLearningAnalyzed, title: "Machine Learning Analysis" },
  ];

  return (
    <React.Fragment>
      {/* Main image and call to action section */}
      <MainImage onLoad={loadedImage} animate={animate} src={BRImage} />
      <CallToActionWrapper>
        <CentralLogoImg onLoad={loadedImage} src={CentralLogo} alt="logo" />
        <SloganText>KNOW YOUR TEAM GROW YOUR TEAM</SloganText>
        <StyledLineTop />
        <CopyTextCallToAction>
          Personality assessments help individuals grow, but we know the secret
          sauce of performance is the team. We provide powerful tools to
          leverage personal growth into team growth.
        </CopyTextCallToAction>
        {alreadyTookAssessment !== undefined && animate && (
          <React.Fragment>
            {alreadyTookAssessment ? (
              <StyledButton
                callback={() => handleOpen()}
                bright
                slideUp
                fontSize="1.5rem">
                BECOME A COACH
              </StyledButton>
            ) : (
              <Link to={ROUTES.ASSESSMENTS}>
                <StyledButton bright slideUp fontSize="1.5rem">
                  TAKE THE ASSESSMENT
                </StyledButton>
              </Link>
            )}
          </React.Fragment>
        )}
      </CallToActionWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
      <MainImageSpacerDiv animate={animate} />
      {/* Catchy Sayings Section */}
      <CoreServicesWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={PersonalityLogo} alt="Personality Logo" />
          <CatcheyLineTitle>Not just another personality test</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Personality tests show where you are, but ours adds where to go
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={TeamLogo} alt="Personality Logo" />
          <CatcheyLineTitle>Know your team coach your team</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Get the clarity you need to lead your unique team on its own journey
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={DataLogo} alt="Personality Logo" />
          <CatcheyLineTitle>Data driven leadership</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Data is the missing element in most team leadership, and its
            unbiased feedback helps you steer your ship
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={GrowthLogo} alt="Personality Logo" />
          <CatcheyLineTitle>We fuel growth</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Our proven methods accelerate growth on your team with insights
            members are hungry for
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
      </CoreServicesWrapper>
      <CarouselSlide slideContents={SLIDE_CONTENTS} />
      <BottomComponent firebase={firebase} />
    </React.Fragment>
  );
};

const LandingPageNotAuth = (props) => {
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(false);
  const imagesLoaed = useRef(0);
  const loadedImage = (params) => {
    imagesLoaed.current += 1;
    if (imagesLoaed === 2) {
      setLoading(false);
    }
  };

  useEffect(() => {
    //Only start animation after all images have loaded
    if (loading) {
      setTimeout(() => {
        setAnimate(true);
      }, 300);
    }
  }, [loading]);

  //Temporary for testing the carousel
  const SLIDE_CONTENTS = [
    { detail: EasyToRead, title: "Easy to Read" },
    { detail: MachineLearningAnalyzed, title: "Machine Learning Analysis" },
    // { detail: TeamDynamicFlow, title: "Team Dynamic Flow" },
  ];

  return (
    <React.Fragment>
      <MainImage onLoad={loadedImage} src={BRImage} />
      <CallToActionWrapper>
        <CentralLogoImg onLoad={loadedImage} src={CentralLogo} alt="logo" />
        <SloganText>KNOW YOUR TEAM GROW YOUR TEAM</SloganText>
        <StyledLineTop />
        <CopyTextCallToAction>
          Personality assessments help individuals grow, but we know the secret
          sauce of performance is the team. We provide powerful tools to
          leverage personal growth into team growth.
        </CopyTextCallToAction>
        <Link to={ROUTES.SIGN_UP}>
          <StyledButton bright slideUp fontSize="1.5rem">
            CREATE AN ACCOUNT
          </StyledButton>
        </Link>
      </CallToActionWrapper>
      <MainImageSpacerDiv animate={animate} />
      {/* Catchy Sayings Section */}
      <CoreServicesWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={PersonalityLogo} alt="Personality Logo" />
          <CatcheyLineTitle>Not just another personality test</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Personality tests show where you are, but ours adds where to go
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={TeamLogo} alt="Personality Logo" />
          <CatcheyLineTitle>Know your team coach your team</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Get the clarity you need to lead your unique team on its own journey
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={DataLogo} alt="Personality Logo" />
          <CatcheyLineTitle>Data driven leadership</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Data is the missing element in most team leadership, and its
            unbiased feedback helps you steer your ship
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
        <CatchyLineWrapper>
          <CatchyLineLogo src={GrowthLogo} alt="Personality Logo" />
          <CatcheyLineTitle>We fuel growth</CatcheyLineTitle>
          <CatcheyLineDetailText>
            Our proven methods accelerate growth on your team with insights
            members are hungry for
          </CatcheyLineDetailText>
        </CatchyLineWrapper>
      </CoreServicesWrapper>
      <CarouselSlide slideContents={SLIDE_CONTENTS} />
      <BottomComponent />
    </React.Fragment>
  );
};

const Landing = ({ firebase }) => {
  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <LandingPageAuth auth={authUser} firebase={firebase} />
        ) : (
          <LandingPageNotAuth />
        )
      }
    </AuthUserContext.Consumer>
  );
};

export default withFirebase(Landing);
