// ***   Written with hooks but not functional when the custom button is used: see with react-square-payment-form   ***
import React, { useState, useContext } from "react";
import styled from "styled-components";
import "./reactPaymentForm.css";
import SquarePaymentForm, {
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  ContextConsumer,
  GooglePayButton,
  ApplePayButton,
} from "react-square-payment-form";
import "./Payment.css";
import { AuthUserContext } from "../../Session";
import { withFirebase } from "../../Firebase";
import Spinner from "react-bootstrap/Spinner";

const StyledButton = styled.button`
  padding: 0.4rem 1.5rem;
  font-size: 1.4rem;
  font-family: Helvetica;
  color: white;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #211f21, black);
  border-radius: 0.6rem;
  width: 14rem;
  cursor: pointer;

  &:hover {
    transition: 0.3s ease-in-out;
    background-image: linear-gradient(180deg, #454545, black);
  }
`;

const StyledSpinner = styled(Spinner)`
  color: white;
  padding: 40px;
`;

const PaymentProcessingText = styled.p`
  font-size: 2rem;
  color: white;
  margin-right: 3rem;
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(120deg, #469dd7, #85469b);
  border-radius: 1rem;
  padding: 1rem;
  width: 40vw;
  @media only screen and (max-width: 1000px) {
    width: 48vw;
  }
  @media only screen and (max-width: 799px) {
    width: 75vw;
  }
  @media only screen and (max-width: 650px) {
    width: 85vw;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 200px;
`;

const PayButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px;
`;

const PaymentTitleWrapper = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  font-family: Helvetica;
  font-size: 1.8rem;
`;

const PaymentButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Payment = (props) => {
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [payWithCard, setPayWithCard] = useState(true);

  const [errorMessages, setErrorMessages] = useState([]);

  //Grab the context
  const authUser = useContext(AuthUserContext);

  const goBack = () => {
    props.backToEmails(false);
  };

  const cardNonceResponseReceived = (
    errors,
    nonce,
    cardData,
    buyerVerificationToken
  ) => {
    if (errors) {
      setErrorMessages(errors.map((error) => error.message));
      return;
    }
    setPaymentProcessing(true);
    setErrorMessages([]);

    try {
      props.firebase.users().doc(authUser.uid).collection(`charges`).add({
        nonce: nonce,
        amount: props.chargeCents,
        verToken: buyerVerificationToken,
        //location: "Online",
        purpose: props.purpose,
        emails: props.emails,
      });
    } catch (err) {
      console.log("Error saving docs to assessments Db ", err);
    }
  };

  const createPaymentRequest = (amount) => {
    let totalDollars = amount / 100;
    let dollarsString = totalDollars.toString().concat(".00");

    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: "USD",
      countryCode: "US",
      total: {
        label: "Team Assessment Central",
        amount: dollarsString,
        pending: false,
      },
      lineItems: [
        {
          label: "AssessmentAccess or Codes",
          amount: dollarsString,
          pending: false,
        },
      ],
    };
  };

  const renderPaymentPage = () => {
    const loadingView = <div className="sq-wallet-loading"></div>;

    return (
      <PaymentWrapper>
        <PaymentTitleWrapper>PURCHASE ACCESS</PaymentTitleWrapper>
        <SquarePaymentForm
          createPaymentRequest={() => createPaymentRequest(props.chargeCents)}
          sandbox={false}
          applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID} //process.env.REACT_APP_SQUARE_APPLICATION_ID
          locationId="LPPNGYPVHJ3JQ"
          cardNonceResponseReceived={cardNonceResponseReceived}
          //createVerificationDetails={createVerificationDetails}
        >
          <GooglePayButton loadingView={loadingView} />
          <ApplePayButton
            loadingView={loadingView}
            // unavailableView={unavailableView}
          />
          <fieldset className="sq-fieldset">
            <CreditCardNumberInput />
            <div className="sq-form-third">
              <CreditCardExpirationDateInput />
            </div>

            <div className="sq-form-third">
              <CreditCardPostalCodeInput />
            </div>

            <div className="sq-form-third">
              <CreditCardCVVInput />
            </div>
          </fieldset>
          <PaymentButtonWrapper>
            <PayButtonWrapper>
              <ContextConsumer>
                {(context) => (
                  <StyledButton
                    width="200px"
                    fontSize="1.3rem"
                    onClick={(event) => {
                      context.onCreateNonce(event);
                    }}>
                    Pay ${props.chargeCents / 100}
                  </StyledButton>
                  // <GooglePayButton />
                )}
              </ContextConsumer>
            </PayButtonWrapper>
            {props.backToEmails && (
              <StyledButton fontSize="1.3rem" tertiary onClick={() => goBack()}>
                Back to Emails
              </StyledButton>
            )}
          </PaymentButtonWrapper>
        </SquarePaymentForm>

        <div className="sq-error-message">
          {errorMessages.map((errorMessage) => (
            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
          ))}
        </div>
      </PaymentWrapper>
    );
  };

  return (
    <div>
      {paymentProcessing && (
        <PaymentWrapper>
          <SpinnerWrapper>
            <PaymentProcessingText>Payment Processing</PaymentProcessingText>
            <StyledSpinner animation="border" role="status" />
          </SpinnerWrapper>
        </PaymentWrapper>
      )}
      {!paymentProcessing && renderPaymentPage()}
    </div>
  );
};

export default withFirebase(Payment);
