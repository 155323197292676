import React, { useState } from "react";
import { withFirebase } from "../Firebase";
import { useForm } from "../CustomHooks";
import styled from "styled-components";
import StyledButton from "../../assests/buttons";

const InputBox = styled.div`
  position: relative;
  padding-top: 7px;
  padding-bottom: 15px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #85469b" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 5px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const PasswordChangeForm = ({ firebase }) => {
  const [error, setError] = useState(null);

  const [hasFocusPW, setHasFocusPW] = useState(false);
  const [hasFocusPW2, setHasFocusPW2] = useState(false);

  const onSubmit = () => {
    firebase.doPasswordUpdate(inputs.passwordOne).catch((error) => {
      this.setState({ error });
    });
  };

  const { inputs, handleSubmit, handleInputChange } = useForm(onSubmit, {
    passwordOne: "",
    passwordTwo: "",
  });

  const setFocusPW = (hasFocusPW) => {
    if (!inputs.passwordOne) {
      setHasFocusPW(hasFocusPW);
    }
  };

  const setFocusPW2 = (hasFocusPW2) => {
    if (!inputs.passwordTwo) {
      setHasFocusPW2(hasFocusPW2);
    }
  };

  const isInvalid =
    inputs.passwordOne !== inputs.passwordTwo || inputs.passwordOne === "";

  return (
    <form onSubmit={handleSubmit}>
      <InputBox>
        <Input
          name="passwordOne"
          value={inputs.passwordOne}
          onChange={handleInputChange}
          type="password"
          onFocus={() => setFocusPW(true)}
          onBlur={() => setFocusPW(false)}
        />
        <InputLabel hasFocus={hasFocusPW}>New Password</InputLabel>
      </InputBox>
      <InputBox>
        <Input
          name="passwordTwo"
          value={inputs.passwordTwo}
          onChange={handleInputChange}
          type="password"
          onFocus={() => setFocusPW2(true)}
          onBlur={() => setFocusPW2(false)}
        />
        <InputLabel hasFocus={hasFocusPW2}>Confirm Password</InputLabel>
      </InputBox>

      <StyledButton
        primary
        width="300px"
        fontSize="1.5rem"
        disabled={isInvalid}
        type="submit">
        Change My Password
      </StyledButton>
      {error && <h5>{error.message}</h5>}
    </form>
  );
};

export default withFirebase(PasswordChangeForm);
