import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const CopyTextWrapper = styled.p`
  font-size: 20px;
  width: 70vw;
`;

const PageWrapper = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlurbBox = styled.div`
  border: black;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #7abf5f;
`;

const SendEmailButton = styled(Button)`
  padding-top: 20px;
`;

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  //this is added to remove the requirement to verify email for local testing
  window.location.href.split("/")[2].split(":")[0] !== "localhost" &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes("password");

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <PageWrapper>
                {this.state.isSent ? (
                  <BlurbBox>
                    <CopyTextWrapper>
                      Email confirmation sent: Check your E-Mail's inbox and
                      spam folder for a confirmation Email. Refresh this page
                      once you have confirmed your Email.
                    </CopyTextWrapper>
                  </BlurbBox>
                ) : (
                  <BlurbBox>
                    <CopyTextWrapper>
                      Verify your Email: Check your E-Mail's inbox and spam
                      folder for a confirmation Email. Refresh this page once
                      you have confirmed your Email.
                    </CopyTextWrapper>
                  </BlurbBox>
                )}

                <SendEmailButton
                  variant="success"
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Send confirmation Email
                </SendEmailButton>
              </PageWrapper>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
