import React, { useState, useEffect, useContext } from "react";
import Assessment from "./AssessmentComponents/assessment";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../../Session";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import Result from "./AssessmentComponents/Results";
import styled from "styled-components";
import PurchaseAndCode from "../PurchaseCodes/PurchaseAndCode";

import LinearProgress from "@material-ui/core/LinearProgress";

const LinearProgressStyled = styled(LinearProgress)`
  padding-top: 7px;
  margin-top: 7px;
  width: 50vw;
`;

const LoadingBorder = styled.div`
  padding: 3px;
  //Code to make a border gradient
  margin: 2vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5vw;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlurbBox = styled.div`
  margin-top: 35px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  /* border: 2px solid #7abf5f; */

  //Code to make a border gradient
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const CopyTextWrapper = styled.p`
  text-align: center;
  font-family: "MonsterratReg";
  margin: 8px;
  font-size: 26px;
  width: 55vw;
  @media only screen and (max-width: 1050px) {
    width: 70vw;
    font-size: 20px;
  }
`;

const Title = styled.h1``;

const TypologyPage = ({ firebase, tookTypology }) => {
  //State Variables
  const [isLoadingData, setIsLoadingData] = useState(true);
  //turn false if they didn't take it, but this avoids loading payment from ternary
  const [clientTookAssessment, setClientTookAssessment] = useState(false);
  const [assessmentResult, setAssessmentResult] = useState([]);
  const [assessmentResultsNums, setAssessmentResultsNums] = useState([]);
  const [hasAccessToAssessmentOne, setHasAccessToAssessmentOne] =
    useState(false);
  const [viewingAccessResults, setViewingAccessResults] = useState(false);

  //Grab the context
  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    setIsLoadingData(true);
    const getUserData = async (authUser) => {
      let userAccessDoc = await firebase
        .users()
        .doc(authUser.uid)
        .collection(`access`)
        .doc(`hasAccessTypology`);
      userAccessDoc.onSnapshot(
        (doc) => {
          if (doc.exists) {
            let accessUser = doc.data().access;
            let hasNoResultsViewerAccess = doc.data().noResultsAccess;
            setHasAccessToAssessmentOne(accessUser);
            if (
              hasNoResultsViewerAccess === false &&
              hasNoResultsViewerAccess !== undefined
            ) {
              setViewingAccessResults(true);
            }
          }
        },
        (err) => {
          console.error(`Encountered error in user access retrieval: ${err}`);
        }
      );

      const userDataDoc = await firebase.users().doc(authUser.uid);
      userDataDoc.onSnapshot(
        (doc) => {
          let resultsUser = doc.data().assessmentResult;
          let resultsNumsUser = doc.data().assessmentResultNums;

          //Do not allow the user to view their results if purchased by a coach, created as a noAccess=true so any use that
          //does not have this entry at all will be undefined/false, which will give access (by not taking it away)

          setAssessmentResult(resultsUser);
          setAssessmentResultsNums(resultsNumsUser);
          setIsLoadingData(false);
        },
        (err) => {
          console.error(`Encountered error in user results retrieval: ${err}`);
        }
      );

      //cancel listener subscription
      return userDataDoc, getUserData;
    };
    if (authUser) {
      getUserData(authUser);
    }
  }, [authUser, firebase, hasAccessToAssessmentOne]);

  const setAssessmentResultUponCompletion = (assessmentResultObject) => {
    firebase
      .user(authUser.uid)
      .collection(`rawAssessmentResults`)
      .doc(`typology`)
      .set({ rawResultsArr: assessmentResultObject });
  };

  const textForIntro =
    "Tyology assessment, a great choice!  There are two ways to gain access to the assessment, you may use a code provided by someone else or purchase access.";

  const RenderResult = () => {
    return viewingAccessResults ? (
      <Result
        assessmentResult={assessmentResult}
        assessmentResultNums={assessmentResultsNums}
      />
    ) : (
      <BlurbBox>
        <CopyTextWrapper>
          Thank you so much for taking our Typology assessment. Your results
          have been sent to the individual who purchased your access code.
        </CopyTextWrapper>
      </BlurbBox>
    );
  };

  const RenderPurchase = () => {
    return (
      <PurchaseAndCode
        firebase={firebase}
        authUser={authUser}
        purpose="typologyAccess"
        chargeCents={1500}
        setViewingAccessResults={setViewingAccessResults}
        introText={textForIntro}
      />
    );
  };

  return (
    <PageWrapper>
      {!tookTypology | (tookTypology & !viewingAccessResults) ? (
        <Title>Typology</Title>
      ) : null}
      {isLoadingData && (
        <LoadingBorder>
          <LoadingWrapper>
            <h4>Loading User Data...</h4>
            <LinearProgressStyled />
          </LoadingWrapper>
        </LoadingBorder>
      )}
      {!isLoadingData && hasAccessToAssessmentOne && (
        <React.Fragment>
          {tookTypology ? (
            assessmentResult !== undefined &&
            assessmentResult.length > 0 &&
            assessmentResultsNums !== undefined &&
            assessmentResultsNums.length > 0 && <RenderResult />
          ) : (
            <Assessment
              setAssessmentResults={setAssessmentResultUponCompletion}
              resultsAccess={viewingAccessResults}
              firebase={firebase}
            />
          )}
        </React.Fragment>
      )}
      {!isLoadingData && !hasAccessToAssessmentOne && <RenderPurchase />}
    </PageWrapper>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(TypologyPage);
