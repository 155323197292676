import React, { useEffect, useState } from "react";
import TeamAssessmentBase from "./teamAssessmentBase";
import teamAssessmentQuestions from "./teamAssessmentQuestions";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const LoadingSpinner = styled(Spinner)`
  padding-top: 400 px;
  width: 80px;
  height: 80px;
`;

const TeamAssessment = (props) => {
  const [question, setQuestion] = useState({
    questionText: "",
    questionID: "",
    answerChoices: [],
  });
  //Question number is the source of truth, -1 means it hasn't loaded yet
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(undefined);
  const [answerResponses, setAnswerResponses] = useState([]);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [assessmentComplete, setAssessmentComplete] = useState(false);
  const [assessmentLength, setAssessmentLength] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  //Source of truth to tie together components is the currentQuestionNumber
  //Run the following on initial load to shuffle questions and determine assessment length
  useEffect(() => {
    // if is here to make sure we don't accidentally run when this has already been done.
    if (!currentQuestionNumber) {
      //Shuffle the choices within each question's array of available answers
      //This code is not working:
      // const shuffledAnswersInEachQuestion = teamAssessmentQuestions.map(
      //   (ques) => shuffleArray(ques)
      // );
      //Shuffle the questions within the assessment as a whole
      const shuffledQuestionsArr = shuffleArray(teamAssessmentQuestions);
      //Set the array of questions to the state variable and move the currentQuestionNumber to Zero
      setShuffledQuestions(shuffledQuestionsArr);
      setAssessmentLength(shuffledQuestionsArr.length);
      setCurrentQuestionNumber(0);
    }
  }, []);

  const storeAnswerAndIncrement = (answerToStore) => {
    let initialAnswerResponses = [...answerResponses];
    initialAnswerResponses[currentQuestionNumber] = answerToStore;
    setAnswerResponses(initialAnswerResponses);
    setCurrentQuestionNumber(currentQuestionNumber + 1);
  };

  useEffect(() => {
    //This prevents the setQuestion useEffect from running if the first useEffect has not yet run
    if (typeof currentQuestionNumber === "number") {
      if (
        //Check if array is empty (first useEffect hasn't run) yet and we are not on the last question of the assessment
        currentQuestionNumber < assessmentLength
      ) {
        setQuestion({
          questionText: shuffledQuestions[currentQuestionNumber].questionText,
          questionID: shuffledQuestions[currentQuestionNumber].questionID,
          answerChoices: shuffledQuestions[currentQuestionNumber].answers,
        });
      }
      if (
        //This is +1 to account for the 0 starting posistion of arrays
        currentQuestionNumber ===
        assessmentLength - 1
      ) {
        setAssessmentComplete(true);
        props.setTeamDysfunctionResults(answerResponses);
      }
      setIsLoading(false);
      console.log(`Answers so far are ${answerResponses}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionNumber]);

  const shuffleArray = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  return (
    <div>
      <h3>Dynamics Assessment for Team: {props.teamName}</h3>
      {assessmentComplete && (
        <div>
          <LoadingSpinner animation="border" role="status" />
          <h4>Registering results, please wait...</h4>
        </div>
      )}
      {!assessmentComplete && !isLoading && (
        <TeamAssessmentBase
          questionData={question}
          currentQuestionNumber={currentQuestionNumber}
          setCurrentQuestionNumber={setCurrentQuestionNumber}
          storeAnswerAndIncrement={storeAnswerAndIncrement}
          totalQuestions={assessmentLength}
        />
      )}
    </div>
  );
};

export default TeamAssessment;
