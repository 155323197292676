import React, { useState } from "react";
import enterView from "enter-view";

import PropTypes from "prop-types";
import typeBlurb, {
  tempermentLeadershipStyleBlurb,
  coreValueBlurb,
  coreQuestBlurb,
  coreStrengthsBlurb,
  coreWeaknessesBlurb,
  coreStressorsBlurb,
  coreRecommendationsBlurb,
} from "./resultsText";
import IntervalCounter from "../../../../assests/intervalCounter";
import PieChart from "react-minimal-pie-chart";
import styled from "styled-components";
import extrovertIMG from "../../../../assests/icons/extrovert.svg";
import introvertIMG from "../../../../assests/icons/introvert.svg";
import intuitorIMG from "../../../../assests/icons/intuitor.svg";
import sensorIMG from "../../../../assests/icons/sensor.svg";
import feelerIMG from "../../../../assests/icons/feeler.svg";
import thinkerIMG from "../../../../assests/icons/thinker.svg";
import judgerIMG from "../../../../assests/icons/judger.svg";
import perceiverIMG from "../../../../assests/icons/perceiver.svg";

const TitleText = styled.h2`
  text-align: center;
`;

const Text = styled.p`
  font-size: 26px;
  font-family: "MontserratReg";
  @media only screen and (max-width: 799px) {
    text-align: center;
  }
`;

const TypeWord = styled.span`
  font-family: "MontserratSemiBold";
  color: ${(props) => props.color || "#000000"};
  font-weight: 500;
`;

const PortionTitle = styled.p`
  font-family: "MontserratSemiBold";
  font-size: 26px;
  font-weight: 900;
  margin-right: 1rem;
  min-width: 200px;
  text-align: justify;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DetailsText = styled.p`
  font-family: "MontserratReg";
  font-size: 22px;
`;

const TempermentWrapper = styled.div``;

const TypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5vw;
`;

const PieChartsGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const PieChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-self: center;
  padding: 5px;
`;

const TypePieLabel = styled.p`
  font-size: 1.8rem;
  font-family: "MontserratSemiBold";

  color: ${({ colorKey }) => colorKey};
`;

const Icon = styled.img`
  width: 130px;
  margin: 15px;
  margin-right: 25px;
`;

const TempermentItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const Result = (props) => {
  const [triggerAnimationBegin, setTriggerAnimationBegin] = useState(false);

  const eiImg =
    props.assessmentResult[0] === "Extrovert" ? extrovertIMG : introvertIMG;

  const snImg =
    props.assessmentResult[1] === "Sensor" ? sensorIMG : intuitorIMG;

  const tfImg =
    props.assessmentResult[2] === "Thinker" ? thinkerIMG : feelerIMG;

  const jpImg =
    props.assessmentResult[3] === "Judger" ? judgerIMG : perceiverIMG;

  const resultDetailsObject = [
    {
      delay: 100,
      color: "#EE3189",
      value: props.assessmentResultNums[0],
      resultName: props.assessmentResult[0],
      icon: eiImg,
    },
    {
      delay: 800,
      color: "#F36D21",
      value: props.assessmentResultNums[1],
      resultName: props.assessmentResult[1],
      icon: snImg,
    },
    {
      delay: 1400,
      color: "#85469b",
      value: props.assessmentResultNums[2],
      resultName: props.assessmentResult[2],
      icon: tfImg,
    },
    {
      delay: 2200,
      color: "#469dd7",
      value: props.assessmentResultNums[3],
      resultName: props.assessmentResult[3],
      icon: jpImg,
    },
  ];

  let tempermentCore;
  let coreOne = props.assessmentResult[1].toString();
  let coreTwo;
  if (coreOne === "Intuitor") {
    coreTwo = props.assessmentResult[2].toString();
  } else if (coreOne === "Sensor") {
    coreTwo = props.assessmentResult[3].toString();
  }
  tempermentCore = coreOne.concat(coreTwo);

  const tempermentDetails = [
    {
      title: "Leadership Style",
      details: tempermentLeadershipStyleBlurb[tempermentCore],
    },
    { title: "Core Value", details: coreValueBlurb[tempermentCore] },
    { title: "Quest ", details: coreQuestBlurb[tempermentCore] },
    { title: "Strengths", details: coreStrengthsBlurb[tempermentCore] },
    { title: "Weaknesses", details: coreWeaknessesBlurb[tempermentCore] },
    { title: "Stressors", details: coreStressorsBlurb[tempermentCore] },
    {
      title: "Growth Path",
      details: coreRecommendationsBlurb[tempermentCore],
    },
  ];

  enterView({
    selector: ".pieCharts",
    enter: (el) => {
      console.log(`The charts have entered, trigger the animations`);
      setTriggerAnimationBegin(true);
    },
  });

  return (
    <PageWrapper>
      <TitleText>
        {props.source === "Coach"
          ? `${props.memberName}'s Typology Results`
          : "My Typology"}
      </TitleText>
      {resultDetailsObject.map((resultDetail) => (
        <TypeWrapper key={resultDetail.resultName.concat("Wrapper")}>
          <Icon
            key={resultDetail.resultName.concat("Icon")}
            src={resultDetail.icon}
          />
          <Text key={resultDetail.resultName.concat("TextBlock")}>
            As{" "}
            {resultDetail.resultName.split("")[0] === "E" ||
            resultDetail.resultName.split("")[0] === "I"
              ? "an"
              : "a"}{" "}
            <TypeWord
              key={resultDetail.resultName.concat("TitleWord")}
              color={resultDetail.color}>
              {resultDetail.resultName}
            </TypeWord>{" "}
            {props.source === "Coach" ? `${props.memberName}` : `you `}{" "}
            {typeBlurb[resultDetail.resultName]}
          </Text>
        </TypeWrapper>
      ))}
      <TitleText>
        {props.source === "Coach"
          ? `${props.memberName}'s Core Temperment`
          : "Core Temperment"}
      </TitleText>
      <TempermentWrapper>
        {tempermentDetails.map((detailItem) => (
          <TempermentItemWrapper
            key={detailItem.title.concat("TempermentWrapper")}>
            <TitleDiv key={detailItem.title.concat("TempermentTitleDiv")}>
              <PortionTitle key={detailItem.title.concat("TempermentTitle")}>
                {detailItem.title}
              </PortionTitle>
            </TitleDiv>
            <DetailsText key={detailItem.title.concat("TempermentDetails")}>
              {detailItem.details}
            </DetailsText>
          </TempermentItemWrapper>
        ))}
      </TempermentWrapper>

      {/* Wraps the pie charts with a div that has a class name for the
      enter-view to trigger the animation upon scroll. */}

      <PieChartsGroupWrapper className="pieCharts">
        {resultDetailsObject.map((resultDetailObject) => (
          <PieChartWrapper
            key={resultDetailObject.resultName.concat("PieWrapper")}>
            <PieChart
              key={resultDetailObject.resultName.concat("PieChart")}
              animate={true}
              animationDuration={500}
              animationEasing="ease-out"
              cx={50}
              cy={50}
              data={[
                {
                  color: resultDetailObject.color,
                  value: IntervalCounter({
                    countSpeed: 30,
                    trigger: triggerAnimationBegin,
                    value: resultDetailObject.value,
                    delay: resultDetailObject.delay,
                  }),
                },
              ]}
              label
              labelPosition={0}
              labelStyle={{
                fontFamily: "sans-serif",
                fontSize: "10px",
              }}
              lengthAngle={360}
              lineWidth={20}
              onClick={undefined}
              onMouseOut={undefined}
              onMouseOver={undefined}
              paddingAngle={0}
              radius={40}
              rounded={false}
              startAngle={0}
              totalValue={100}
              viewBoxSize={[35, 35]}
            />

            <TypePieLabel colorKey={resultDetailObject.color}>
              {resultDetailObject.resultName}
            </TypePieLabel>
          </PieChartWrapper>
        ))}
      </PieChartsGroupWrapper>
    </PageWrapper>
  );
};

Result.propTypes = {
  assessmentResult: PropTypes.array.isRequired,
};

export default Result;
