import { useState, useEffect } from "react";

const useProgressiveImg = (lowResSource, highResSource) => {
  const [src, setSrc] = useState(lowResSource);
  const [quickLoad, setQuickLoad] = useState(false);

  //Variable for timer to eliminate flicker on fast image load
  let timeUp = false;
  setTimeout(() => {
    timeUp = true;
  }, 100);

  useEffect(() => {
    setSrc(lowResSource);

    const img = new Image();
    img.src = highResSource;

    img.onload = () => {
      setSrc(highResSource);
      if (!timeUp) {
        setQuickLoad(true);
      }
    };
  }, [lowResSource, highResSource, timeUp]);

  return [src, { blur: src === lowResSource, cancelAnimate: quickLoad }];
};

export default useProgressiveImg;

// Usage:
// const BlurredUpImage = () => {
//   const [src, { blur }] = useProgressiveImg("./tiny.jpg", "./large.jpg");
//   return (
//     <img
//       src={src}
//       style={{
//         width: 200,
//         filter: blur ? "blur(20px)" : "none",
//         transition: blur ? "none" : "filter 0.3s ease-out",
//       }}
//     />
//   );
// };
