import { useRef, useEffect, useState } from "react";

const IntervalCounter = ({ value, delay, countSpeed, trigger }) => {
  const [triggerCount, setTriggerCount] = useState(false);
  const [valueCounter, setValueCounter] = useState(0);

  const delayPerIncrement = countSpeed || 10;

  const maxCounter = value;
  const counter = useRef(undefined);

  useEffect(() => {
    if (trigger) {
      setTimeout(() => {
        setTriggerCount(true);
      }, delay);
    }
  }, [delay, trigger]);

  //Run this on load
  useEffect(() => {
    if (trigger) {
      if (triggerCount) {
        counter.current = setInterval(() => {
          if (valueCounter !== maxCounter) {
            setValueCounter((valuePercentage) => valuePercentage + 1);
          }
        }, delayPerIncrement);
      }
    }
    return () => {
      clearInterval(counter.current);
    };
  }, [triggerCount, valueCounter, trigger, delayPerIncrement, maxCounter]);

  //Stop counting interval when the max number is reached by clearing the interval
  useEffect(() => {
    if (valueCounter >= maxCounter) {
      clearInterval(counter);
    }
  }, [maxCounter, valueCounter]);
  return valueCounter;
};

export default IntervalCounter;
