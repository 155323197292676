import { useState, useEffect } from "react";

const useSignUpForm = (callback) => {
  const [inputs, setInputs] = useState({});

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

const useCreateTeamForm = (callback) => {
  const [inputsCreate, setInputsCreate] = useState({});

  const handleSubmitCreate = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChangeCreate = (event) => {
    event.persist();
    setInputsCreate((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmitCreate,
    handleInputChangeCreate,
    inputsCreate,
  };
};

const useForm = (callback) => {
  const [inputs, setInputs] = useState({});

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
  };
};

//Included for pages that require the use of 2 forms
const useForm2 = (callback) => {
  const [inputs2, setInputs2] = useState({});

  const handleSubmit2 = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChange2 = (event) => {
    event.persist();
    setInputs2((inputs) => ({
      ...inputs2,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit2,
    handleInputChange2,
    inputs2,
  };
};

const useForm3 = (callback) => {
  const [inputs3, setInputs3] = useState({});

  const handleSubmit3 = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const handleInputChange3 = (event) => {
    event.persist();
    setInputs3((inputs) => ({
      ...inputs3,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleSubmit3,
    handleInputChange3,
    inputs3,
  };
};

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default useSignUpForm;
export { useForm, useForm2, useForm3, useCreateTeamForm, useOnClickOutside };
