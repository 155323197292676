export const LANDING = "/";
export const SIGN_UP = "/signup";
export const FINISH_SIGN_UP = "/finishsignup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const TYPOLOGY = "/assessments/typology";
export const SOCIALSTYLES = "/assessments/socialstyles";
export const TEAM = "/team";
export const PASSWORD_FORGET = "/pw-forget";
export const ADMIN = "/admin";
export const ADMIN_DETAILS = "/admin/:id";
export const PURCHASECODES = "/assessments/buycodes";
export const ASSESSMENTS_HOME = "/assessments/home";
export const CREATE_TEAM = "/team/createteam";
export const JOIN_TEAM = "/team/jointeam";
export const MANAGE_TEAM = "/team/manageteam";
export const ASSESSMENTS = "/assessments";
export const SIGNINLINK = "/signinlink";
export const COACHES = "/coach";
export const COACHES_MEMBER = "/coach/:id";
export const APPLE_ID =
  "/.well-known/apple-developer-merchantid-domain-association";
