import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useForm } from "../CustomHooks";
import { withFirebase } from "../Firebase";
import styled from "styled-components";
import * as ROUTES from "../../constants/routes";

const Heading2 = styled.h2`
  color: #5b5656;
  text-align: center;
  padding-bottom: 20px;
`;

const BlackButton = styled.button`
  display: flex;
  width: 250px;
  height: 48px;
  justify-content: center;
  font-size: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const Box = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => (props.displayMobile ? "top: 120px" : "top: 200px")};
  ${(props) => (props.displayMobile ? "left: 2.5%" : "left: 33.33%")};
  transform: translate(-50% -50%);
  width: 400px;
  background-color: #f0efef;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #7abf5f" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const InputBox = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const HackSolutionDiv = styled.div`
  height: 110px;
  width: 100vw;
`;

const SignInLinkFormBase = ({ firebase }) => {
  const [error, setError] = useState(null);
  const [hasFocusEM, setHasFocusEM] = useState(false);

  const history = useHistory();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const displayMobile = windowWidth >= 900 ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    inputs.email = window.localStorage.getItem("emailForSignIn");
    if (inputs.email) {
      setHasFocusEM(true);
    }
  }, []);

  const submitSignInWithLink = () => {
    // Confirm the link is a sign-in with email link.

    firebase
      .doFinishSignInWithEmailLink(inputs.email, window.location.href)
      .then(function (result) {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // Access the new user via result.user
      })
      .then(() => {
        history.push(ROUTES.ASSESSMENTS);
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    submitSignInWithLink,
    {
      email: "",
    }
  );

  const setFocusEM = (hasFocusEM) => {
    if (!inputs.email) {
      setHasFocusEM(hasFocusEM);
    }
  };

  const isInvalid = inputs.email === "";

  return (
    <React.Fragment>
      {displayMobile ? <HackSolutionDiv /> : null}
      <Box displayMobile={displayMobile}>
        <Heading2>Finish Signing In</Heading2>
        <form onSubmit={handleSubmit}>
          <InputBox>
            <Input
              name="email"
              value={inputs.email}
              onChange={handleInputChange}
              type="text"
              onFocus={() => setFocusEM(true)}
              onBlur={() => setFocusEM(false)}
            />
            <InputLabel hasFocus={hasFocusEM}>Verify Email Address</InputLabel>
          </InputBox>
          <BlackButton
            disabled={isInvalid}
            variant="outline-success"
            type="submit"
          >
            Finish Signing In
          </BlackButton>
          {error && <p>{error.message}</p>}
        </form>
      </Box>
    </React.Fragment>
  );
};

const SignInLinkPage = () => (
  <div>
    <SignInLinkForm />
  </div>
);

const SignInLinkForm = withRouter(withFirebase(SignInLinkFormBase));

export default withFirebase(SignInLinkPage);
