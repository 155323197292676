import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as ROUTES from "../../constants/routes";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "team-assessment-central.firebaseapp.com",
  databaseURL: "https://team-assessment-central.firebaseio.com",
  projectId: "team-assessment-central",
  storageBucket: "team-assessment-central.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPLICATION_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    //const location = useLocation();

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.auth = app.auth();
    this.db = app.firestore();

    //splits out localhost from window address, and sets db to localhost as well
    if (window.location.href.split("/")[2].split(":")[0] === "localhost") {
      //These db settings are for local testing, remove them for deployment
      this.db.settings({
        host: "localhost:8080",
        ssl: false,
      });
    }

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doFinishSignInWithEmailLink = (email, href) => {
    console.log(
      `Executing signInWithEmailList using email: ${email} and the link ${href}`
    );
    return this.auth.signInWithEmailLink(email, href);
  };

  doFetchEmailLoginMethods = async (email) => {
    var method;
    const signInMethods = await this.auth.fetchSignInMethodsForEmail(email);
    if (
      signInMethods.indexOf(
        app.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      ) !== -1
    ) {
      console.log("User has logged in with an email link previously");
      method = "emailLink";
    }
    if (
      signInMethods.indexOf(
        app.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
      ) !== -1
    ) {
      console.log("User has logged in with a password previously");
      method = "emailAndPassword";
    }
    return method;
  };

  doSendEmailLinkSignIn = (actionCodeSettings, email) => {
    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings);
  };

  isValidEmailLink = (href) => {
    this.auth.isSignInWithEmailLink(href);
  };

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => {
    this.auth.signOut();
    console.log("Signing out");
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({});

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            var dbUser;
            if (
              !snapshot.exists &&
              window.location.href.split("/")[2].split(":")[0] === "localhost"
            ) {
              console.log(`User not in DB`);
              this.user(authUser.uid).set(
                {
                  username: null,
                  email: authUser.email,
                  roles: { ADMIN: "NOTADMIN" },
                },
                {
                  merge: true,
                }
              );
              dbUser = {
                username: authUser.email,
                roles: { ADMIN: "NOTADMIN" },
              };
            } else {
              dbUser = { ...dbUser, roles: {} };
              dbUser = snapshot.data();
            }

            //default empty roles
            if (!snapshot.exists || !dbUser.roles) {
              dbUser.roles = { ADMIN: "NOTADMIN" };
            }

            //merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  //   *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  //   *** Message API ***
  message = (uid) => this.db.doc(`messages/${uid}`);

  messages = () => this.db.collection("messages");

  //   *** Assessment API   ***
  assessmentResult = (uid) => this.db.doc(`assessmentResults/${uid}`);

  assessmentResults = () => this.db.collection("assessmentResults");

  //   ***   Team Creation API   ***
  team = (uid) => this.db.doc(`teams/${uid}`);

  teams = () => this.db.collection("teams");

  //   ***   Payment Code API   ***
  code = (uid) => this.db.doc(`codes/${uid}`);

  codes = () => this.db.collection("purchasedCodes");

  //   ***    For contact forms    ***
  contacts = () => this.db.collection("contacts");
}

export default Firebase;
