import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Payment from "./Payment";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../../Session";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import StyledButton from "../../../assests/buttons";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";

const InputBox = styled.div`
  position: relative;
`;

const BoxModal = styled.div`
  padding: 20px;
`;

const HeadingInstructionsText = styled.p`
  font-family: Helvetica;
  font-size: 1.2rem;
  width: 40vw;
`;

const EmailList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #85469b" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 0.8rem" : "font-size: 1.2rem")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;
const EmailInput = styled.input`
  width: 35vw;
  padding: 5px 0;
  font-size: 1.2rem;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
  @media only screen and (max-width: 1000px) {
    width: 50vw;
  }
  @media only screen and (max-width: 350px) {
    width: 70vw;
  }
`;

const EmailInputsComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const EmailBox = styled.form`
  justify-content: flex-start;
  width: 60vw;
  @media only screen and (max-width: 1000px) {
    width: 90vw;
  }
`;

const PurchaseHistoryTitle = styled.h5`
  padding-top: 20px;
  padding-bottom: 10px;
`;

const PurchaseCodes = ({ firebase }) => {
  return (
    <BoxModal>
      <PurchaseCodesBase firebase={firebase} />
    </BoxModal>
  );
};

const PurchaseCodesBase = (props) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [emails, setEmails] = useState([]);
  const [inputs, setInputs] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [paymentPageActive, setPaymentPageActive] = useState(false);
  const [clientPurchasedCodes, setClientPurchasedCodes] = useState([]);
  const [usedCodes, setUsedCodes] = useState({});
  const [clientCodeStatus, setClientCodeStatus] = useState({});
  const [codesSentToEmails, setCodesSentToEmails] = useState({});
  const [purchaseHistory, setPurchaseHistory] = useState(false);
  const costPerItem = 10;

  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    if (authUser) {
      getUserData(authUser);
    }
  }, [authUser]);

  const getUserData = async (authUser) => {
    let doc = props.firebase
      .users()
      .doc(authUser.uid)
      .collection(`purchasedCodes`);

    doc.onSnapshot(
      (docSnapshot) => {
        let codesArray = [];
        let codesAndEmails = {};
        // Here this should display purchases IF there are any
        docSnapshot.forEach((doc) => {
          codesArray.push(doc.data().code);
          codesAndEmails[doc.data().code] = doc.data().userEmail;
        });
        setClientPurchasedCodes(codesArray);
        setCodesSentToEmails(codesAndEmails);
        setPaymentPageActive(false);
        setEmails([]);
        if (!docSnapshot.empty) {
          setPurchaseHistory(true);
        }
      },
      (err) => {
        console.error(`Encountered error: ${err}`);
      }
    );
  };

  useEffect(() => {
    let usedCodesList = {};
    let usedCodesDoc = props.firebase.codes().where("used", "==", true);
    usedCodesDoc.onSnapshot((docSnapshot) => {
      docSnapshot.forEach((doc) => {
        usedCodesList[doc.data().accessCode] = "Used by: ".concat(
          doc.data().usedBy
        );
      });
      clientPurchasedCodes.forEach((code) => {
        if (usedCodesList[code] === undefined) {
          usedCodesList[code] = "Sent to: ".concat(codesSentToEmails[code]);
        }
        console.log(usedCodesList[code]);
      });
      setClientCodeStatus(usedCodesList);
    });

    setUsedCodes();
  }, [clientPurchasedCodes, codesSentToEmails, props.firebase]);

  const enterName = () => {
    if (inputs.email) {
      let emailArray = emails;
      emailArray.push(inputs.email);
      setEmails(emailArray);
      setInputs({ email: "" });
      setHasFocus(false);
      setTotalCost(emails.length * costPerItem);
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    enterName();
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const setFocus = (hasFocus) => {
    if (!inputs.email) {
      setHasFocus(hasFocus);
    }
  };

  const removeEmail = (emailToRemove) => {
    let result = emails.filter((email) => emailToRemove !== email);
    setEmails(result);
    setTotalCost(result.length * costPerItem);
  };

  const editEmail = (emailToEdit) => {
    console.log(emailToEdit);
    let listAfter = emails.filter((email) => emailToEdit !== email);
    setEmails(listAfter);
    setTotalCost(listAfter.length * costPerItem);
    inputs.email = emailToEdit;
    setHasFocus(true);
  };

  const renderEmailListBuilder = () => {
    return (
      <div>
        <HeadingInstructionsText>
          Your cost per team member code is <strong>${costPerItem}</strong>.
        </HeadingInstructionsText>

        <EmailBox onSubmit={handleSubmit}>
          <EmailInputsComponentWrapper>
            <InputBox>
              <EmailInput
                name="email"
                value={inputs.email}
                onChange={handleInputChange}
                type="text"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              />
              <InputLabel hasFocus={hasFocus}>Email Address</InputLabel>
            </InputBox>
            <StyledButton
              type="submit"
              primary
              fontSize="1.2rem"
              width="145px"
              height="40px">
              Add To List
            </StyledButton>
          </EmailInputsComponentWrapper>
        </EmailBox>
        {emails.map((email) => (
          <EmailList>
            <Chip
              icon={<FaceIcon />}
              label={email}
              variant="outlined"
              onClick={() => editEmail(email)}
              onDelete={() => removeEmail(email)}
            />
          </EmailList>
        ))}
        <div>
          <StyledButton
            fontSize="1.4rem"
            primary
            callback={() => {
              setPaymentPageActive(true);
            }}>
            Checkout ${totalCost}
          </StyledButton>
        </div>
      </div>
    );
  };

  const renderPaymentPage = () => {
    return (
      <div>
        <Payment
          purpose="emailedCodes"
          firebase={props.firebase}
          chargeCents={totalCost * 100}
          emails={emails}
          backToEmails={() => setPaymentPageActive()}
        />
      </div>
    );
  };

  return (
    <div>
      {paymentPageActive ? renderPaymentPage() : renderEmailListBuilder()}
      {purchaseHistory && (
        <PurchaseHistoryTitle>Purchase History</PurchaseHistoryTitle>
      )}
      {clientPurchasedCodes.map((code) => (
        <p>
          <strong>{code}</strong> {clientCodeStatus[code]}
        </p>
        //TODO: Add a backend join to show which codes have been used and by what user
      ))}
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(PurchaseCodes);
