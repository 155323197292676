import React, { useState, useEffect, useContext } from "react";
import { compose } from "recompose";
import { useForm } from "../CustomHooks";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";
import { PasswordForgetForm } from "../PasswordForget";
import * as SCREEN from "../../constants/values";
import PasswordChangeForm from "../PasswordChange";
import styled from "styled-components";
import StyledButton from "../../assests/buttons";
import SignOutButton from "../SignOut";

const SIGN_IN_METHODS = [
  {
    id: "password",
    provider: null,
  },
  {
    id: "google.com",
    provider: "googleProvider",
  },
  {
    id: "facebook.com",
    provider: "facebookProvider",
  },
];

const SubHeadingTitle = styled.h4`
  font-family: roboto-light, sans-sarif;
  padding-bottom: 10px;
`;

const HeadingWelcome = styled.h3`
  font-family: roboto-light, sans-sarif;
  padding-top: 10px;
`;

const Heading = styled.h1`
  font-family: roboto, sans-sarif;
`;

const PageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignInMethodElement = styled.div`
  padding: 8px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  ${(props) => (props.displayMobile ? "margin-top: 10px" : "margin-top: 30px")};
  background-color: white;
  ${(props) =>
    props.displayMobile ? null : "margin-left: 4vw; margin-right: 4vw;"}
`;

const ComponentBorder = styled.div`
  margin: 2vw;
  padding: 5px;
  //Code to make a border gradient
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputBox = styled.div`
  position: relative;
  padding-top: 7px;
  padding-bottom: 15px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #85469b" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  color: #5b5656;
  letter-spacing: 1px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const ErrorText = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  color: red;
  font-size: 20px;
`;

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const SignOutButtonSpacer = styled.div`
  width: 4rem;
`;

const AccountPage = ({ firebase }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const displayMobile = windowWidth >= SCREEN.DESKTOPSIZE ? false : true;
  const displayMiniMobile = windowWidth >= SCREEN.TABLETSIZE ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <PageWrapper>
          <HeadingWrapper>
            <Heading>Account</Heading>
            <WelcomeWrapper>
              <HeadingWelcome>
                Welcome {authUser.username} - {authUser.email}
              </HeadingWelcome>
              <SignOutButtonSpacer />
              <StyledButton
                secondary
                fontSize="1.4rem"
                callback={() => firebase.doSignOut()}>
                Sign Out?
              </StyledButton>
            </WelcomeWrapper>
          </HeadingWrapper>
          <PageLayoutWrapper
            displayMiniMobile={displayMiniMobile}
            displayMobile={displayMobile}>
            <ComponentBorder>
              <ComponentWrapper>
                <SubHeadingTitle>Forgot your password?</SubHeadingTitle>
                <PasswordForgetForm />
                <hr />
                <SubHeadingTitle>Need a new password?</SubHeadingTitle>
                <PasswordChangeForm />
              </ComponentWrapper>
            </ComponentBorder>
            <ComponentBorder>
              <ComponentWrapper>
                <SubHeadingTitle>Manage your login methods</SubHeadingTitle>
                <LoginManagement authUser={authUser} />
              </ComponentWrapper>
            </ComponentBorder>
          </PageLayoutWrapper>
        </PageWrapper>
      )}
    </AuthUserContext.Consumer>
  );
};

const LoginManagementBase = ({ firebase }) => {
  const [activeSignInMethods, setActiveSignInMethods] = useState([]);
  const [error, setError] = useState(null);

  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    fetchSignInMethods();
  }, []);

  const fetchSignInMethods = () => {
    firebase.auth
      .fetchSignInMethodsForEmail(authUser.email)
      .then((activeSignInMethods) => {
        setActiveSignInMethods(activeSignInMethods);
        setError(null);
      });
  };

  const onSocialLoginLink = (provider) => {
    firebase.auth.currentUser
      .linkWithPopup(firebase[provider])
      .then(fetchSignInMethods)
      .catch((error) => setError(error));
  };

  const onDefaultLoginLink = (password) => {
    const credential = firebase.emailAuthProvider.credential(
      authUser.email,
      password
    );

    firebase.auth.currentUser
      .linkWithCredential(credential)
      .then(fetchSignInMethods)
      .catch((error) => {
        console.error(error);
        setError(error);
      });
  };

  const onUnlink = (providerId) => {
    firebase.auth.currentUser
      .unlink(providerId)
      .then(fetchSignInMethods)
      .catch((error) => setError(error));
  };

  return (
    <div>
      {/* Map Sign in Methods iterating through the list above, ternary logic below displays it
              properly and username/password or social with the proper options. */}
      {SIGN_IN_METHODS.map((signInMethod) => {
        const onlyOneLeft = activeSignInMethods.length === 1;
        const isEnabled = activeSignInMethods.includes(signInMethod.id);
        return (
          <SignInMethodElement key={signInMethod.id}>
            {signInMethod.id === "password" ? (
              <DefaultLoginToggle
                onlyOneLeft={onlyOneLeft}
                isEnabled={isEnabled}
                signInMethod={signInMethod}
                onLink={onDefaultLoginLink}
                onUnlink={onUnlink}
                error={error}
              />
            ) : (
              <SocialLoginToggle
                onlyOneLeft={onlyOneLeft}
                isEnabled={isEnabled}
                signInMethod={signInMethod}
                onLink={onSocialLoginLink}
                onUnlink={onUnlink}
              />
            )}
          </SignInMethodElement>
        );
      })}
    </div>
  );
};

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) => {
  var stringCutAfterDot = signInMethod.id.split(".")[0]; //Cut off the .com
  var sterilizedName =
    stringCutAfterDot[0].toUpperCase() + stringCutAfterDot.slice(1); //Capitalize the first letter
  return isEnabled ? (
    <StyledButton
      primary
      width="300px"
      fontSize="1.5rem"
      type="button"
      callback={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}>
      Deactivate {sterilizedName}
    </StyledButton>
  ) : (
    <StyledButton
      primary
      width="300px"
      fontSize="1.5rem"
      type="button"
      callback={() => onLink(signInMethod.provider)}>
      Link {sterilizedName}
    </StyledButton>
  );
};

const DefaultLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
  error,
}) => {
  var sterilizedName =
    signInMethod.id[0].toUpperCase() + signInMethod.id.slice(1); //Capitalize the first letter

  const onSubmit = () => {
    onLink(inputs.passwordOne);
  };

  const [hasFocusPW, setHasFocusPW] = useState(false);
  const [hasFocusPW2, setHasFocusPW2] = useState(false);

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit, {
    passwordOne: "",
    passwordTwo: "",
  });

  const setFocusPW = (hasFocusPW) => {
    if (!inputs.passwordOne) {
      setHasFocusPW(hasFocusPW);
    }
  };

  const setFocusPW2 = (hasFocusPW2) => {
    if (!inputs.passwordTwo) {
      setHasFocusPW2(hasFocusPW2);
    }
  };

  const isInvalid =
    inputs.passwordOne !== inputs.passwordTwo || inputs.passwordOne === "";

  return isEnabled ? (
    <StyledButton
      primary
      width="300px"
      fontSize="1.5rem"
      type="button"
      callback={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}>
      Deactivate {signInMethod.id}
    </StyledButton>
  ) : (
    <form onSubmit={handleSubmit}>
      <InputBox>
        <Input
          name="passwordOne"
          value={inputs.passwordOne}
          onChange={handleInputChange}
          type="password"
          onFocus={() => setFocusPW(true)}
          onBlur={() => setFocusPW(false)}
        />
        <InputLabel hasFocus={hasFocusPW}>New Password</InputLabel>
      </InputBox>
      <InputBox>
        <Input
          name="passwordTwo"
          value={inputs.passwordTwo}
          onChange={handleInputChange}
          type="password"
          onFocus={() => setFocusPW2(true)}
          onBlur={() => setFocusPW2(false)}
        />
        <InputLabel hasFocus={hasFocusPW2}>Confirm Password</InputLabel>
      </InputBox>
      <StyledButton
        primary
        width="300px"
        fontSize="1.5rem"
        disabled={isInvalid}
        type="submit">
        Use {sterilizedName}
      </StyledButton>
      {/* FIXME: The below error is not showing up, it renders the div but there is not text. */}
      {error && <ErrorText>{error.message}</ErrorText>}
    </form>
  );
};

const LoginManagement = withFirebase(LoginManagementBase);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
