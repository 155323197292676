import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import StyledButton from "./buttons";
import {
  Switch,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const Box = styled.div`
  ${(props) =>
    props.turnOff
      ? null
      : `
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 200px;
  left: 33.33%;
  transform: translate(-50% -50%);
  width: 600px;
  background-color: #f0efef;
  opacity: 0.9;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0 15px 25px #5d5b6a;
  border-radius: 10px;`};
`;

const MaterialInputLabel = styled(FormControlLabel)`
  margin-bottom: 10px;
  transform: translate(-5px, -20px);
  color: #5b5656;
`;

const RadioOptionLabel = styled(FormControlLabel)`
  color: #5b5656;
  ${({ highlight }) =>
    highlight &&
    css`
      color: #85469b;

      transition: 0.5s ease-in;
    `}
`;

const CustomForm = styled.form`
  width: 300px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -20px" : "top: 0")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: #85469b" : "color: #5b5656")};
  ${(props) => (props.hasFocus ? "font-size: 12px" : "font-size: 18px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const InputBox = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #5b5656;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #4d4646;
  outline: none;
  background: transparent;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnimatedForm = (props) => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [inputs, setInputs] = useState({});

  const makeInitialFocus = () => {
    let initialFocus = {};
    props.formInputs.forEach((value) => (initialFocus[value.name] = false));
    return initialFocus;
  };
  const [componentFocus, setComponentFocus] = useState(makeInitialFocus);

  const onSubmitForm = (event) => {
    event.preventDefault();
    props.submitFunction(inputs);
  };
  //submit function should take an object with [keys] that match the formInput.name

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  var inputsObject = {};
  var namesOfInputs = props.formInputs.map((formInput) => formInput.name);
  namesOfInputs.forEach((inputName) => (inputsObject[inputName] = ""));
  //example inputsObject = { email: "", password: ""}

  //formInputs should list the inputs needed in the form, and their types in an array of objects
  // formInputs = [ {name: , label: , type: ,} ]
  const setFocusOn = (keyName) => {
    setComponentFocus({ ...componentFocus, [keyName]: true });
  };

  const setFocusOff = (keyName) => {
    if (!inputs[keyName]) {
      setComponentFocus({ ...componentFocus, [keyName]: false });
    }
  };

  const onChangeSwitch = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.checked,
    }));
    setSwitchStatus(event.target.checked);
  };

  const onChangeRadio = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const StyledSwitch = withStyles({
    switchBase: {
      color: grey,
      fontSize: "18px",
      "&$checked": {
        color: "orange",
      },
      "&$checked + $track": {
        backgroundColor: "orange",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const StyledRadio = withStyles({
    root: {
      color: grey,
      fontSize: "18px",
      "&$checked": {
        color: "#85469b",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <Box turnOff={props.disableBox}>
      {props.title && <h2>{props.title}</h2>}
      <CustomForm onSubmit={onSubmitForm}>
        {props.formInputs.map((formInput) =>
          formInput.type === "text" || formInput.type === "password" ? (
            <InputBox key={formInput.name.concat("box")}>
              <Input
                key={formInput.name.concat("input")}
                value={inputs[formInput.name]}
                onChange={
                  //Pass in a custom onChange in as a prop, or in the formInputs
                  formInput.customOnChange
                    ? formInput.customOnChange
                    : props.customOnChange
                    ? props.customOnChange
                    : handleInputChange
                }
                name={formInput.name}
                type={formInput.type}
                onFocus={() => setFocusOn(formInput.name)}
                onBlur={() => setFocusOff(formInput.name)}
              />
              <InputLabel
                key={formInput.name.concat("label")}
                hasFocus={
                  // !componentFocus ? false : componentFocus[formInput.name]
                  componentFocus[formInput.name]
                }>
                {formInput.label}
              </InputLabel>
            </InputBox>
          ) : formInput.type === "switch" ? (
            <MaterialInputLabel
              label={formInput.label}
              control={
                <StyledSwitch
                  checked={inputs[formInput.name]}
                  onChange={
                    //Pass in a custom onChange in as a prop, or in the formInputs
                    formInput.customOnChange
                      ? formInput.customOnChange
                      : props.customOnChange
                      ? props.customOnChange
                      : onChangeSwitch
                  }
                  name={formInput.name}
                />
              }
              labelPlacement="start"
            />
          ) : formInput.type === "radio" ? (
            <FormControl
              key={formInput.name.concat("FormControl")}
              component="fieldset">
              <FormLabel
                key={formInput.name.concat("FormLabel")}
                component="legend">
                {formInput.label}
              </FormLabel>
              <RadioGroup
                aria-label={formInput.label}
                key={formInput.name.concat("RadioGroup")}
                value={inputs[formInput.name]}
                onChange={
                  //Pass in a custom onChange in as a prop, or in the formInputs
                  formInput.customOnChange
                    ? formInput.customOnChange
                    : props.customOnChange
                    ? props.customOnChange
                    : onChangeRadio
                }
                name={formInput.name}>
                {formInput.options.map((radioOption) => (
                  <RadioOptionLabel
                    key={formInput.name
                      .concat(radioOption.value)
                      .concat("radio")}
                    value={radioOption.value}
                    control={<StyledRadio />}
                    label={radioOption.label}
                    highlight={radioOption.value === inputs[formInput.name]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : null
        )}
        <ButtonWrapper>
          <StyledButton
            primary
            width={props.buttonStyle.width}
            fontSize={props.buttonStyle.size}
            type="submit">
            {props.submitButtonText}
          </StyledButton>
        </ButtonWrapper>
      </CustomForm>
    </Box>
  );
};

export default AnimatedForm;
