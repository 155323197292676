import React from "react";
import styled, { keyframes, css } from "styled-components";

import { fadeInUp } from "react-animations";

const fadeUpAnimation = keyframes`${fadeInUp}`;

const InitialStyledButton = styled.button`
  padding: 0.4rem 1.5rem;
  font-size: 1.8rem;
  font-family: "MontserratSemiBold";
  color: white;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #1e242b, black);
  border-radius: 0.6rem;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: pointer;

  ${({ primary }) =>
    primary &&
    css`
      font-size: ${({ fontSize }) => fontSize};

      &:hover {
        transition: 0.3s ease-in-out;
        background-image: linear-gradient(to right, #ee3189, #f36d21);
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      font-size: ${({ fontSize }) => fontSize};
      &:hover {
        transition: 0.3s ease-in-out;
        background-image: linear-gradient(to right, #469dd7, #70c8b8);
      }
    `}

${({ tertiary }) =>
    tertiary &&
    css`
      font-size: ${({ fontSize }) => fontSize};
      &:hover {
        transition: 0.3s ease-in-out;
        background-image: linear-gradient(180deg, #454545, black);
      }
    `}

    ${({ bright }) =>
    bright &&
    css`
      font-size: ${({ fontSize }) => fontSize};
      color: black;
      background-image: linear-gradient(to right, white, white);
      &:hover {
        color: white;

        background-image: linear-gradient(to right, #ee3189, #f36d21);
        transition: all 0.3s ease-in-out;
      }
    `}

    ${({ slideUp }) =>
    slideUp &&
    css`
      animation: 1s ${fadeUpAnimation};
    `}
`;
//#4f5354, #211f21
const StyledButton = ({
  primary,
  secondary,
  tertiary,
  bright,
  slideUp,
  fontSize,
  width,
  height,
  children,
  callback,
  disabled,
  type,
}) => {
  const runMe = () => {
    //Only run a callback function if there is one for the button
    if (callback) {
      const runIt = () => {
        callback();
      };
      runIt();
    } else {
      return;
    }
  };
  return (
    <InitialStyledButton
      disabled={disabled}
      type={type}
      onClick={() => runMe()}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      bright={bright}
      slideUp={slideUp}
      fontSize={fontSize}
      width={width}
      height={height}>
      {children}
    </InitialStyledButton>
  );
};

export default StyledButton;
