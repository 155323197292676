import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import styled from "@emotion/styled";
import TeamAssessment from "./teamAssessment";
import TeamGraphsComponent from "./teamGraphsComponent";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import DemographicSurvey from "../Assessments/DemographicSurvey/DemographicSurvey";
import DemographicSurveyIntro from "../Assessments/DemographicSurvey/DemographicSurveyIntro";

import StyledButton from "../../assests/buttons";
import BRImage from "../../assests/images/fullBackgroundImg.jpg";

const MainImage = styled.img`
  position: absolute;
  border-radius: 1rem;
  width: 96vw;
  height: 430px;
  object-fit: cover;
  object-position: 0% 80%;
  @media only screen and (max-width: 1450px) {
    height: 400px;
  }
  @media only screen and (max-width: 1150px) {
    height: 350px;
  }
  @media only screen and (max-width: 799px) {
    height: 300px;
  }
  @media only screen and (max-width: 531px) {
    height: 350px;
    object-position: 20% 80%;
  }

  z-index: -2;
  margin: 2vw;
`;

const OverlayStyled = styled.div`
  position: absolute;
  border-radius: 1rem;
  width: 96vw;
  height: 430px;
  @media only screen and (max-width: 1450px) {
    height: 400px;
  }
  @media only screen and (max-width: 1150px) {
    height: 350px;
  }
  @media only screen and (max-width: 799px) {
    height: 300px;
  }
  @media only screen and (max-width: 531px) {
    height: 350px;
  }
  z-index: -1;
  margin: 2vw;
  background-image: linear-gradient(120deg, #ee3189, #f36d21, #70c8b8, #469dd7);
  opacity: 0.8;
`;

const SpacerForAbsoluteOverlay = styled.div`
  border-radius: 1rem;
  width: 96vw;
  height: 430px;
  @media only screen and (max-width: 1450px) {
    height: 400px;
  }
  @media only screen and (max-width: 1150px) {
    height: 350px;
  }
  @media only screen and (max-width: 799px) {
    height: 300px;
  }
  @media only screen and (max-width: 531px) {
    height: 350px;
  }

  margin: 2vw;
`;

const TitleText = styled.p`
  font-family: Helvetica;
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 4px;
  color: white;
  margin-top: 50px;

  @media only screen and (max-width: 1150px) {
    margin-top: 45px;
    font-size: 2.8rem;
  }

  @media only screen and (max-width: 799px) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 670px) {
    margin-top: 25px;
    font-size: 1.8rem;
  }
`;

const CopyTextHeading = styled.p`
  font-family: Helvetica;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  color: white;
  margin-top: 25px;
  width: 75vw;
  @media only screen and (max-width: 1150px) {
    margin-top: 12px;
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 799px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 670px) {
    margin-top: 10px;
  }
`;

const TeamHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  background-color: white;
  align-self: center;
  margin: 20px;
  padding: 15px;
`;

const CallToActionComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4vw;
`;

const ComponentBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin-top: 60px;
  //Code to make a border gradient
  margin-left: 25vw;
  margin-right: 25vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
  @media only screen and (max-width: 1450px) {
    margin-left: 20vw;
    margin-right: 20vw;
  }
  @media only screen and (max-width: 1200px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }
  @media only screen and (max-width: 1050px) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
`;

const StyledSpinner = styled(Spinner)`
  padding: 40px;
  margin: 50px;
`;

const LoadingBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin-top: 60px;
  //Code to make a border gradient
  margin-left: 35vw;
  margin-right: 35vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
  @media only screen and (max-width: 1150px) {
    margin-left: 25vw;
    margin-right: 25vw;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 15vw;
    margin-right: 15vw;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;

const CopyTextWrapper = styled.p`
  font-size: 20px;
  width: 70vw;
`;

const CallToActionText = styled.p`
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
`;

const TypologyLinkBlackButton = styled.button`
  display: flex;
  width: 350px;
  height: 48px;
  justify-content: center;
  font-size: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;

  &:hover {
    background-color: #659f4f;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectTeamWrapperButton = styled(Dropdown.Toggle)`
  background-color: #7abf5f;
  font-size: 24px;
`;

const TeamOptionWrapper = styled(Dropdown.Item)`
  font-size: 24px;
`;

const DropDownWrapper = styled(Dropdown)`
  transform: translate(0, -20px);
`;

const TeamMembers = styled.h6`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const TeamMember = styled.p`
  font-size: 18px;
`;

const NoStyleLink = styled(Link)`
  &:focus,
  &:visited,
  &:link,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const LoadingText = styled.p`
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: grey;
`;

const CallToActionDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const MyTeam = (props) => {
  const [isPartOfTeam, setIsPartOfTeam] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [teamID, setTeamID] = useState(null);
  const [moreThanOneTeam, setMoreThanOneTeam] = useState(false);
  const [teamsObject, setTeamsObject] = useState({});
  const [teamArray, setTeamArray] = useState([]);
  const [allDataRetrieved, setAllDataRetrieved] = useState(false);
  const [isDirector, setIsDirector] = useState(false);
  const [userTookAssessment, setUserTookAssessment] = useState({
    typology: true,
    social: true,
  });
  const [
    hasTakenDysfunctionAssessmentObj,
    setHasTakenDysfunctionAssessmentObj,
  ] = useState({});
  const [
    userTookDysfunctionAssessmentForThisTeam,
    setUserTookDysfunctionAssessmentForThisTeam,
  ] = useState(false);
  const [dysfunctionResultsObject, setDysfunctionResultsObject] = useState({});
  const [takenDemographicSurvey, setTakenDemographicSurvey] = useState(false);
  const [permissionGiven, setPermissionGiven] = useState(false);
  const [showModalPermissions, setShowModalPermissions] = useState(true);

  // teams object contains an object of teams mapped to their IDs.
  /// An array of team ID's will be created, then a map function to creates selectable values in the jsx.
  // These will use the ID's to call the team object for the rest of the data.

  //Should execute whenever someone either joins team or is already on a team
  //TODO: Create HTTPS cloud function to receive the team ID, and lookup all member's data.  This way member data is not exposed.
  useEffect(() => {
    getUserData(props.authUser);
  }, []);

  const getUserData = (authUser) => {
    let userDoc = props.firebase.users().doc(authUser.uid);

    //Get User from Firebase, store their team (if they have one), this is a listener so updating any aspect on firebase should trigger
    userDoc.onSnapshot(
      (userDocSnapshot) => {
        setIsLoading(true);
        setMoreThanOneTeam(false);
        var teams = [];
        teams = userDocSnapshot.data().teams;
        if (userDocSnapshot.data().director) {
          setIsDirector(true);
        }
        //If member was removed from team or added, the teamdata needs to be re-retrieved
        if (!teams || teams.length === 0) {
          //TODO:
          setIsLoading(false);
        }
        if (teams) {
          setTeamArray(teams);
          //Check if there is more than one team to display conditional choice dropdown
          if (teams.length > 1) {
            setMoreThanOneTeam(true);
          }
          if (teams.length > 0) {
            setTeamID(teams[0]); //Temporarily set array to first postion to get first team to display
          }
        }
        setUserTookAssessment({
          social: userDocSnapshot.data().userTookAssessmentSocial,
          typology: userDocSnapshot.data().userTookAssessmentTypology,
        });

        setTakenDemographicSurvey(
          userDocSnapshot.data().userTookDemographicSurvey
        );

        setPermissionGiven(userDocSnapshot.data().userGavePermissions);

        //dysfunctionAssessments should be an object that has keys corresponding to teamID's and booleans for whether the
        // user has answered these questions.
        setHasTakenDysfunctionAssessmentObj(
          userDocSnapshot.data().dysfunctionAssessmentsTaken
        );
        setDysfunctionResultsObject(
          userDocSnapshot.data().dysfunctionResultsByTeamID
        );
        props.setProcessing(false);
      },
      (err) => {
        console.error(`Encountered error: ${err}`);
      }
    );
  };

  const getTeamData = useCallback((teamArray) => {
    //TODO: Add a retrieval for teams that the member Leads, and a button that determines whether to count the leader's data
    // with the team's.

    //var teamsObjectTemp = {};
    if (teamArray.length !== 0) {
      teamArray.forEach(async (teamDocID) => {
        //Use the user's team ID (if they are on a team) to retrieve their team data
        if (teamDocID) {
          //Temporarily stores all team/user/assessment data
          let teamDataObj = {},
            memberNames = [];
          //Need to make this a listener, since teams can be altered in many ways
          let teamDoc = props.firebase.teams().doc(teamDocID);
          teamDoc.onSnapshot((doc) => {
            setAllDataRetrieved(false);
            if (doc.exists) {
              let teamData = doc.data();
              teamDataObj.CompanyName = teamData.companyName;
              teamDataObj.DepartmentName = teamData.department;
              teamDataObj.LeaderName = teamData.teamLead;
              teamDataObj.JoinCodeName = teamData.teamCode;
              teamDataObj.Name = teamData.teamName;
              teamDataObj.TypologyResults = teamData.typologyResults;
              teamDataObj.SocialResults = teamData.socialResults;
            }

            props.firebase
              .teams()
              .doc(teamDocID)
              .collection(`members`)
              .get()
              .then((members) => {
                members.forEach((memberDoc) =>
                  memberNames.push(memberDoc.data().member)
                );
                teamDataObj.members = memberNames;
              });
            //Get teams already retrieved as an object
            var teamsObjectTemp = teamsObject;
            //Add the team that was just built to that object
            teamsObjectTemp[teamDocID] = teamDataObj;
            //Add the object with the new team data appended to the state variable
            setTeamsObject(teamsObjectTemp);
            if (Object.keys(teamsObject).length === teamArray.length) {
              setAllDataRetrieved(true);
            }
          });
        }
      });
    }
    console.log(teamsObject);
  }, []);

  useEffect(() => {
    if (teamArray) {
      getTeamData(teamArray);
    }
  }, [teamArray, getTeamData, teamsObject]);

  useEffect(() => {
    //Check how many objects are in the state variable (These are fetched teams)
    var keyCount = Object.keys(teamsObject).length;
    //Check how many teams are in the array of teams
    var teamCount = teamArray.length;
    if (teamsObject && teamID && keyCount === teamCount) {
      if (teamsObject[teamID].Name) {
        props.setResultsMap(teamsObject[teamID].TypologyResults);
        props.setResultsMapSocial(teamsObject[teamID].SocialResults);
        setIsPartOfTeam(true);
        if (
          hasTakenDysfunctionAssessmentObj &&
          hasTakenDysfunctionAssessmentObj[teamID] !== undefined
        ) {
          setUserTookDysfunctionAssessmentForThisTeam(
            hasTakenDysfunctionAssessmentObj[teamID]
          );
        } else {
          setUserTookDysfunctionAssessmentForThisTeam(false);
        }
        //TODO:
        setIsLoading(false);
      }
    }
  }, [teamID, allDataRetrieved, teamsObject, teamArray.length, props]);

  const storeTeamDysfunctionResults = async (answerResponses) => {
    console.log(`Storing results for: ${answerResponses}`);
    var dysfunctionAssessmentsTaken;
    if (
      hasTakenDysfunctionAssessmentObj &&
      hasTakenDysfunctionAssessmentObj[teamID] !== undefined
    ) {
      dysfunctionAssessmentsTaken = hasTakenDysfunctionAssessmentObj;
    } else {
      dysfunctionAssessmentsTaken = {};
    }
    var dysfunctionResultsByTeamIDTemp = dysfunctionResultsObject || {};
    dysfunctionResultsByTeamIDTemp[teamID] = answerResponses;
    if (dysfunctionAssessmentsTaken) {
      dysfunctionAssessmentsTaken[teamID] = true;
    } else {
    }

    try {
      await props.firebase.users().doc(props.authUser.uid).update({
        dysfunctionAssessmentsTaken: dysfunctionAssessmentsTaken,
        dysfunctionResultsByTeamID: dysfunctionResultsByTeamIDTemp,
      });
    } catch (err) {
      console.error(
        `An error encountered when storing dysfunction results: ${err}`
      );
    }
  };

  const renderNoTeam = () => {
    return (
      <PageWrapper>
        <OverlayStyled />
        <MainImage src={BRImage} alt="Image of a hip meeting" />

        <SpacerForAbsoluteOverlay>
          <TeamHeadingWrapper>
            <TitleText>TEAMS</TitleText>
            <CopyTextHeading>
              While individual assessments are essential to growth, the
              interactions between these individuals provides the richness to
              compiled team data. Create and join teams here to get the most out
              of our service.
            </CopyTextHeading>
          </TeamHeadingWrapper>
        </SpacerForAbsoluteOverlay>
        <CallToActionDiv>
          <CallToActionComponentWrapper>
            {isDirector && (
              <CallToActionText>
                You have created a team, but you still must join that team with
                the code if you wish your data to be counted with your team's.
              </CallToActionText>
            )}
            {!isDirector && (
              <CallToActionText>
                Please join a team here if you have already recieved a "team
                password" from your team leader
              </CallToActionText>
            )}
            <StyledButton
              primary
              fontSize="1.5rem"
              callback={() => props.openJoin()}>
              Join a Team
            </StyledButton>
          </CallToActionComponentWrapper>
          <CallToActionComponentWrapper>
            <CallToActionText>
              Create a team by clicking here, then give the "team password" you
              created to other members so they can join it
            </CallToActionText>
            <StyledButton
              primary
              fontSize="1.5rem"
              callback={() => props.openCreate()}>
              Create a Team
            </StyledButton>
          </CallToActionComponentWrapper>
        </CallToActionDiv>
      </PageWrapper>
    );
  };

  const renderTeamDysfunctionsAssessment = () => {
    return (
      <div>
        <TeamAssessment
          setTeamDysfunctionResults={storeTeamDysfunctionResults}
          teamName={teamsObject[teamID].Name}
        />
      </div>
    );
  };

  const renderTeam = () => {
    return (
      <div>
        <ComponentBorder>
          <ComponentWrapper>
            <h1>My Team</h1>
            <h4>
              <strong>Company: </strong>
              {teamsObject[teamID].CompanyName} <strong>Department: </strong>
              {teamsObject[teamID].DepartmentName}
            </h4>
            <h4>
              <strong>Team Name: </strong>
              {teamsObject[teamID].Name}
            </h4>
            <h4>
              <strong>Your Team's Director: </strong>
              {teamsObject[teamID].LeaderName}
            </h4>
            <p>
              Note: please have other members join with the code:{" "}
              <strong>{teamsObject[teamID].JoinCodeName}</strong>
            </p>
          </ComponentWrapper>
        </ComponentBorder>

        {props.resultsMap || props.resultsMapSocial ? (
          <TeamGraphsComponent
            resultsMap={props.resultsMap}
            resultsMapSocial={props.resultsMapSocial}
            teamName={teamsObject[teamID].Name}
          />
        ) : (
          <ComponentWrapper>
            <CopyTextWrapper>
              We cannot display your team's results yet, at least 2 members of
              your team must take an assessment. According to our records, you{" "}
              {userTookAssessment.typology ? "have" : "have not"} taken the
              Typology Assessment, and{" "}
              {userTookAssessment.social ? "have" : "have not"} taken the Social
              Styles Assessment.
            </CopyTextWrapper>
          </ComponentWrapper>
        )}
        {!userTookAssessment.typology && (
          <ComponentWrapper>
            <CopyTextWrapper>
              Please take the Typology Assessment. Until you do this, we cannot
              calculate your team's results accurately.
            </CopyTextWrapper>
            <NoStyleLink
              to={{
                pathname: `${ROUTES.TYPOLOGY}`,
              }}>
              <TypologyLinkBlackButton>
                Take Typology Assessment
              </TypologyLinkBlackButton>
            </NoStyleLink>
          </ComponentWrapper>
        )}
        {!userTookAssessment.social && (
          <ComponentWrapper>
            <CopyTextWrapper>
              Please take the Social Styles Assessment. Until you do this, we
              cannot calculate your team's results accurately.
            </CopyTextWrapper>
            <NoStyleLink
              to={{
                pathname: `${ROUTES.SOCIALSTYLES}`,
              }}>
              <TypologyLinkBlackButton>
                Take Social Styles Assessment
              </TypologyLinkBlackButton>
            </NoStyleLink>
          </ComponentWrapper>
        )}
        <hr />
        <TeamMembers>
          Your team members are:{" "}
          {teamsObject[teamID] !== "undefined" &&
            teamsObject[teamID].members &&
            teamsObject[teamID].members.map((name, index) => (
              <TeamMember key={index}>{name}</TeamMember>
            ))}
        </TeamMembers>
        <hr />
      </div>
    );
  };

  const selectTeam = useCallback(
    (DocID) => {
      setTeamID(DocID);
    },
    [teamsObject]
  );

  const storeUserGavePermissions = () => {
    props.firebase
      .users()
      .doc(props.authUser.uid)
      .update({ userGavePermissions: true });
    setShowModalPermissions(false);
  };

  const renderDemographicSurvey = () => {
    const storeDemographicResults = (demographicResults) => {
      props.firebase.users().doc(props.authUser.uid).update({
        userTookDemographicSurvey: true,
        userDemographics: demographicResults,
      });
    };

    return (
      <div>
        <DemographicSurvey storeDemographicResults={storeDemographicResults} />
      </div>
    );
  };

  return (
    <PageWrapper>
      {!isLoading && !permissionGiven && (
        <DemographicSurveyIntro
          showModalPermissions={showModalPermissions}
          setShowModalPermissions={setShowModalPermissions}
          storeUserGavePermissions={storeUserGavePermissions}
        />
      )}
      {!isLoading && moreThanOneTeam && (
        <DropDownWrapper title="Drop large">
          <SelectTeamWrapperButton variant="success" id="dropdown-basic">
            Switch Teams
          </SelectTeamWrapperButton>

          <Dropdown.Menu>
            {teamArray.map((teamDoc) => (
              <TeamOptionWrapper
                key={teamsObject[teamDoc].ID}
                as="button"
                onClick={() => selectTeam(teamDoc)}>
                {teamsObject[teamDoc].Name}
              </TeamOptionWrapper>
            ))}
          </Dropdown.Menu>
        </DropDownWrapper>
      )}

      {!isLoading &&
        (!takenDemographicSurvey
          ? renderDemographicSurvey()
          : isPartOfTeam
          ? userTookDysfunctionAssessmentForThisTeam
            ? renderTeam()
            : renderTeamDysfunctionsAssessment()
          : renderNoTeam())}

      {isLoading && (
        <LoadingBorder>
          <LoadingWrapper>
            <LoadingText>Loading data...</LoadingText>
            <StyledSpinner animation="border" role="status" />
          </LoadingWrapper>
        </LoadingBorder>
      )}
    </PageWrapper>
  );
};

export default MyTeam;
