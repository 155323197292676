import React, { useContext, useState, useEffect } from "react";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../../Session";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import Spinner from "react-bootstrap/Spinner";
import styled from "@emotion/styled";
import Assessment from "./AssessmentComponents/assessment";
import ResultSocial from "./AssessmentComponents/Results";
import PurchaseAndCode from "../PurchaseCodes/PurchaseAndCode";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 200px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1``;

const BlurbBox = styled.div`
  margin-top: 35px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  /* border: 2px solid #7abf5f; */

  //Code to make a border gradient
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const CopyTextWrapper = styled.p`
  text-align: center;
  font-family: "MonsterratReg";
  margin: 8px;
  font-size: 26px;
  width: 55vw;
  @media only screen and (max-width: 1050px) {
    width: 70vw;
    font-size: 20px;
  }
`;

const SocialStylesPage = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const [clientTookAssessment, setClientTookAssessment] = useState(false);
  const [clientHasAccess, setClientHasAccess] = useState(false);
  const [assessmentResult, setAssessmentResult] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [viewingAccessResults, setViewingAccessResults] = useState(false);

  const getUserData = async (authUser) => {
    let userAccessDoc = await firebase
      .users()
      .doc(authUser.uid)
      .collection(`access`)
      .doc(`hasAccessSocial`);
    userAccessDoc.onSnapshot(
      (doc) => {
        if (doc.exists) {
          var accessUser = doc.data().access;
          let hasNoResultsViewerAccess = doc.data().noResultsAccess;
          setClientHasAccess(accessUser);
          if (
            hasNoResultsViewerAccess === false &&
            hasNoResultsViewerAccess !== undefined
          ) {
            setViewingAccessResults(true);
          }
        }
      },
      (err) => {
        console.error(`Encountered error in user access retrieval: ${err}`);
      }
    );

    let userDataDoc = await firebase.users().doc(authUser.uid);
    userDataDoc.onSnapshot(
      (doc) => {
        var resultsUser = doc.data().assessmentResultSocial;
        var tookAssessmentUser = doc.data().userTookAssessmentSocial;
        setClientTookAssessment(tookAssessmentUser);
        setAssessmentResult(resultsUser);
        setIsLoadingData(false);
      },
      (err) => {
        console.error(`Encountered error in user results retrieval: ${err}`);
      }
    );
  };

  useEffect(() => {
    setIsLoadingData(true);
    if (authUser) {
      getUserData(authUser);
    }
  }, [authUser, clientHasAccess]);

  return (
    <PageWrapper>
      <Title>Social Styles</Title>
      {isLoadingData && (
        <SpinnerWrapper>
          <Spinner animation="border" role="status" />
        </SpinnerWrapper>
      )}
      {!isLoadingData && clientHasAccess ? (
        clientTookAssessment ? (
          assessmentResult !== undefined &&
          (viewingAccessResults ? (
            <ResultSocial assessmentResult={assessmentResult} />
          ) : (
            <BlurbBox>
              <CopyTextWrapper>
                Thank you so much for taking our Social Styles assessment. Your
                results have been sent to the coach who purchased your access
                code.
              </CopyTextWrapper>
            </BlurbBox>
          ))
        ) : (
          <Assessment />
        )
      ) : (
        <PurchaseAndCode
          purpose="socialStylesAccess"
          firebase={firebase}
          authUser={authUser}
          chargeCents={1500}
        />
      )}
    </PageWrapper>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition)
)(SocialStylesPage);
