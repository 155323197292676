import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../Firebase";
import AssessmentBase from "./assessmentBase";
import typologyAssessmentQuestions from "./assessmentQuestions";
import styled from "styled-components";

import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner = styled(Spinner)`
  color: black;
  margin-right: 3vw;
  padding-left: 5vw;
  padding-top: 5vw;
`;

const LoadingBorder = styled.div`
  padding: 3px;
  //Code to make a border gradient
  margin: 2vw;
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5vw;
`;

const LoadingSpacer = styled.div`
  height: 30vh;
`;

const AssessmentDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Assessment = (props) => {
  const [question, setQuestion] = useState({
    questionText: "",
    questionID: "",
    answerChoices: [],
  });
  //Question number is the source of truth, -1 means it hasn't loaded yet
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(undefined);
  const [answerResponses, setAnswerResponses] = useState([]);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [assessmentComplete, setAssessmentComplete] = useState(false);
  const [assessmentLength, setAssessmentLength] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  //Source of truth to tie together components is the currentQuestionNumber
  //Run the following on initial load to shuffle questions and determine assessment length
  useEffect(() => {
    // if is here to make sure we don't accidentally run when this has already been done.
    if (!currentQuestionNumber) {
      //Shuffle the choices within each question's array of available answers
      //This code is not working:
      // const shuffledAnswersInEachQuestion = teamAssessmentQuestions.map(
      //   (ques) => shuffleArray(ques)
      // );
      //Shuffle the questions within the assessment as a whole
      const shuffledQuestionsArr = shuffleArray(typologyAssessmentQuestions);
      //Set the array of questions to the state variable and move the currentQuestionNumber to Zero
      setShuffledQuestions(shuffledQuestionsArr);
      setAssessmentLength(shuffledQuestionsArr.length);
      setCurrentQuestionNumber(0);
    }
  }, [currentQuestionNumber]);

  const storeAnswerAndIncrement = (answerToStore) => {
    let initialAnswerResponses = [...answerResponses];
    initialAnswerResponses[currentQuestionNumber] = answerToStore;
    setAnswerResponses(initialAnswerResponses);
    setCurrentQuestionNumber(currentQuestionNumber + 1);
  };

  useEffect(() => {
    //This prevents the setQuestion useEffect from running if the first useEffect has not yet run
    if (typeof currentQuestionNumber === "number") {
      if (
        //Check if array is empty (first useEffect hasn't run) yet and we are not on the last question of the assessment
        currentQuestionNumber < assessmentLength
      ) {
        setQuestion({
          questionText: shuffledQuestions[currentQuestionNumber].questionText,
          questionID: shuffledQuestions[currentQuestionNumber].questionID,
          answerChoices: shuffledQuestions[currentQuestionNumber].answers,
        });
      }
      if (
        //This is +1 to account for the 0 starting posistion of arrays
        currentQuestionNumber === assessmentLength
      ) {
        setAssessmentComplete(true);
        props.setAssessmentResults(answerResponses);
      }
      setIsLoading(false);
      console.log(`Answers so far are:`);
      console.log(answerResponses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionNumber]);

  const shuffleArray = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };
  return (
    <AssessmentDiv>
      {assessmentComplete && (
        <LoadingBorder>
          <LoadingWrapper>
            <LoadingSpinner animation="border" role="status" />
            <h4>Registering results, please wait...</h4>
          </LoadingWrapper>
        </LoadingBorder>
      )}
      {!assessmentComplete && !isLoading && (
        <AssessmentBase
          questionData={question}
          currentQuestionNumber={currentQuestionNumber}
          setCurrentQuestionNumber={setCurrentQuestionNumber}
          storeAnswerAndIncrement={storeAnswerAndIncrement}
          totalQuestions={assessmentLength}
        />
      )}
    </AssessmentDiv>
  );
};

export default withFirebase(Assessment);
