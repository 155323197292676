import AssessmentBase from "./assessmentBase";
import React, { useState, useEffect, useContext } from "react";
import { AuthUserContext } from "../../../Session";
import { withFirebase } from "../../../Firebase";
import assessmentQuestions from "./assessmentQuestions";
import "./assessment.css";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 300px;
  flex-direction: column;
  align-items: center;
`;

const Assessment = ({ firebase }) => {
  //State Variables
  const [counter, setCounter] = useState(1); //Using zero because thd array begins at zero, may need to be 1
  const [questionId, setQuestionId] = useState(1);
  const [question, setQuestion] = useState("");
  const [answerOptions, setAnswerOptions] = useState([]);
  const [answer, setAnswer] = useState("");
  const [answersCount, setAnswersCount] = useState({});
  const [result, setResult] = useState({});
  const [resultNums, setResultNums] = useState([]);
  const [
    shuffledAssessmentQuestions,
    setShuffledAssessmentQuestions,
  ] = useState([]);

  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    shuffleAndLoadFirst();
  }, []); //May not work, but added this to trigger as this is where it is not working.

  const shuffleAndLoadFirst = () => {
    const shuffledAssessmentQuestions = shuffleArray(assessmentQuestions);

    const shuffledAnswerOptions = assessmentQuestions.map((question) =>
      shuffleArray(question.answers)
    );

    setShuffledAssessmentQuestions(shuffledAssessmentQuestions);
    console.log(shuffledAssessmentQuestions);

    //TODO: add a shuffle to shuffle the questions in their entirity

    setQuestion(shuffledAssessmentQuestions[0].question);
    setAnswerOptions(shuffledAnswerOptions[0]);
  };

  const shuffleArray = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  useEffect(() => {
    if (answer) {
      //This is here since answer is blanked in the setNextQuestion method
      setAnswersCount({
        ...answersCount,
        [answer]: (answersCount[answer] || 0) + 1,
      });
      console.log(`The answer in setUserAnswer() is ${answer}`);
    }
  }, [answer]);

  useEffect(() => {
    //Executes every time the AnswersCount is updates, use this to do final tabulation of results
    console.log(`The AnswersCount so far is ${Object.entries(answersCount)}`);

    if (questionId === assessmentQuestions.length) {
      setTimeout(() => setResults(getResults(), 300));
    }
  }, [answersCount]);

  //Likely to be problems with the following method
  const setUserAnswer = (answer) => {
    setAnswer(answer);
  };

  const setNextQuestion = () => {
    setCounter(counter + 1);
    setQuestionId(questionId + 1);
    setQuestion(shuffledAssessmentQuestions[counter].question);
    setAnswerOptions(assessmentQuestions[counter].answers);
    setAnswer(""); //reset answer as this a new question
  };

  /**This triggers a chain reaction upon an answer being selected in the quiz, I may need to
   * use "useEffect" here.  The chain reaction is: <AssessmentBase> sends onAnswerSelected -> handleAnswerSelected ->
   * setNextQuestion if there are more questions, setResults if there are no more questions.
   *
   * For some reason this is currently executing when the page loads, so answerCoutValues is not defined (which of course it cannot
   * be unil the user takes the assessment).
   **/

  const handleAnswerSelected = (event) => {
    //console.log("Handle Answer selected is called for ", questionId);
    setUserAnswer(event.currentTarget.value);
    if (questionId < assessmentQuestions.length) {
      setTimeout(() => setNextQuestion(), 300);
    }
  };

  const getResults = () => {
    let resultsMapMath = {};

    console.log(
      `Within get results the final answers count is: ${answersCount}`
    );

    //FIXME: These are key value pairs, this can be simplified to simply need the key to retrieve a number

    if (answersCount.Entertainer) {
      resultsMapMath = { Entertainer: answersCount.Entertainer / counter };
      console.log(
        `The fraction of entertainer is: ${resultsMapMath.Entertainer}`
      );
    } else {
      resultsMapMath = { Entertainer: 0 };
    }

    if (answersCount.Significance) {
      resultsMapMath = {
        ...resultsMapMath,
        Significance: answersCount.Significance / counter,
      };
      console.log(
        `The fraction of significance is: ${resultsMapMath.Significance}`
      );
    } else {
      resultsMapMath = { ...resultsMapMath, Significance: 0 };
    }

    if (answersCount.Sensibility) {
      resultsMapMath = {
        ...resultsMapMath,
        Sensibility: answersCount.Sensibility / counter,
      };
      console.log(
        `The fraction of sensibility is: ${resultsMapMath.Sensibility}`
      );
    } else {
      resultsMapMath = { ...resultsMapMath, Sensibility: 0 };
    }

    if (answersCount.Altruistic) {
      resultsMapMath = {
        ...resultsMapMath,
        Altruistic: answersCount.Altruistic / counter,
      };
      console.log(
        `The fraction of altruistic is: ${resultsMapMath.Altruistic}`
      );
    } else {
      resultsMapMath = { ...resultsMapMath, Altruistic: 0 };
    }

    let resultsPercenage = {};

    for (let [key, value] of Object.entries(resultsMapMath)) {
      console.log(`${key}: ${value}`);
      resultsPercenage[key] = Math.round(value * 100);
    }

    console.log(`In percentage form: ${Object.entries(resultsPercenage)}`);

    return resultsPercenage;
  };

  const setResults = (resultTally) => {
    console.log("Set Result has triggered");
    setResult(resultTally);
    console.log("Results are: ", resultTally);
  };

  const onCreateResult = async (authUser) => {
    try {
      await firebase.users().doc(authUser.uid).update({
        assessmentResultSocial: result,
        userTookAssessmentSocial: true,
      });
      const userDocSnap = await firebase.users().doc(authUser.uid).get();
      const teamsMemberIsOn = userDocSnap.data().teams;
      teamsMemberIsOn.forEach(
        async (teamID) =>
          await firebase
            .teams()
            .doc(teamID)
            .collection(`members`)
            .doc(authUser.uid)
            .update({ tookSocial: true })
      );
    } catch (err) {
      console.log("Error saving docs to Users Db ", err);
    }
  };

  const renderAssessment = () => {
    return (
      <div>
        <div>
          <AssessmentBase
            answer={answer}
            answerOptions={answerOptions}
            questionId={questionId}
            question={question}
            questionTotal={assessmentQuestions.length}
            onAnswerSelected={(event) => handleAnswerSelected(event)} //written as is
          />
        </div>
      </div>
    );
  };

  const renderResult = () => {
    //Show loading results while they are registered with FireBase
    onCreateResult(authUser);
    return (
      <SpinnerWrapper>
        <h2>Loading Results</h2>
        <Spinner animation="border" role="status" />
      </SpinnerWrapper>
    );
  };

  return (
    <div>
      {(Object.keys(result).length === 0) > 0
        ? renderAssessment()
        : renderResult()}
    </div>
  );
};

export default withFirebase(Assessment);
