import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  pulse,
  fadeInLeftBig,
  fadeOutLeftBig,
  slideOutRight,
} from "react-animations";

const pulseAnimation = keyframes`${pulse}`;
const fadeInLeftAnimation = keyframes`${fadeInLeftBig}`;
const fadeOutLeftAnimation = keyframes`${fadeOutLeftBig}`;
const slideOutRightAnimation = keyframes`${slideOutRight}`;

const Answers = styled.ul`
  list-style: none;
`;

const BlackButton = styled.button`
  display: flex;
  width: 190px;
  height: 38px;
  justify-content: center;
  font-size: 20px;
  background-color: #2b2b2b;
  color: white;
  border-radius: 4px;
  font-family: roboto-bold, sans-serif;
  margin-left: 50px;

  &:hover {
    background-color: red;
    transition: all 0.3s ease-in-out 0s;
  }

  &:active {
    outline: 5px solid grey;
    background-color: grey;
  }
  &:focus {
    outline: none;
  }
`;

const AnswerOption = styled.li`
  font-size: 18px;

  &:hover {
    animation: 0.5s ${pulseAnimation};
    font-weight: 900;
  }
`;

const RadioInputAnswerChoice = styled.input`
  visibility: hidden;
`;

const AnswerChoiceContainer = styled.label`
  display: flex;
  flex-direction: row;
  padding-top: 8px;

  ${(props) => (props.checked ? "border-top: solid 2px #8ABC6A" : "")}
  ${(props) => (props.checked ? "border-bottom: solid 2px #8ABC6A" : "")}
  ${(props) => (props.checked ? "font-weight: 900;" : "")}
  animation: 0.5s ${(props) =>
    props.selected ? slideOutRightAnimation : fadeOutLeftAnimation};
`;

const AnswerChoiceText = styled.p`
  font-size: 20px;
`;

const AssessmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  width: 70vw;
  @media (max-width: 1200px) {
    width: 80vw;
  }
  @media (max-width: 500px) {
    width: 100vw;
  }

  animation: 0.5s
    ${(props) =>
      props.animationState === "comingIn"
        ? fadeInLeftAnimation
        : fadeOutLeftAnimation};
`;

const QuestionText = styled.h3``;

const QuestionNumberText = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 18px;
`;

const TeamAssessmentBase = (props) => {
  const [timeouts, setTimeouts] = useState(undefined);
  const [animationTimeouts, setAnimationTimeouts] = useState(undefined);
  const [currentCheckedAnswer, setCurrentCheckedAnswer] = useState(undefined);
  const [animationState, setAnimationState] = useState("comingIn");
  const [animateAsSelected, setAnimateAsSelected] = useState(undefined);

  //onChange fires this event every time, newer clicks override by clearing the timeouts
  const onAnswerSelected = (event) => {
    const selectedAnswer = event.target.value;
    //clear the timeouts from previous instances of onAnswerSelected, if it has already been clicked but not yet submitted via timeout
    if (timeouts) {
      console.log(`Clearning a previous timeout function`);
      clearTimeout(timeouts);
      clearTimeout(animationTimeouts);
    }
    //Set the state variable for the selected answer, so the UI can show that it has been selected
    setCurrentCheckedAnswer(selectedAnswer);

    //Create and store a timeout, this will store the question and increment it to the next question using the parent component
    const timeoutNewQuestion = setTimeout(() => {
      //Set states for animations
      setAnimationState("goingOut");
      setAnimateAsSelected(selectedAnswer);
      //StoreValue & increment currentQuestionNumber
      props.storeAnswerAndIncrement(selectedAnswer);
      setCurrentCheckedAnswer(undefined);
      setTimeouts(undefined);
    }, 1000);

    //Set timeout for the animation, this is 0.5s longer than the other to give the 0.5s animation time to finish
    const timeoutAnimation = setTimeout(() => {
      setAnimationState("comingIn");
      setAnimationTimeouts(undefined);
    }, 1500);

    setTimeouts(timeoutNewQuestion);
    setAnimationTimeouts(timeoutAnimation);
  };

  const onBackAQuestion = () => {
    if (props.currentQuestionNumber !== 0)
      props.setCurrentQuestionNumber(props.currentQuestionNumber - 1);
  };

  return (
    <AssessmentWrapper
      key={props.currentQuestionNumber}
      animationState={animationState}>
      <QuestionNumberText>
        {/* +1 here is to account for zero array postion being first */}
        Question {props.currentQuestionNumber + 1}/{props.totalQuestions}
      </QuestionNumberText>
      {/* Using the key here to force react to recognize a new question and then trigger an animation */}
      <QuestionText>{props.questionData.questionText}</QuestionText>
      <Answers>
        {props.questionData.answerChoices.map((ans) => (
          <AnswerOption key={ans.answerChoiceId}>
            <AnswerChoiceContainer
              key={ans.answerChoiceId}
              checked={ans.answerChoiceId === currentCheckedAnswer}
              selected={ans.answerChoiceId === animateAsSelected}>
              <RadioInputAnswerChoice
                type="radio"
                name="RadioCustomAnswers"
                value={ans.answerChoiceId}
                key={ans.answerChoiceId}
                onChange={onAnswerSelected}
              />
              <AnswerChoiceText>{ans.content}</AnswerChoiceText>
            </AnswerChoiceContainer>
          </AnswerOption>
        ))}
      </Answers>
      <BlackButton
        onClick={onBackAQuestion}
        disabled={props.currentQuestionNumber === 0}>
        Back One Question
      </BlackButton>
    </AssessmentWrapper>
  );
};

export default TeamAssessmentBase;
