import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { AuthUserContext } from "../Session";
import styled from "styled-components";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import Burger from "./burger";
import MobileMenuAuth from "./mobileMenuAuth";

import LogoWithNameSVG from "../../assests/logos/typologylogoheader_Header_Logo.svg";
import LogoNameHorizontal from "./Logo_Color.svg";

const MobileNavWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white; */
`;

const DesktopNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  @media only screen and (max-width: 1050px) {
    height: 65px;
  }
  /* position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2; */
`;

const DesktopLogo = styled.img`
  height: 55px;
  @media only screen and (max-width: 1050px) {
    height: 45px;
  }
  @media only screen and (max-width: 850px) {
    height: 35px;
  }
  z-index: 3;
`;

const MobileLogo = styled.img`
  /* position: fixed; */
  position: absolute;
  top: 22.5px;
  height: 35px;
  left: 22px;
  @media only screen and (max-width: 550px) {
    height: 25px;
    top: 27.5px;
  }
  /* z-index: 3; */
`;

const TopBar = styled.div`
  /* position: fixed; */
  height: 80px;
  background: white;
`;

const DesktopStyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "MontserratSemiBold", "Helvetica Neue";
  color: black;
  font-size: 25px;
  padding-left: 10px;
  padding-right: 15px;
  @media only screen and (max-width: 1050px) {
    font-size: 17.5px;
  }
  &:after {
    content: "";
    height: 2px;
    background-image: linear-gradient(
      to right,
      #ee3189,
      #f36d21,
      #70c8b8,
      #469dd7
    );
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  &.active:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  &:hover {
    text-decoration: none;
    color: #1d242c;
  }
`;

const DesktopNavLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const DesktopNav = (props) => {
  const auth = props.authPassed;

  const DesktopAuth = () => {
    return (
      <React.Fragment>
        <Link to={ROUTES.LANDING}>
          <DesktopLogo src={LogoWithNameSVG} />
        </Link>
        <DesktopNavLinksWrapper>
          {auth.roles[ROLES.ADMIN] === "ADMIN" && (
            <DesktopStyledNavLink to={ROUTES.ADMIN}>Admin</DesktopStyledNavLink>
          )}
          <DesktopStyledNavLink to={ROUTES.ASSESSMENTS}>
            Assessments
          </DesktopStyledNavLink>
          <DesktopStyledNavLink to={ROUTES.TEAM}>Team</DesktopStyledNavLink>
          {auth.coach && (
            <DesktopStyledNavLink to={ROUTES.COACHES}>
              Coaching
            </DesktopStyledNavLink>
          )}
          <DesktopStyledNavLink to={ROUTES.ACCOUNT}>
            Account
          </DesktopStyledNavLink>
        </DesktopNavLinksWrapper>
      </React.Fragment>
    );
  };

  const DesktopNotAuth = () => {
    return (
      <React.Fragment>
        <Link to={ROUTES.LANDING}>
          <DesktopLogo src={LogoWithNameSVG} alt="team typology logo" />
        </Link>
        <DesktopNavLinksWrapper>
          <DesktopStyledNavLink to={ROUTES.SIGN_UP}>
            Sign Up
          </DesktopStyledNavLink>
          <DesktopStyledNavLink to={ROUTES.SIGN_IN}>Login</DesktopStyledNavLink>
        </DesktopNavLinksWrapper>
      </React.Fragment>
    );
  };

  return (
    <DesktopNavWrapper>
      {auth ? <DesktopAuth /> : <DesktopNotAuth />}
    </DesktopNavWrapper>
  );
};

const MobileNav = ({ authPassed }) => {
  const auth = authPassed;

  const MobileAuth = () => {
    const [open, setOpen] = useState(false);
    return (
      <React.Fragment>
        <Burger open={open} setOpen={setOpen} />
        <MobileMenuAuth auth={auth} open={open} setOpen={setOpen} />
        <TopBar />
      </React.Fragment>
    );
  };

  const MobileNotAuth = () => {
    return (
      <DesktopNavLinksWrapper>
        <DesktopStyledNavLink to={ROUTES.SIGN_UP}>Sign Up</DesktopStyledNavLink>
        <DesktopStyledNavLink to={ROUTES.SIGN_IN}>Login</DesktopStyledNavLink>
      </DesktopNavLinksWrapper>
    );
  };

  return (
    <React.Fragment>
      <Link to={ROUTES.LANDING}>
        <MobileLogo src={LogoNameHorizontal} alt="logo" />
      </Link>
      <MobileNavWrapper>
        {auth ? <MobileAuth /> : <MobileNotAuth />}
      </MobileNavWrapper>
    </React.Fragment>
  );
};

const Nav = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const displayMobile = windowWidth >= 800 ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        displayMobile ? (
          <MobileNav authPassed={authUser} />
        ) : (
          <DesktopNav authPassed={authUser} />
        )
      }
    </AuthUserContext.Consumer>
  );
};

export default Nav;
