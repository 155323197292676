import React from "react";
import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import PieChart from "react-minimal-pie-chart";
import styled from "@emotion/styled";

const PieChartsGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px;
`;

const PieChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-self: center;
  flex: none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ]
  padding: 5px;
  color: #7abf5f;
`;

const ResultSocial = (props) => {
  const results = props.assessmentResult;

  return (
    <CSSTransitionGroup
      className="container result"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}>
      <PieChartsGroupWrapper>
        <PieChartWrapper>
          <PieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={[
              {
                color: "#7abf5f",
                value: results.Entertainer,
              },
            ]}
            label
            labelPosition={0}
            labelStyle={{
              fontFamily: "sans-serif",
              fontSize: "10px",
            }}
            lengthAngle={360}
            lineWidth={20}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={40}
            rounded={false}
            startAngle={0}
            totalValue={100}
            viewBoxSize={[35, 35]}
          />

          <h4>Entertainer</h4>
        </PieChartWrapper>

        <PieChartWrapper>
          <PieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={[
              {
                color: "#7abf5f",
                value: results.Significance,
              },
            ]}
            label
            labelPosition={0}
            labelStyle={{
              fontFamily: "sans-serif",
              fontSize: "10px",
            }}
            lengthAngle={360}
            lineWidth={20}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={40}
            rounded={false}
            startAngle={0}
            totalValue={100}
            viewBoxSize={[35, 35]}
          />

          <h4>Significance</h4>
        </PieChartWrapper>

        <PieChartWrapper>
          <PieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={[
              {
                color: "#7abf5f",
                value: results.Sensibility,
              },
            ]}
            label
            labelPosition={0}
            labelStyle={{
              fontFamily: "sans-serif",
              fontSize: "10px",
            }}
            lengthAngle={360}
            lineWidth={20}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={40}
            rounded={false}
            startAngle={0}
            totalValue={100}
            viewBoxSize={[35, 35]}
          />

          <h4>Sensibility</h4>
        </PieChartWrapper>

        <PieChartWrapper>
          <PieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={[
              {
                color: "#7abf5f",
                value: results.Altruistic,
              },
            ]}
            label
            labelPosition={0}
            labelStyle={{
              fontFamily: "sans-serif",
              fontSize: "10px",
            }}
            lengthAngle={360}
            lineWidth={20}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={40}
            rounded={false}
            startAngle={0}
            totalValue={100}
            viewBoxSize={[35, 35]}
          />

          <h4>Altruistic</h4>
        </PieChartWrapper>
      </PieChartsGroupWrapper>
    </CSSTransitionGroup>
  );
};

ResultSocial.propTypes = {
  assessmentResult: PropTypes.string.isRequired,
};

export default ResultSocial;
