import React, { useState, useEffect } from "react";
import { Card, makeStyles, Slide } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Title = styled.h2`
  padding-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  @media only screen and (max-width: 1000px) {
  }
`;

const LeftArrow = styled(ArrowBackIosIcon)`
  height: 60px;
  cursor: pointer;
`;

const RightArrow = styled(ArrowForwardIosIcon)`
  height: 60px;
  cursor: pointer;
`;

const CarouselSlide = (props) => {
  const [index, setIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState("right");
  const [slideIn, setSlideIn] = useState(true);
  const [clickDelayAuto, setClickDelayAuto] = useState(false);
  const content = props.slideContents[index];
  const numSlides = props.slideContents.length;
  let delay = 8000;
  if (props.slideInterval) {
    delay = props.slideInterval;
  }
  let interval;

  const Arrow = (props) => {
    const { direction, clickFunction } = props;
    const icon =
      direction === "right" ? (
        <RightArrow fontSize="large" color="white" />
      ) : (
        <LeftArrow fontSize="large" color="white" />
      );
    return <div onClick={clickFunction}>{icon}</div>;
  };

  const onArrowClick = (direction) => {
    console.log(`Pushed the arrow ${direction}`);
    setClickDelayAuto(true);
    setTimeout(() => {
      setClickDelayAuto(false);
    }, 8000);

    const increment = direction === "right" ? 1 : -1;
    changeSlide(increment);
  };

  const changeSlide = (increment) => {
    clearTimeout(interval);
    console.log(`Current slide:${index}, and increment:${increment}`);
    const direction = increment === -1 ? "left" : "right";

    const oppDirection = direction === "left" ? "right" : "left";
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
      setIndex((index) => (index + increment + numSlides) % numSlides);
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, 500);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 39) {
        onArrowClick("right");
      }
      if (e.keyCode === 37) {
        onArrowClick("left");
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (!clickDelayAuto) {
        changeSlide(1);
      }
    }, delay);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const CarouselCard = (props) => {
    const StyledCard = styled.div`
      background-color: #ebebeb;
      border-radius: 20px;
      height: 320px;
      width: 730px;
      overflow: hidden;
      @media only screen and (max-width: 850px) {
        width: 650px;
        height: 285px;
      }
      @media only screen and (max-width: 799px) {
        width: 580px;
        height: 280px;
      }
      @media only screen and (max-width: 650px) {
        width: 530px;
        height: 280px;
      }
      @media only screen and (max-width: 600px) {
        width: 470px;
        height: 280px;
      }
      @media only screen and (max-width: 480px) {
        width: 320px;
        height: 280px;
      }
    `;

    const CardContents = props.content.detail;

    return (
      <ElementWrapper>
        <StyledCard backgroundColor={grey}>
          <CardContents />
        </StyledCard>
        <Title>{props.content.title}</Title>
      </ElementWrapper>
    );
  };

  return (
    <Wrapper>
      <Arrow
        style={{ height: "60px" }}
        direction="left"
        clickFunction={() => onArrowClick("left")}
      />
      <Slide in={slideIn} direction={slideDirection}>
        <div>
          <CarouselCard content={content} />
        </div>
      </Slide>
      <Arrow direction="right" clickFunction={() => onArrowClick("right")} />
    </Wrapper>
  );
};

export default CarouselSlide;
