import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import PieChart from "react-minimal-pie-chart";
import WhiteLogo from "../../assests/logos/typologylogos_Logo_Mono_White.svg";

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const StyledPieChart = styled(PieChart)`
  position: absolute;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 7%;
  margin-right: 7%;
  @media only screen and (max-width: 799px) {
    margin-top: 12%;
    margin-bottom: 12%;
    margin-left: 7%;
    margin-right: 7%;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 12%;
    margin-bottom: 12%;
    margin-left: 15%;
    margin-right: 15%;
  }
`;

const LeftHalfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 55%;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

const GraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  align-items: left;
`;

const RightHalfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  justify-content: space-around;
  background-color: #202124;
  align-items: center;
  @media only screen and (max-width: 480px) {
    width: 0%;
  }
`;

const Logo = styled.img`
  width: 80%;
  padding-bottom: 15px;
`;

const Text = styled.p`
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
`;

const SubText = styled.p`
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -22px;
`;

const GraphBar1 = styled.div`
  height: 10px;
  border-radius: 5px;
  margin-top: 30%;
  margin-left: 20px;
  background-image: linear-gradient(to right, #ee3189, #f36d21);
  z-index: 2;
  width: 20px;
  transition: 1s ease-in;
  ${({ animate }) =>
    animate &&
    css`
      width: 65%;
    `}
`;
const GraphBar2 = styled.div`
  height: 10px;
  width: 20px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 20px;

  background-image: linear-gradient(to right, #469dd7, #70c8b8);
  z-index: 2;

  ${({ animate }) =>
    animate &&
    css`
      transition: width 0.8s ease-in;
      width: 85%;
    `}
`;
const GraphBar3 = styled.div`
  height: 10px;
  width: 20px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 20px;

  background-image: linear-gradient(to right, #85469b, #ee3189);
  z-index: 2;

  ${({ animate }) =>
    animate &&
    css`
      transition: width 0.7s ease-in;
      width: 55%;
    `}
`;
const GraphBar4 = styled.div`
  height: 10px;
  width: 20px;
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 20px;

  background-image: linear-gradient(to right, #ffc516, #f36d21);
  z-index: 2;

  ${({ animate }) =>
    animate &&
    css`
      transition: width 0.3s ease-in;
      width: 40%;
    `}
`;

const MachineLearningAnalyzed = () => {
  const [animateBegin, setAnimateBegin] = useState(false);
  const [animateBegin2, setAnimateBegin2] = useState(false);
  const [animateBegin3, setAnimateBegin3] = useState(false);
  const [animateBegin4, setAnimateBegin4] = useState(false);
  const [valuePercentage, setValuePercentage] = useState(0);
  const [triggerCount, setTriggerCount] = useState(false);

  setTimeout(() => {
    setAnimateBegin(true);
  }, 500);
  setTimeout(() => {
    setAnimateBegin2(true);
  }, 1000);
  setTimeout(() => {
    setAnimateBegin3(true);
  }, 1300);
  setTimeout(() => {
    setAnimateBegin4(true);
  }, 1850);

  const maxInterval = 65;
  const counter = useRef(undefined);

  useEffect(() => {
    setTimeout(() => {
      setTriggerCount(true);
    }, 1000);
  });

  //Run this on load
  useEffect(() => {
    if (triggerCount) {
      counter.current = setInterval(() => {
        if (valuePercentage !== maxInterval) {
          setValuePercentage((valuePercentage) => valuePercentage + 1);
        }
      }, 10);
    }
    return () => {
      clearInterval(counter.current);
    };
  }, [triggerCount, valuePercentage]);

  //Stop counting interval when the max number is reached by clearing the interval
  useEffect(() => {
    if (valuePercentage >= maxInterval) {
      clearInterval(counter);
    }
  }, [valuePercentage]);

  return (
    <CardWrapper>
      <LeftHalfWrapper>
        <Text>{valuePercentage}%</Text>
        <SubText>Intuitor</SubText>
        <StyledPieChart
          animate={true}
          animationDuration={800}
          animationEasing="ease-in"
          data={[
            {
              color: "#70c8b8",
              value: 80,
            },
          ]}
          radius={48}
          lineWidth={20}
          startAngle={270}
          totalValue={100}
        />
        <StyledPieChart
          animate={true}
          animationDuration={800}
          animationEasing="ease-in"
          data={[
            {
              color: "#ee3189",
              value: valuePercentage,
            },
          ]}
          radius={33}
          lineWidth={25}
          startAngle={270}
          totalValue={100}
        />
      </LeftHalfWrapper>

      <RightHalfWrapper>
        <GraphsWrapper>
          <GraphBar1 animate={animateBegin} />
          <GraphBar2 animate={animateBegin2} />
          <GraphBar3 animate={animateBegin3} />
          <GraphBar4 animate={animateBegin4} />
        </GraphsWrapper>

        <Logo src={WhiteLogo} />
      </RightHalfWrapper>
    </CardWrapper>
  );
};

export default MachineLearningAnalyzed;
