var assessmentQuestions = [
  {
    questionText: "In company you tend to",
    questionID: "arrayPos1",
    answers: [
      {
        type: "Extrovert",
        content: "start up conversations",
      },
      {
        type: "Introvert",
        content: "wait to be approached",
      },
    ],
  },

  {
    questionText: "In a new job or class, do you tend to",
    questionID: "arrayPos2",
    answers: [
      {
        type: "Extrovert",
        content: "make friends easily",
      },
      {
        type: "Introvert",
        content: "keep more to yourself",
      },
    ],
  },

  {
    questionText: "At parties do you like to",
    questionID: "arrayPos3",
    answers: [
      {
        type: "Extrovert",
        content: "meet and chat with everyone",
      },
      {
        type: "Introvert",
        content: "enjoy a few close friends",
      },
    ],
  },

  {
    questionText: "Does being with groups of people tend to",
    questionID: "arrayPos4",
    answers: [
      {
        type: "Extrovert",
        content: "energize you",
      },
      {
        type: "Introvert",
        content: "wear you out",
      },
    ],
  },

  {
    questionText: "Do you think of yourself as",
    questionID: "arrayPos5",
    answers: [
      {
        type: "Extrovert",
        content: "outgoing and expressive",
      },
      {
        type: "Introvert",
        content: "private and reserved",
      },
    ],
  },

  {
    questionText: "Do you live more in",
    questionID: "arrayPos6",
    answers: [
      {
        type: "Sensor",
        content: "the world as it is",
      },
      {
        type: "Intuitor",
        content: "the realm of possibility",
      },
    ],
  },

  {
    questionText: "Do you more often see things",
    questionID: "arrayPos7",
    answers: [
      {
        type: "Sensor",
        content: "with open eyes",
      },
      {
        type: "Intuitor",
        content: "in your mind's eye",
      },
    ],
  },

  {
    questionText: "Would you call yourself more",
    questionID: "arrayPos8",
    answers: [
      {
        type: "Sensor",
        content: "practical",
      },
      {
        type: "Intuitor",
        content: "theoretical",
      },
    ],
  },

  {
    questionText: "To solve problems do you rely more on",
    questionID: "arrayPos9",
    answers: [
      {
        type: "Sensor",
        content: "common sense",
      },
      {
        type: "Intuitor",
        content: "your own analysis",
      },
    ],
  },

  {
    questionText: "Do you more often feel",
    questionID: "arrayPos10",
    answers: [
      {
        type: "Sensor",
        content: "in touch with your feelings",
      },
      {
        type: "Intuitor",
        content: "lost in thought",
      },
    ],
  },

  {
    questionText: "Facts",
    questionID: "arrayPos11",
    answers: [
      {
        type: "Sensor",
        content: "speak for themselves",
      },
      {
        type: "Intuitor",
        content: "suggest principles",
      },
    ],
  },

  {
    questionText: "Are you better at",
    questionID: "arrayPos12",
    answers: [
      {
        type: "Sensor",
        content: "noticing important details",
      },
      {
        type: "Intuitor",
        content: "seeing the big picture",
      },
    ],
  },

  {
    questionText: "Childhood is best spent developing",
    questionID: "arrayPos13",
    answers: [
      {
        type: "Sensor",
        content: "physical and social skills",
      },
      {
        type: "Intuitor",
        content: "world of make-believe",
      },
    ],
  },

  {
    questionText: "Are you more of a",
    questionID: "arrayPos14",
    answers: [
      {
        type: "Sensor",
        content: "nuts and bolts sort of person",
      },
      {
        type: "Intuitor",
        content: "fanciful sort of person",
      },
    ],
  },

  {
    questionText: "Are you more likely to trust",
    questionID: "arrayPos15",
    answers: [
      {
        type: "Sensor",
        content: "your experiences",
      },
      {
        type: "Intuitor",
        content: "your insights",
      },
    ],
  },

  {
    questionText: "Do you prize yourself in",
    questionID: "arrayPos16",
    answers: [
      {
        type: "Sensor",
        content: "a strong grasp of reality",
      },
      {
        type: "Intuitor",
        content: "a complex imagination",
      },
    ],
  },

  {
    questionText: "When dealing with others do you try to be",
    questionID: "arrayPos17",
    answers: [
      {
        type: "Thinker",
        content: "firm and objective",
      },
      {
        type: "Feeler",
        content: "gentle and sympathetic",
      },
    ],
  },

  {
    questionText: "In discussions do you care more about",
    questionID: "arrayPos18",
    answers: [
      {
        type: "Thinker",
        content: "making a good argument",
      },
      {
        type: "Feeler",
        content: "finding points of agreement",
      },
    ],
  },

  {
    questionText: "Are you more comfortable giving people",
    questionID: "arrayPos19",
    answers: [
      {
        type: "Thinker",
        content: "honest criticism",
      },
      {
        type: "Feeler",
        content: "approval and encouragement",
      },
    ],
  },

  {
    questionText: "Do you base your decisions more on",
    questionID: "arrayPos20",
    answers: [
      {
        type: "Thinker",
        content: "impersonal data",
      },
      {
        type: "Feeler",
        content: "personal wishes",
      },
    ],
  },

  {
    questionText: "Are you more inclined to be",
    questionID: "arrayPos21",
    answers: [
      {
        type: "Thinker",
        content: "cool and collected",
      },
      {
        type: "Feeler",
        content: "warm and enthusiastic",
      },
    ],
  },

  {
    questionText: "In evaluating others are you usually",
    questionID: "arrayPos22",
    answers: [
      {
        type: "Thinker",
        content: "frank and straightforward",
      },
      {
        type: "Feeler",
        content: "friendly and considerate",
      },
    ],
  },

  {
    questionText: "Which do you admire more in others",
    questionID: "arrayPos23",
    answers: [
      {
        type: "Thinker",
        content: "strength of will",
      },
      {
        type: "Feeler",
        content: "strength of feeling",
      },
    ],
  },

  {
    questionText: "Which rules you more",
    questionID: "arrayPos24",
    answers: [
      {
        type: "Thinker",
        content: "your head",
      },
      {
        type: "Feeler",
        content: "your heart",
      },
    ],
  },

  {
    questionText: "Do you consider yourself",
    questionID: "arrayPos25",
    answers: [
      {
        type: "Thinker",
        content: "tough-minded",
      },
      {
        type: "Feeler",
        content: "sentimental",
      },
    ],
  },

  {
    questionText: "Which do you value more in yourself",
    questionID: "arrayPos26",
    answers: [
      {
        type: "Thinker",
        content: "being reasonable",
      },
      {
        type: "Feeler",
        content: "being devoted",
      },
    ],
  },

  {
    questionText: "Are you swayed more by",
    questionID: "arrayPos27",
    answers: [
      {
        type: "Thinker",
        content: "convincing evidence",
      },
      {
        type: "Feeler",
        content: "a touching appeal",
      },
    ],
  },

  {
    questionText: "As a job wraps up do you want to",
    questionID: "arrayPos28",
    answers: [
      {
        type: "Judger",
        content: "tie up the loose ends",
      },
      {
        type: "Perceiver",
        content: "start on something else",
      },
    ],
  },

  {
    questionText: "Do you typically",
    questionID: "arrayPos29",
    answers: [
      {
        type: "Judger",
        content: "make up your mind quickly",
      },
      {
        type: "Perceiver",
        content: "consider at some length",
      },
    ],
  },

  {
    questionText: "Do you find that schedules",
    questionID: "arrayPos30",
    answers: [
      {
        type: "Judger",
        content: "give you needed structure",
      },
      {
        type: "Perceiver",
        content: "are too restrictive",
      },
    ],
  },

  {
    questionText: "Would you rather be seen as",
    questionID: "arrayPos31",
    answers: [
      {
        type: "Judger",
        content: "constant",
      },
      {
        type: "Perceiver",
        content: "unpredictable",
      },
    ],
  },

  {
    questionText: "Going on vacation, do you prefer to",
    questionID: "arrayPos32",
    answers: [
      {
        type: "Judger",
        content: "make plans in advance",
      },
      {
        type: "Perceiver",
        content: "go where the road leads you",
      },
    ],
  },

  {
    questionText: "Are you happier",
    questionID: "arrayPos33",
    answers: [
      {
        type: "Judger",
        content: "getting things settled",
      },
      {
        type: "Perceiver",
        content: "exploring alternatives",
      },
    ],
  },

  {
    questionText: "Is a mess something you usually",
    questionID: "arrayPos34",
    answers: [
      {
        type: "Judger",
        content: "want to straighten up",
      },
      {
        type: "Perceiver",
        content: "can live with",
      },
    ],
  },

  {
    questionText: "Do you tend to spend money",
    questionID: "arrayPos35",
    answers: [
      {
        type: "Judger",
        content: "pretty carefully",
      },
      {
        type: "Perceiver",
        content: "more on impulse",
      },
    ],
  },

  {
    questionText: "Would you say you are more",
    questionID: "arrayPos36",
    answers: [
      {
        type: "Judger",
        content: "definite and determined",
      },
      {
        type: "Perceiver",
        content: "casual and easy going",
      },
    ],
  },

  {
    questionText: "Do you feel better about",
    questionID: "arrayPos37",
    answers: [
      {
        type: "Judger",
        content: "coming to closure",
      },
      {
        type: "Perceiver",
        content: "keeping your options open",
      },
    ],
  },

  {
    questionText: "Do you prefer to",
    questionID: "arrayPos38",
    answers: [
      {
        type: "Judger",
        content: "plan your free time",
      },
      {
        type: "Perceiver",
        content: "just see what turns up",
      },
    ],
  },
];

export default assessmentQuestions;
