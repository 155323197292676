import React, { useState, useEffect } from "react";
import Payment from "./Payment";
import styled from "@emotion/styled";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import StyledButton from "../../../assests/buttons";

const AccessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 799px) {
    flex-direction: column;
  }
`;

const AssessmentIntroText = styled.p`
  font-size: 1.4rem;
  font-family: Helvetica;
  width: 80vw;
  text-align: center;
  margin-top: 2rem;
`;

const CodeForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const AccessHeadingTitle = styled.p`
  padding-top: 1.5rem;
  font-size: 1.8rem;
  text-align: center;
  font-family: Helvetica;
  color: white;
`;

const AccessCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 15px;
  margin-top: 0px;
  padding-top: 0px;
  border-radius: 6px;
`;

const CodeEntryHeadingText = styled.p`
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const CodeEntryFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  margin-top: 0px;
`;

const InputBox = styled.div`
  position: relative;
  margin-bottom: 7.5px;
`;

const InputLabel = styled.label`
  position: absolute;
  ${(props) => (props.hasFocus ? "top: -30px" : "top: -5px")};
  left: 0;
  ${(props) => (props.hasFocus ? "color: black" : "color: white")};
  ${(props) => (props.hasFocus ? "font-size: 16px" : "font-size: 24px")};
  letter-spacing: 1px;
  padding: 10px 0;
  pointer-events: none;
  transition: 0.5s;
`;

const CodeInput = styled.input`
  width: 100%;
  padding: 0;
  font-size: 24px;
  color: white;
  letter-spacing: 1px;
  margin-bottom: 5px;
  border: none;
  border-bottom: 2px solid white;
  outline: none;
  background: transparent;
`;

const CodeEnteredHint = styled.p`
  color: white;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 150px;
  padding-right: 150px;
`;

const ProcessingTextWrapper = styled.p`
  color: white;
  font-family: Helvetica;
  font-size: 22px;
`;

const CodeComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  margin: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(120deg, #469dd7, #85469b);
  border-radius: 1rem;
  @media only screen and (max-width: 799px) {
    width: 75vw;
  }
  @media only screen and (max-width: 650px) {
    width: 85vw;
  }
  /* 
  //Code to make a border gradient
  border: double 4px transparent;
  border-radius: 22px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #469dd7, #85469b);
  background-origin: border-box;
  background-clip: content-box, border-box; */
`;

const CreditCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  margin: 2vw;
  @media only screen and (max-width: 1000px) {
    width: 48vw;
  }
  @media only screen and (max-width: 799px) {
    width: 90vw;
  }
  padding-top: 2vw;
`;

const PurchaseAndCode = (props) => {
  const [accessCode, setAccessCode] = useState("");
  const [hasACode, setHasACode] = useState(false);
  const [processingCode, setProcessingCode] = useState(false);
  const [codeNotFound, setCodeNotFound] = useState(false);
  const [inputs, setInputs] = useState({});
  const [incorrectCode, setIncorrectCode] = useState("");

  const [hasFocus, setHasFocus] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const displayMobile = windowWidth >= 1000 ? false : true;
  const displayMiniMobile = windowWidth >= 550 ? false : true;
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (props.authUser && !hasACode) {
      var accessCodes = [];
      try {
        props.firebase
          .codes()
          .where("userEmail", "==", props.authUser.email)
          .where("used", "==", false)
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              setHasACode(true);
              snapshot.forEach((doc) => {
                console.log(doc.id, "=>", doc.data().accessCode);
                accessCodes.push(doc.data().accessCode);
              });
              if (accessCodes) {
                setAccessCode(accessCodes[0]);
                setHasFocus(true);
                console.log("Access Codes => ", accessCodes);
              }
            }
          });
      } catch (err) {
        console.error("Error getting documents with matching code: ", err);
      }
    }
  }, [hasACode, props.authUser, props.firebase]);

  const useAccessCode = async (event) => {
    if (event) {
      event.preventDefault();
    }

    //Read code from input and trim spaces
    let tempCode = accessCode || inputs.accessCode;
    var accessCodeChoice = tempCode.trim();
    var docID = null;
    var match = false;
    var used = true; //This is if the code has been used already
    setProcessingCode(true);
    try {
      await props.firebase
        .codes()
        .where("accessCode", "==", accessCodeChoice)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            console.log("There is a code that matches");
            match = true;
            snapshot.forEach((doc) => {
              console.log(doc.id, "=>", doc.data());
              docID = doc.id;
              used = doc.data().used;
            });
          } else if (snapshot.empty) {
            console.log(`There is no matching code`);
            setIncorrectCode(accessCodeChoice);
            setCodeNotFound(true);
            setProcessingCode(false);
            setInputs({});
          }
        });
      let usedCodeHere = false;
      if (match && !used) {
        console.log("run give access for: ", props.purpose);
        let input;
        //Purpose passes to cloudfunction, which uses thiis property as the key for write to user doc for access
        if (props.purpose === "socialStylesAccess") {
          input = "hasAccessSocial";
        }
        if (props.purpose === "typologyAccess") {
          input = "hasAccessTypology";
        }
        await props.firebase
          .users()
          .doc(props.authUser.uid)
          .collection(`usedCodes`)
          .add({
            usedCode: accessCodeChoice,
            usedCodeDocID: docID,
            purpose: input,
          });
      }
      if (usedCodeHere) {
        await props.firebase
          .codes()
          .doc(docID)
          .update({ used: true, usedBy: props.authUser.username });
      }
    } catch (err) {
      console.error("Error getting documents with matching code: ", err);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const setFocus = (hasFocus) => {
    if (!accessCode && !inputs.accessCode) {
      setHasFocus(hasFocus);
    }
  };

  const renderCodeHintNoCode = (props) => {
    return (
      <Tooltip id="hasNoCodeHint" {...props}>
        Please enter a code if you have one, or purchase access above
      </Tooltip>
    );
  };

  const renderCodeHintWithCode = (props) => {
    return (
      <Tooltip id="hasACodeHint" {...props}>
        We filled in the code that emailed to you, we work for your convenience!
      </Tooltip>
    );
  };

  return (
    <PageWrapper>
      <AssessmentIntroText>{props.introText}</AssessmentIntroText>
      <AccessWrapper>
        <CodeComponentWrapper>
          <AccessHeadingTitle>ACCESS CODE</AccessHeadingTitle>
          <AccessCodeWrapper>
            {!processingCode && (
              <CodeEntryFormWrapper>
                <CodeEntryHeadingText>
                  Enter an access code below if you have one. If you use a code
                  provided by a coach or employer, your assessment results will
                  be sent to them upon completion.
                </CodeEntryHeadingText>

                {codeNotFound && (
                  <p>{`The code you entered: ${incorrectCode}, was not correct.`}</p>
                )}
                <CodeForm onSubmit={useAccessCode}>
                  <OverlayTrigger
                    placement="top-end"
                    delay={{ show: 200, hide: 400 }}
                    overlay={
                      hasACode ? renderCodeHintWithCode : renderCodeHintNoCode
                    }>
                    <InputBox>
                      <CodeInput
                        type="text"
                        name="accessCode"
                        onChange={handleInputChange}
                        value={inputs.accessCode || accessCode}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        required
                      />
                      <InputLabel hasFocus={hasFocus}>Access Code</InputLabel>
                    </InputBox>
                  </OverlayTrigger>

                  {hasACode && accessCode && (
                    <CodeEnteredHint>
                      Note: We filled in the code attached to your email
                    </CodeEnteredHint>
                  )}
                  <StyledButton
                    tertiary
                    fontSize="1.4rem"
                    type="submit"
                    width="16rem">
                    Use Access Code
                  </StyledButton>
                </CodeForm>
              </CodeEntryFormWrapper>
            )}
            {processingCode && (
              <SpinnerWrapper>
                <ProcessingTextWrapper>Applying Code</ProcessingTextWrapper>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </SpinnerWrapper>
            )}
          </AccessCodeWrapper>
        </CodeComponentWrapper>

        {/* Payment componenet here */}

        <CreditCardWrapper displayMiniMobile={displayMiniMobile}>
          <Payment
            purpose={props.purpose}
            firebase={props.firebase}
            chargeCents={props.chargeCents}
            emails={props.authUser.email}
          />
        </CreditCardWrapper>
      </AccessWrapper>
    </PageWrapper>
  );
};

export default PurchaseAndCode;
