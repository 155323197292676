import React, { useContext, useState, useEffect, useCallback } from "react";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 200px;
`;

const TeamManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 20px;
  padding: 15px;
`;

const TeamMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: center;
`;

const TeamMemberName = styled.p`
  width: 180px;
  font-size: 14pt;
`;

const TitleWrapper = styled.h2`
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: center;
`;

const InstructionsWrapper = styled.p`
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: center;
`;

const SelectTeamWrapperButton = styled(Dropdown.Toggle)`
  background-color: #7abf5f;
  font-size: 24px;
`;

const TeamOptionWrapper = styled(Dropdown.Item)`
  font-size: 24px;
`;

const DropDownWrapper = styled(Dropdown)`
  transform: translate(0, -20px);
`;

const TeamManagementComponent = (props) => {
  const authUser = useContext(AuthUserContext);

  const [teamData, setTeamData] = useState({});
  const [teamMembersState, setTeamMembersState] = useState([]);
  const [teamsArray, setTeamsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leadTeamDocId, setLeadTeamDocId] = useState(null);
  const [moreThanOneTeam, setMoreThanOneTeam] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(0);

  const getTeamUserOwns = useCallback(
    async (authUser) => {
      console.log(
        "Executing in getTeamUserOwns with authUserID: ",
        authUser.uid
      );
      let teams = [];
      let teamDataMap = {};
      let teamDocId;
      const teamCollection = await props.firebase
        .teams()
        .where("leaderId", "==", authUser.uid)
        .get();
      teamCollection.docs.forEach((teamDoc) => {
        if (teamDoc.exists) {
          let leaderTeamObject = teamDoc.data();
          teamDocId = teamDoc.id;
          teamDataMap = {
            teamCode: leaderTeamObject.teamCode,
            teamName: leaderTeamObject.teamName,
            teamDocId: teamDocId,
          };
          teams.push(teamDataMap);
          setTeamData(teamDataMap);
          setLeadTeamDocId(teamDocId);
        } else {
          console.log("This member is not a team lead");
        }
      });
      console.log("Teams: ", teams);
      if (teams.length > 1) {
        setMoreThanOneTeam(true);
      }
      setTeamsArray(teams);
      setCurrentTeam(0);
    },
    [props.firebase]
  );

  useEffect(() => {
    console.log("useEffect to get team members has fired outside");
    console.log(teamsArray[currentTeam]);
    if (teamsArray[currentTeam] && teamsArray[currentTeam] !== "undefined") {
      console.log("useEffect to get team members has fired inside");
      let teamMembersListener = props.firebase
        .teams()
        .doc(teamsArray[currentTeam].teamDocId)
        .collection(`members`);

      teamMembersListener.onSnapshot((teamMembersCollection) => {
        let teamMembers = [];
        console.log(`Team members: ${teamMembers}`);
        teamMembersCollection.forEach((memberDoc) => {
          if (memberDoc.exists) {
            teamMembers.push({ ...memberDoc.data(), docId: memberDoc.id });
          }
        });
        console.log(teamMembers.forEach((member) => console.log(member)));
        setTeamMembersState(teamMembers);
        setIsLoading(false);
      });
    }
  }, [props.firebase, teamsArray, currentTeam]);

  const onRemoveMember = (userDocId, memberIdToRemove) => {
    console.log(
      `I will now remove the member: ${userDocId} whose UID is: ${memberIdToRemove}`
    );
    props.firebase
      .teams()
      .doc(teamsArray[currentTeam].teamDocId)
      .collection(`members`)
      .doc(userDocId)
      .delete();
    setIsLoading(true);
  };

  useEffect(() => {
    if (authUser) {
      getTeamUserOwns(authUser);
    }
  }, [authUser, getTeamUserOwns]);

  useEffect(() => {
    if (teamsArray) {
      setIsLoading(false);
    }
  }, [teamsArray]);

  const selectTeam = (teamArrayPos) => {
    setCurrentTeam(teamArrayPos);
  };

  return (
    <TeamManagementWrapper>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner animation="border" role="status" />
        </SpinnerWrapper>
      )}
      {/* Add team slection here, if teamsArray.length > 1.  teamsArray is an array of objects that comprise each team. */}
      {!isLoading && moreThanOneTeam && (
        <DropDownWrapper title="Drop large">
          <SelectTeamWrapperButton variant="success" id="dropdown-basic">
            Select Team
          </SelectTeamWrapperButton>

          <Dropdown.Menu>
            {teamsArray.map((teamDoc, index) => (
              <TeamOptionWrapper onClick={() => selectTeam(index)}>
                {/* Breaks here, this needs to be repaired to not render until defined */}
                {teamDoc.teamName}
              </TeamOptionWrapper>
            ))}
          </Dropdown.Menu>
        </DropDownWrapper>
      )}
      {!isLoading && teamsArray[0] && (
        <div>
          <TitleWrapper>
            <strong>{teamsArray[currentTeam].teamName}</strong>
          </TitleWrapper>
          <InstructionsWrapper>
            Members can join this team by entering the password:{" "}
            <strong>{teamsArray[currentTeam].teamCode}</strong>
          </InstructionsWrapper>
        </div>
      )}
      {/* Consider adding a useEffect here to remove upon update of the members */}
      {!isLoading &&
        teamMembersState.map((member) => (
          <TeamMemberWrapper>
            <TeamMemberName>{member.member}</TeamMemberName>{" "}
            <Button
              onClick={() => {
                onRemoveMember(member.docId, member.memberId);
              }}
              variant="outline-danger"
              size="sm"
            >
              Remove Member
            </Button>
          </TeamMemberWrapper>
        ))}
    </TeamManagementWrapper>
  );
};

export default withFirebase(TeamManagementComponent);
